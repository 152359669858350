<div [ngClass]="{'container-gtp': showHeader}">
    <ng-container *ngIf="showHeader">
        <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
        <h2 class="text-center">BOLETÍN CONCURSAL</h2>
        <h5 class="text-center"><span class="badge bg-secondary text-center">
                {{content.boletinConcursalReporte.principal.encabezado.rut | number :
                '1.0-0'}}-{{content.boletinConcursalReporte.principal.encabezado.dv | uppercase}}
            </span></h5>
    </ng-container>
    <div class="row">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
                        style="font-size:28px;">&nbsp;</i>DATOS DEL PROCEDIMIENTO</h5>
                <div class="card-body">
                    <table class="table table-hover table-borderless">
                        <tbody>
                            <tr>
                                <th width="12%" nowrap="nowrap" scope="row">Tipo de Procedimiento:</th>
                                <td width="88%">
                                    {{content.boletinConcursalReporte.principal.encabezado.tipoDeProcedimiento}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">RUT Deudor:</th>
                                <td>
                                    {{content.boletinConcursalReporte.principal.encabezado.rut | number :
                                    '1.0-0'}}-{{content.boletinConcursalReporte.principal.encabezado.dv}}
                                </td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Nombre / Razón Social Deudor:</th>
                                <td>{{content.boletinConcursalReporte.principal.encabezado.razonSocial}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Tribunal:</th>
                                <td>{{content.boletinConcursalReporte.principal.encabezado.tribunal}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Rol Causa:</th>
                                <td>{{content.boletinConcursalReporte.principal.encabezado.rolCausa}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Liquidador:</th>
                                <td>{{content.boletinConcursalReporte.principal.encabezado.liquidador}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn" (click)="showDetails()">Ver Detalle</button>
                </div>
                <br>
                <br>
                <div *ngIf="showReportDetails" class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
                            style="font-size:28px;">&nbsp;</i>PUBLICACIONES ASOCIADAS AL PROCEDIMIENTO</h5>
                    <div class="card-body">
                        <table class="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th class="cursor-pointer" align="left" nowrap="nowrap" scope="row" (click)="cambioDeCampo('nombrePublicacion')">
                                        Nombre Publicación
                                        <div class="float-right not-print">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th class="cursor-pointer" (click)="cambioDeCampo('fchPublicacion')">
                                        Fecha Publicación
                                        <div class="float-right not-print">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th class="cursor-pointer" (click)="cambioDeCampo('entePublicador')">
                                        Vendedor / Liquidador / Martillero
                                        <div class="float-right not-print">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="listaDetalles">
                                    <tr
                                        *ngFor="let detalle of listaDetalles | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <td>{{detalle?.nombrePublicacion}}</td>
                                        <td align="center">{{detalle?.fchPublicacion || ''}}</td>
                                        <td align="center">{{detalle?.entePublicador}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div id="pagination">
                            <div class="col-xs-10">
                                <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                            </div>
                            <div class="col-xs-2 text-right">
                                <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <hr>
        <p>La información presentada en estos análisis proviene de fuentes consideradas altamente confiables. Sin
            embargo,
            dada la posibilidad de error humano o mecánico, no se garantiza la exactitud o integridad de la información
            y, pot
            tanto, no se hace responsable de errores u omisiones, como tampoco de las consecuencias asociadas con el
            empleo de
            esa información.</p>
        <hr>
        <table width="100%" border="0">
            <tbody>
                <tr>
                    <td align="center">
                        <div class="container-gtp">
                            <!-- img src="img/qr.png" width="99" height="100" alt=""/ -->
                            <img src="https://chart.googleapis.com/chart?chs=99x100&cht=qr&chl=https://www.gestionatupyme.cl"
                                width="99" height="100" alt="" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
    </div>