import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private location: Location
  ) { 
  }

  async ngOnInit(): Promise<void> {
    this.location.replaceState('/login');
    this.titleService.setTitle(this.route.snapshot.data['title']);
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      //console.log("accessToken", accessToken, "idToken", idToken);
      if(idToken) this.router.navigate([''])
    } catch (err) {
      console.log(err);
    }
  }
  
}
