<div [ngClass]="{'container-gtp mb-5': showHeader}">
    <ng-container *ngIf="showHeader">
    
    <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
    <h2 class="text-center mt-3">Deuda Sbif Leasing </h2>
    <h5 class="text-center"><span class="badge bg-secondary text-center">
 
        <ng-template #buscar>
            {{content?.Documentos?.SbifOnline?.DatosBasicosSolicitud?.Rut | number : '1.0-0'}}-
            {{content?.Documentos?.SbifOnline?.DatosBasicosSolicitud?.DV | uppercase}}
        </ng-template>
        

        <ng-container *ngIf="typeTX; else buscar">
            {{content.DeudaSistemaFinancieroReporte?.DatosBasicosSolicitud?.Rut | number : '1.0-0'}}-
            {{content.DeudaSistemaFinancieroReporte?.DatosBasicosSolicitud?.DV | uppercase}}
        </ng-container>
        
        </span></h5>
    </ng-container>
    <div class="row">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
                        style="font-size:28px;">&nbsp;</i>ANTECEDENTES M$</h5>
                <div class="card-body">
                    <ng-container *ngIf="dataSource.length > 0">
                        <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <tbody>
                                <tr *ngFor="let row of headRow">
                                    <td>{{row !== 'periodo'? headNames[row]: ''}}</td>
                                    <td *ngFor="let item of dataSource">
                                        <ng-template #periodo>
                                           <span class="text-capitalize">
                                            {{item[row] | date: 'LLL yyyy'}}
                                           </span> 
                                        </ng-template>
                                        <ng-container *ngIf="row !== 'periodo'; else periodo">
                                            <ng-template #cosumoAcreedor>
                                                {{item[row][0] | number : '1.0-0'}}/{{item[row][1] | number : '1.0-0'}}    
                                            </ng-template>
                                            
                                            <ng-container *ngIf="row !== 'diracreedor'; else cosumoAcreedor">
                                                {{item[row] | number : '1.0-0'}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </ng-container>
                </div>
            </div>

        </div>