import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompraVentaService } from 'src/app/shared/services/compra-venta.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from 'src/app/components/_alert';

@Component({
  selector: 'app-reporte-compra-venta-view',
  templateUrl: './reporte-compra-venta-view.component.html',
  styleUrls: ['./reporte-compra-venta-view.component.scss']
})
export class ReporteCompraVentaViewComponent implements OnInit {
  @Input() rut = '';
  @Input() errores: any[] = [];
  @Input() periodoInicial: any;
  @Input() hasBackLogic: boolean = false;
  @Output() backReport = new EventEmitter<void>();
  
  public objectKeys = Object.keys;

  public datosGeneralesCompra: any = {};
  public datosGeneralesVenta: any = {};

  public estadisticosGenerales: any = {};

  public tablaTop10Proveedores: any[] = [];
  public tablaTop10Clientes: any[] = [];

  public graphEvCompraL: any = {};
  public graphEvVentaL: any = {};

  public graphDisCompraB: any = {};
  public graphDisVentaB: any = {};

  public graphEvCompraB: any = {};
  public graphEvVentaB: any = {};
  
  public graphDisCompraP: any = {};
  public graphDisVentaP: any = {};

  public graphDisCompraPorAnio: any = {};
  public graphDisVentaPorAnio: any = {};

  public graphDisTipoCompra: any = {};
  public graphDisTipoVenta: any = {};

  public tipoGraficoEvolutivoVentas: boolean = false;
  public tipoGraficoEvolutivoCompras: boolean = false;

  public tipoGraficoDistribucionVentas: boolean = false;
  public tipoGraficoDistribucionCompras: boolean = false;

  public value: number = 0;
  public seeVentas: boolean = false;
  public seeCompras: boolean = true;
  public seeNC: boolean = false;
  public seeBoletas: boolean = true;
  
  public listaRuts: any [] = [];
  public filtroForm: UntypedFormGroup;
  private subscriptions: any[] = [];

  //Manejo de paginas
  public paginaActual: any[] = [];
  public currentPage = 0;
  public currentItem = 10;

  public cantidadRegistrosVenta: number = 0;
  public cantidadRegistrosCompra: number = 0;
  
  public cantidadPaginas: number = 0;
  public paginasActualesErrores: {pagina: number, error: string}[] = []
  public paginasActuales: {pagina: number, response: any}[] = [];
  public tipoPagina: string = 'compra';

  public respuestaCalculos: any = {};

  public paginationOptions: number[] = [10, 25, 50, 100];

  public calulosErrores: {tipo: string, error: string}[] = [];
  public ejecutarLlamadas: boolean = true;
  public showSpinnerRut: boolean = false;

  public tablaBoletas: any[] = [];
  public tablaComprobantesElectronicos: any[] = [];
  public tablaOtrasVentas: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private compraVentaService: CompraVentaService,
    public alertService: AlertService,
  ) {
    this.filtroForm = this.formBuilder.group({
      entidadRut: [''],
      selectPeriodos: ['']
    }) as FormGroupTyped<any>;
  }

  ngOnInit(): void {
    if(this.periodoInicial) {
      this.filtroForm.get('selectPeriodos')?.setValue('36'); // se hace consulta por 3 periodos
      this.llamadaCalculos(true, true);
      this.onChanges();
    } else {
      this.alertService.error('No se ha logrado recuperar el periodo');
      this.spinner.hide();
      this.logicaRetorno();
    }
  }

  onChanges(): void {
    this.subscriptions.push(this.filtroForm.get('entidadRut')?.valueChanges.subscribe(value => {
      if(value){
        this.seeBoletas = false;
      } else {
        this.seeBoletas = true;
      }

      if(this.ejecutarLlamadas){
        this.llamadaCalculos(false);
      }
    }));

    this.subscriptions.push(this.filtroForm.get('selectPeriodos')?.valueChanges.subscribe(value => {
      if(this.ejecutarLlamadas){
        const periodo = this.filtroForm.get('selectPeriodos')?.value || '';
        const rut = this.filtroForm.get('entidadRut')?.value || '';
  
        if(periodo){
          this.llamadaCalculos(rut ? false : true);
        }
      }
    }));
  }

  logicaRetorno(): void {
    if(this.hasBackLogic) {
      this.backReport.emit();
    }
  }

  cambioCompras(): void {
    let forzar = false;
    if(this.seeNC){
      forzar = true;
    }
    this.seeVentas = false;
    this.seeCompras = true;
    this.seeNC = false;
    this.tipoGraficoEvolutivoCompras = false;
    this.tipoGraficoDistribucionCompras = false;
    this.currentItem = 10;
    this.validaCambiosFiltros(forzar);
  }

  cambioVentas(): void {
    let forzar = false;
    if(this.seeNC){
      forzar = true;
    }
    this.seeVentas = true;
    this.seeCompras = false;
    this.seeNC = false;
    this.tipoGraficoEvolutivoVentas = false;
    this.tipoGraficoDistribucionVentas = false;
    this.currentItem = 10;
    this.validaCambiosFiltros(forzar);
  }

  cambioNC(): void {
    this.seeVentas = false;
    this.seeCompras = false;
    this.seeNC = true;
    this.tipoGraficoEvolutivoCompras = false;
    this.tipoGraficoDistribucionCompras = false;
    this.tipoGraficoEvolutivoVentas = false;
    this.tipoGraficoDistribucionVentas = false;
    this.currentItem = 10;
  }

  validaCambiosFiltros(forzar: boolean = false): void {
    const meses = this.filtroForm.get('selectPeriodos')?.value;
    const rutFiltro = this.filtroForm.get('entidadRut')?.value;
    if(meses != '36' || rutFiltro != '' || forzar) { // se hizo un cambio, se debe consultar a api de calculos
      this.ejecutarLlamadas = false;
      this.filtroForm.get('entidadRut')?.setValue('');
      this.filtroForm.get('selectPeriodos')?.setValue('');
      this.ejecutarLlamadas = true;
      this.filtroForm.get('selectPeriodos')?.setValue('36');
    } else { // no se hizo un cambio, se debe consultar solo a paginacion
      this.orquestadorMapeos();
      this.obtenerRuts();
      this.obtenerCantidadPaginas(true);
    }
  }

  divisionNumber(numero: number, divisor: number): number {
    if((divisor || divisor === 0) && numero !== 0){
      return Number( numero / divisor );
    }
    return numero;
  }

  generarTablaTop10Proveedores(datosCompras: any[]): void {
    this.tablaTop10Proveedores = datosCompras.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
  }

  generarTablaTop10Clientes(datosVentas: any[]): void {
    this.tablaTop10Clientes = datosVentas.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
  }

  validaInfinito(valor: number): number | string {
    return (isFinite(valor) ? (valor || 0) : (''));
  }

  // Graficos Evolutivos

  obtenerObjectAnios(periodos: any[], valuesCompras: any[]): any {
    const anios: any = {};

    for (const mesAnio of periodos) {
      const [mes, anio] = mesAnio.split("-");
      if (!anios[anio]) {
        anios[anio] = new Array(12).fill("");
      }
      anios[anio][mes - 1] = valuesCompras[periodos.indexOf(mesAnio)] || 0;
    }

    return anios;
  }

  setGraficoLineaDatosEvCompra(periodos: any[], valuesCompras: any[]): void {
    const colores: string[] = [
      '#007bff',
      '#c51f20',
      '#f0f05f',
      '#b2e98c'
    ];
    const series: any[] = [];
    const anios = this.obtenerObjectAnios(periodos, valuesCompras);

    let indexColor = 0;
    for (const [key, value] of Object.entries(anios)) {
      series.push({
        name: `Compras ${key}`,
        type: 'line',
        data: value,
        color:  colores[indexColor] || '#007bff',
      })
      indexColor += 1;
    }

    this.graphEvCompraL = new Chart({
      xAxis: [{
        categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function () {
          return this.points?.reduce(function (s, point) {
            return s + '<br/>' + point.series.name + ': ' +
                '$' + Highcharts.numberFormat(Number(point.y), 2,',') + ' K';
          }, '<b>' + this.x + '</b>');
        },
        shared: true
      },
      series: series
    });
  }

  setGraficoLineaDatosEvVenta(periodos: any[], valuesVentas: any[]): void {
    const colores: string[] = [
      '#007bff',
      '#c51f20',
      '#f0f05f',
      '#b2e98c'
    ];
    const series: any[] = [];
    const anios = this.obtenerObjectAnios(periodos, valuesVentas);
    let indexColor = 0;
    for (const [key, value] of Object.entries(anios)) {
      series.push({
        name: `Ventas ${key}`,
        type: 'line',
        data: value,
        color:  colores[indexColor] || '#007bff',
      })
      indexColor += 1;
    }

    this.graphEvVentaL = new Chart({
      chart: {
        width: null,
        height: null
      },
      xAxis: [{
        categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function () {
          return this.points?.reduce(function (s, point) {
            return s + '<br/>' + point.series.name + ': ' +
                '$' + Highcharts.numberFormat(Number(point.y), 2,',') + ' K';
          }, '<b>' + this.x + '</b>');
        },
        shared: true
      },
      series: series
    });
  }

  setGraficoBarraDatosEvCompra(periodos: any[], valuesCompras: any[]): void {
    const colores: string[] = [
      '#007bff',
      '#c51f20',
      '#f0f05f',
      '#b2e98c'
    ];
    const series: any[] = [];
    const anios = this.obtenerObjectAnios(periodos, valuesCompras);
    let indexColor = 0;
    for (const [key, value] of Object.entries(anios)) {
      series.push({
        name: `Compras ${key}`,
        type: 'column',
        data: value,
        color:  colores[indexColor] || '#007bff',
      })
      indexColor += 1;
    }

    this.graphEvCompraB = new Chart({
      xAxis: [{
        categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.x + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: series
    });
  }

  setGraficoBarraDatosEvVenta(periodos: any[], valuesVentas: any[]): void {
    const colores: string[] = [
      '#007bff',
      '#c51f20',
      '#f0f05f',
      '#b2e98c'
    ];
    const series: any[] = [];
    const anios = this.obtenerObjectAnios(periodos, valuesVentas);
    let indexColor = 0;
    for (const [key, value] of Object.entries(anios)) {
      series.push({
        name: `Ventas ${key}`,
        type: 'column',
        data: value,
        color:  colores[indexColor] || '#007bff',
      })
      indexColor += 1;
    }

    this.graphEvVentaB = new Chart({
      xAxis: [{
        categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.x + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: series
    });
  }

  // Fin Graficos Evolutivos

  // Graficos Distribucion por compras/ventas - cliente/proveedor

  setGraficoBarraDistribucionCompra(datosCompras: any[]): void {
    const categorias: any[] = [];
    const valuesCompras: any[] = [];
    datosCompras.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    
    datosCompras.forEach(e => {
      categorias.push(e?.nombre);
      valuesCompras.push(e?.montoK);
    })


    this.graphDisCompraB = new Chart({
      xAxis: [{
        categories: categorias
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.x + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Compras',
        type: 'column',
        data: valuesCompras,
        color: '#007bff'
      }]
    });
  }

  setGraficoBarraDistribucionVenta(datosVentas: any[]): void {
    const categorias: any[] = [];
    const valuesVentas: any[] = [];
    datosVentas.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    
    datosVentas.forEach(e => {
      categorias.push(e?.nombre);
      valuesVentas.push(e?.montoK);
    })


    this.graphDisVentaB = new Chart({
      xAxis: [{
        categories: categorias
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.x + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Ventas',
        type: 'column',
        data: valuesVentas,
        color: '#007bff'
      }]
    });
  }

  setGraficoPieDistribucionCompra(datosCompras: any[]): void {
    const values: any = datosCompras.map(({nombre, montoK}) => [nombre, montoK]);
    this.graphDisCompraP = new Chart({
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Compras',
        type: 'pie',
        data: values,
        colors: ["#2e71b0","#1d4978","#235b7c","#2a6c7f","#327e82","#3b9084","#45a185","#4fb386","#5ac685","#65d883"],
      }]
    });
  }

  setGraficoPieDistribucionVenta(datosVentas: any[]): void {
    const values: any = datosVentas.map(({nombre, montoK}) => [nombre, montoK]);
    this.graphDisVentaP = new Chart({
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Ventas',
        type: 'pie',
        data: values,
        colors: ["#2e71b0","#1d4978","#235b7c","#2a6c7f","#327e82","#3b9084","#45a185","#4fb386","#5ac685","#65d883"],
      }]
    });
  }

  setGraficoPieCompraPorAnio(datosCompras: any): void {
    const values: any []= []; 
    for (const [key, value] of Object.entries(datosCompras)) {
      const valor: number = value as number;
      values.push([key, Number(this.divisionNumber(valor, 1000).toFixed(2))])
    }

    this.graphDisCompraPorAnio = new Chart({
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Compras',
        type: 'pie',
        data: values,
        colors: [
          '#007bff',
          '#c51f20',
          '#f0f05f',
          '#b2e98c'
        ],
      }]
    });
  }

  setGraficoPieVentaPorAnio(datosVentas: any): void {
    const values: any []= []; 
    for (const [key, value] of Object.entries(datosVentas)) {
      const valor: number = value as number;
      values.push([key, Number(this.divisionNumber(valor, 1000).toFixed(2))])
    }

    this.graphDisVentaPorAnio = new Chart({ // cambiar
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '${value} K',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
        }
      },
      series: [{
        name: 'Ventas',
        type: 'pie',
        data: values,
        colors: [
          '#007bff',
          '#c51f20',
          '#f0f05f',
          '#b2e98c'
        ],
      }]
    });
  }

  setGraficoPieTipoCompra(datosCompras: any): void {
    const values: any []= [];
    
    values.push(['Del Giro', Number(datosCompras.delGiro)])
    values.push(['No Del Giro', Number(datosCompras.noDelGiro)])

    this.graphDisTipoCompra = new Chart({
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + this.y;
        }
      },
      series: [{
        name: 'N° Compras',
        type: 'pie',
        data: values,
        colors: [
          '#007bff',
          '#c51f20',
          '#f0f05f',
          '#b2e98c'
        ],
      }]
    });
  }

  setGraficoPieTipoVenta(datosVentas: any): void {
    const values: any []= [];
    
    values.push(['Del Giro', Number(datosVentas.delGiro)])
    values.push(['No Del Giro', Number(datosVentas.noDelGiro)])

    this.graphDisTipoVenta = new Chart({
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      tooltip: {
        formatter: function() {
          return ''+ this.key + '<br/>' +
            this.series.name +': ' + this.y;
        }
      },
      series: [{
        name: 'N° Ventas',
        type: 'pie',
        data: values,
        colors: [
          '#007bff',
          '#c51f20',
          '#f0f05f',
          '#b2e98c'
        ],
      }]
    });
  }

  // Fin Graficos Distribucion por compras/ventas - cliente/proveedor

  getPercents(title: string): boolean {
    const valuesKeys = [
      "porcVentasFacturas",
      "porcVentasBoletas",
      "porcVentasNC",
      "porcCrecVentasUltimoMes",
      "porcCrecVentasUltimoAnio"
    ];
    return valuesKeys.includes(title);
  }

  getMontos(title: string): boolean {
    const valuesKeys = [
      'ventasPromedio',
      'compraPromedio',
      'margen',
      'margenPromedio',
      'ventaUltimoMes',
      'ventaAnioActual'
    ];
    return valuesKeys.includes(title);
  }

  getPage(event: number, forzar: boolean = false): void {
    this.paginasActualesErrores = [];
    if((this.seeCompras && this.cantidadRegistrosCompra > 0) || (this.seeVentas && this.cantidadRegistrosVenta > 0) || forzar) {
      const tipoActual: string = this.seeVentas ? 'venta' : (this.seeCompras ? 'compra' : '');
      const elemento: any = this.paginasActuales.find(e => e.pagina == (event))

      if((this.tipoPagina != tipoActual || elemento == undefined || forzar)){
        if(this.tipoPagina != tipoActual || forzar){
          this.paginasActuales = [];
        }

        if(event == 1){
          const paginas: number[] = [];
          for(let i = 0; i < 3; i++){
            const pagina = event + i;
            if(pagina > 0 && pagina <= this.cantidadPaginas){
              paginas.push(pagina);
            }
          }
          this.obtenerPaginas(paginas, event);
          this.tipoPagina = tipoActual
        } else if(event > 1 && event <= this.cantidadPaginas) {
          const pagina: number = event;
          const paginas: number[] = [pagina - 1, pagina];
          if(event + 1 <= this.cantidadPaginas)
            paginas.push(pagina + 1);
          this.obtenerPaginas(paginas, event);
          this.tipoPagina = tipoActual
        } 
      } else {
        this.paginaActual = this.mapeoTipoDocumento(elemento?.response) || [undefined];
      }
    }

    this.currentPage = event;
  }

  obtenerCantidadPaginas(forzar: boolean = false): void {
    if(this.seeVentas && this.cantidadRegistrosVenta > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistrosVenta / this.currentItem);
    }
    else if(this.seeCompras && this.cantidadRegistrosCompra > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistrosCompra / this.currentItem);
    }
    if(this.cantidadPaginas > 0) {
      this.getPage(1, forzar);
    } else {
      this.spinner.hide();
    }
  }

  setError(pagina: number, error: string): void {
    this.paginasActualesErrores.push({
      pagina: pagina,
      error: error
    })
  }

  setResponse(response: any[], pagina: number): void {
    this.paginasActuales.push({
      pagina: pagina,
      response: response || null
    });
  }

  getServicePagina(pagina: number, cantidadElementos: number, tipo: string, esTipoDocNC: boolean | null): any {
    const meses: string = this.filtroForm.get('selectPeriodos')?.value || '36';
    const rutFiltro = this.filtroForm.get('entidadRut')?.value;

    return this.compraVentaService.obtenerPagina(this.rut, pagina, cantidadElementos, tipo, meses, rutFiltro, this.periodoInicial, esTipoDocNC)
      .pipe(
        map(resp => {
          this.setResponse(resp, pagina);
        })
      )
      .pipe(
        catchError((error) => (this.setError(pagina, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  obtenerPaginas(paginas:  number[], paginaActual: number): void {
    const tipo: string = this.seeVentas ? 'VENTA' : (this.seeCompras ? 'COMPRA' : '');
    let esTipoDocNC: boolean | null = null;
    if (this.seeVentas || this.seeCompras) {
      esTipoDocNC = false;
    }
    const apiServices: any = [];
    if(this.cantidadPaginas > 0 && paginas.length > 0 && tipo) {
      paginas.forEach(pagina => {
        if(pagina > 0 && pagina <= this.cantidadPaginas && !this.paginasActuales.some(e => e?.pagina == pagina)) {

          apiServices.push(
            {
              service: this.getServicePagina(pagina, this.currentItem, tipo, esTipoDocNC),
              pagina: pagina
            }
          );

        }
      });

      // se quitan elementos que no se desean consultar
      this.paginasActuales = this.paginasActuales.filter(item =>
        paginas.includes(item.pagina) && 
        item.pagina > 0 && 
        item.pagina <= this.cantidadPaginas
      );

    }
    if(apiServices.length > 0) {
      this.spinner.show();
      this.llamadaObtenerPaginas(apiServices, paginaActual);
    }
  }

  llamadaObtenerPaginas(apiServices: any[], paginaActual: number): void {
    const services = apiServices.map(e => e?.service);

    forkJoin(services).subscribe(
      resp => {
        if(this.paginasActualesErrores.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErrores.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        const elemento: any = this.paginasActuales.find(e => e.pagina == (paginaActual))
        this.paginaActual = this.mapeoTipoDocumento(elemento?.response) || [undefined];

        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
        this.spinner.hide();
      }
    );
  }

  mapeoTipoDocumento(paginaActual: any[] | any): any {
    if(paginaActual && paginaActual.length > 0) {
      for(const e of paginaActual) {
        e.tipoDocumentoMap = this.seeVentas ? this.compraVentaService.getTipoDocumento(e?.TipoDoc) : 
        (this.seeCompras ? this.compraVentaService.getTipoDocumento(e?.TipoDoc) : 'Sin Información')
      }
    }
    return paginaActual;
  }

  esFormatoFecha(cadena: string): boolean {
    if (typeof cadena !== 'string' || !cadena) {
      return false;
    }

    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    return regex.test(cadena);
  }

  cambiarFormatoFecha(cadena: string): any {
    if (!this.esFormatoFecha(cadena)) {
      return cadena;
    }
    
    return cadena.replace(/\//g, "-");
  }

  esFormatoFechaHora(cadena: string): boolean {
    if (typeof cadena !== 'string' || !cadena) {
      return false;
    }

    const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    return regex.test(cadena);
  }

  cambiarFormatoFechaHora(cadena: string): any {
    if (!this.esFormatoFechaHora(cadena)) {
      return cadena;
    }
    
    return cadena.replace(/\//g, "-");
  }

  setErrorCalculo(tipo: string, error: string): void {
    this.calulosErrores.push({
      tipo: tipo,
      error: error
    })
  }

  setResponseCalculo(response: any, tipo: string): void {
    this.respuestaCalculos[tipo] = response;
    if(tipo === 'VENTA') {
      const datosBoletas: any = response?.resumen || {};

      this.tablaBoletas = this.crearTablaBoletaByTipoDocumento(datosBoletas, ['35', '38', '39', '41', '105']);
      this.tablaComprobantesElectronicos = this.crearTablaBoletaByTipoDocumento(datosBoletas, ['48']);
      this.tablaOtrasVentas = this.crearTablaBoletaByTipoDocumento(datosBoletas, ['919', '920', '922', '924']);
    }
  }

  getServiceCalculo(tipo: string): any { // tipos: (con todo) COMPRA, VENTA, (sin nc) COMPRANC, VENTANC
    const meses: string = this.filtroForm.get('selectPeriodos')?.value || '36';
    const rutFiltro = this.filtroForm.get('entidadRut')?.value;
    let esTipoDocNC: boolean | null = null;
    if(tipo === 'VENTANC' || tipo === 'COMPRANC') {
      esTipoDocNC = false;
    }

    const tipoConsulta = tipo === 'COMPRANC' || tipo === 'COMPRA' ? 'COMPRA' : (tipo === 'VENTANC' || tipo === 'VENTA' ? 'VENTA' : tipo);

    return this.compraVentaService.obtenerCalculos(this.rut, tipoConsulta, meses, rutFiltro, this.periodoInicial, esTipoDocNC)
      .pipe(
        map(resp => {
          this.setResponseCalculo(resp, tipo);
        })
      )
      .pipe(
        catchError((error) => (this.setErrorCalculo(tipo, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  resizeEvent(): void { // se lanza evento para que graficos se redimensionen
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  mapeoElementos(datos: any, tipo: string): void {
    if(tipo === 'VENTA') {
      this.datosGeneralesVenta = {
        montoTotal: datos?.datosGenerales?.montoTotal || datos?.datosGenerales?.montoTotal == 0 ? datos?.datosGenerales?.montoTotal : "",
        cantidadClientes: datos?.datosGenerales?.cantidadRutDoc || datos?.datosGenerales?.cantidadRutDoc == 0 ? datos?.datosGenerales?.cantidadRutDoc : "",
        montoPromedio: datos?.datosGenerales?.montoTotalPromedio || datos?.datosGenerales?.montoTotalPromedio == 0 ? datos?.datosGenerales?.montoTotalPromedio : "",
      };

      const graphEv: any = datos?.graphEv || Object.keys(datos?.graphEv).length > 0 ? datos?.graphEv : null;
      if(graphEv) {
        this.setGraficoLineaDatosEvVenta(datos?.graphEv?.categorias || [], datos?.graphEv?.values || []);
        this.setGraficoBarraDatosEvVenta(datos?.graphEv?.categorias || [], datos?.graphEv?.values || []);
      }

      const graphDis: any = datos?.graphDis || Object.keys(datos?.graphDis).length > 0 ? datos?.graphDis : null;
      if(graphDis) {
        this.setGraficoBarraDistribucionVenta(datos?.graphDis || []);
        this.setGraficoPieDistribucionVenta(datos?.graphDis || []);
        this.generarTablaTop10Clientes(datos?.graphDis || []);
      }

      const graphDisPorAnio: any = datos?.graphDisPorAnio || Object.keys(datos?.graphDisPorAnio).length > 0 ? datos?.graphDisPorAnio : null;
      if(graphDisPorAnio) {
        this.setGraficoPieVentaPorAnio(datos?.graphDisPorAnio || {});
      }

      const graphDisTipo: any = datos?.graphDisTipo || Object.keys(datos?.graphDisTipo).length > 0 ? datos?.graphDisTipo : null;
      if(graphDisTipo) {
        this.setGraficoPieTipoVenta(datos?.graphDisTipo || {});
      }

      this.resizeEvent();

    } else if(tipo === 'COMPRA') {
      this.datosGeneralesCompra = {
        montoTotal: datos?.datosGenerales?.montoTotal || datos?.datosGenerales?.montoTotal == 0 ? datos?.datosGenerales?.montoTotal : "",
        cantidadProveedores: datos?.datosGenerales?.cantidadRutDoc || datos?.datosGenerales?.cantidadRutDoc == 0 ? datos?.datosGenerales?.cantidadRutDoc : "",
        montoPromedio: datos?.datosGenerales?.montoTotalPromedio || datos?.datosGenerales?.montoTotalPromedio == 0 ? datos?.datosGenerales?.montoTotalPromedio : "",
      };

      const graphEv: any = datos?.graphEv || Object.keys(datos?.graphEv).length > 0 ? datos?.graphEv : null;
      if(graphEv) {
        this.setGraficoLineaDatosEvCompra(datos?.graphEv?.categorias || [], datos?.graphEv?.values || []);
        this.setGraficoBarraDatosEvCompra(datos?.graphEv?.categorias || [], datos?.graphEv?.values || []);
      }

      const graphDis: any = datos?.graphDis || Object.keys(datos?.graphDis).length > 0 ? datos?.graphDis : null;
      if(graphDis) {
        this.setGraficoBarraDistribucionCompra(datos?.graphDis || []);
        this.setGraficoPieDistribucionCompra(datos?.graphDis || []);
        this.generarTablaTop10Proveedores(datos?.graphDis || []);
      }

      const graphDisPorAnio: any = datos?.graphDisPorAnio || Object.keys(datos?.graphDisPorAnio).length > 0 ? datos?.graphDisPorAnio : null;
      if(graphDisPorAnio) {
        this.setGraficoPieCompraPorAnio(datos?.graphDisPorAnio || {});
      }

      const graphDisTipo: any = datos?.graphDisTipo || Object.keys(datos?.graphDisTipo).length > 0 ? datos?.graphDisTipo : null;
      if(graphDisTipo) {
        this.setGraficoPieTipoCompra(datos?.graphDisTipo || {});
      }

      this.resizeEvent();

    }

  }

  mapeoDatosGenerales(venta: any, compra: any): void {
    const datosVenta = venta?.datosGenerales && Object.keys(venta?.datosGenerales).length > 0 ? venta?.datosGenerales : null;
    const datosCompra = compra?.datosGenerales && Object.keys(compra?.datosGenerales).length > 0 ? compra?.datosGenerales : null;

    if(datosCompra && datosVenta) {
      
      const meses: string = this.filtroForm.get('selectPeriodos')?.value || '36';
      
      const totalVentasFactura: number = datosVenta?.montoTotalFacturas || 0;
      const totalVentas: number = datosVenta?.montoTotalNoNotaCredito || 0;
      const totalVentasNC: number = datosVenta?.montoTotalNotaCredito || 0;
      const totalVentasMonto: number = (totalVentas - totalVentasNC) || 0;
      
      const porcVentasFacturas: number = (totalVentasFactura / totalVentasMonto) * 100;
      const cantVentas: number = datosVenta?.totalRegistros || 0;
      const ventasPromedio: number = (totalVentasMonto / cantVentas);
      
      const montoTotalCompra: number = datosCompra?.montoTotalNoNotaCredito || 0;
      const totalComprasNC: number = datosCompra?.montoTotalNotaCredito || 0;
      const totalComprasMonto: number = (montoTotalCompra - totalComprasNC) || 0;
      const totalCompras: number = datosCompra?.totalRegistros || 0;
      const compraPromedio: number = (totalComprasMonto / totalCompras);

      const margen: number = (totalVentasMonto - totalComprasMonto);

      const porcVentasNC: number = (totalVentasNC / totalVentasMonto)  * 100;

      const periodoActual = this.periodoInicial.toString();
      const periodoAnterior = (Number(periodoActual) - 1).toString();
      const ventaTotalAnioActual = venta?.graphDisPorAnio?.[periodoActual] || 0;
      const ventaTotalAnioAnterior = venta?.graphDisPorAnio?.[periodoAnterior] || 0;
      const porcCrecVentasUltimoAnio: number = ((ventaTotalAnioActual - ventaTotalAnioAnterior) / ventaTotalAnioAnterior) * 100;

      const totalVentasBoleta: number = datosVenta?.montoTotalBoletas || 0;
      const porcVentasBoletas: number = (totalVentasBoleta / totalVentasMonto) * 100;

      const nClientes: number = datosVenta?.cantidadRutDoc || 0;
      const nProveedores: number = datosCompra?.cantidadRutDoc || 0;

      const margenPromedio: number = (totalVentasMonto - totalComprasMonto) / Number(meses);

      const ventaTotalUltimoMes: number = datosVenta?.montoTotalMesReciente || 0;
      const ventaTotalMesAnterior: number = datosVenta?.montoTotalMesAnterior || 0;

      const porcCrecVentasUltimoMes: number = ((ventaTotalUltimoMes - ventaTotalMesAnterior) / ventaTotalMesAnterior) * 100;

      this.estadisticosGenerales = {
        porcVentasFacturas: {name: "% Ventas con facturas", value: this.validaInfinito(porcVentasFacturas)},
        porcVentasBoletas: {name: "% Ventas con Boletas", value: this.validaInfinito(porcVentasBoletas)},
        ventasPromedio: {name: "Venta Promedio", value: this.validaInfinito(ventasPromedio)},
        nClientes: {name: "N° Clientes", value: this.validaInfinito(nClientes)},
        compraPromedio: {name: "Compra Promedio", value: this.validaInfinito(compraPromedio)},
        nProveedores: {name: "N° Proveedores", value: this.validaInfinito(nProveedores)},
        margen: {name: "Margen", value: this.validaInfinito(margen)},
        margenPromedio: {name: "Margen Promedio", value: this.validaInfinito(margenPromedio)},
        porcVentasNC: {name: "% NC sobre ventas", value: this.validaInfinito(porcVentasNC)},
        ventaUltimoMes: {name: "Venta Ultimo Mes", value: this.validaInfinito(ventaTotalUltimoMes)},
        ventaAnioActual: {name: "Ventas año Actual", value: this.validaInfinito(ventaTotalAnioActual)},
        porcCrecVentasUltimoMes: {name: "% Crecimiento Ventas ultimo mes", value: this.validaInfinito(porcCrecVentasUltimoMes)},
        porcCrecVentasUltimoAnio: {name: "% Crecimiento ventas vs año anterior", value: this.validaInfinito(porcCrecVentasUltimoAnio)},
      }
    }
  }

  orquestadorMapeos(): void {
    this.datosGeneralesVenta = {};
    this.tablaTop10Clientes = [];
    this.graphEvVentaL = {};
    this.graphDisVentaB = {};
    this.graphEvVentaB = {};
    this.graphDisVentaP = {};
    this.graphDisVentaPorAnio = {};
    this.graphDisTipoVenta = {};
    this.datosGeneralesCompra = {};
    this.tablaTop10Proveedores = [];
    this.graphEvCompraL = {};
    this.graphDisCompraB = {};
    this.graphEvCompraB = {};
    this.graphDisCompraP = {};
    this.graphDisCompraPorAnio = {};
    this.graphDisTipoCompra = {};
    this.estadisticosGenerales = {};

    const tipoActual: string = this.seeVentas ? 'VENTA' : (this.seeCompras ? 'COMPRA' : '');
    const respuestaTipo: any = this.respuestaCalculos?.[tipoActual] || {};

    if(respuestaTipo && Object.keys(respuestaTipo).length > 0) {
      this.mapeoElementos(respuestaTipo, tipoActual);
    }

    const datosVenta: any = this.respuestaCalculos?.['VENTA'] && Object.keys(this.respuestaCalculos?.['VENTA']).length > 0 ? this.respuestaCalculos['VENTA'] : null;
    const datosCompra: any = this.respuestaCalculos?.['COMPRA'] && Object.keys(this.respuestaCalculos?.['COMPRA']).length > 0 ? this.respuestaCalculos['COMPRA'] : null;

    if(datosVenta && datosCompra){
      this.mapeoDatosGenerales(datosVenta, datosCompra);
    }
  }

  llamadaCalculos(obtenerRuts: boolean = true, validaCantidad: boolean = false): void {
    this.tablaBoletas = [];
    this.tablaComprobantesElectronicos = [];
    this.tablaOtrasVentas = [];

    const apiServices: any = [];

    if(!this.seeNC) { // en notas de credito solo se usa ventas
      apiServices.push(this.getServiceCalculo('COMPRA'));
      apiServices.push(this.getServiceCalculo('VENTA'));

      apiServices.push(this.getServiceCalculo('COMPRANC'));
      apiServices.push(this.getServiceCalculo('VENTANC'));
    }

    if(apiServices.length > 0){
      this.spinner.show();
      forkJoin(apiServices).subscribe(
        resp => {
          if(this.calulosErrores.length > 0) 
          {
            this.alertService.error('Ha ocurrido un error al obtener los calculos de compra y venta');
            this.spinner.hide();
            this.logicaRetorno();
          } else {
            this.cantidadRegistrosCompra = 0;
            this.cantidadRegistrosVenta = 0;

            this.cantidadRegistrosVenta = this.respuestaCalculos?.['VENTANC']?.datosGenerales?.totalRegistrosPaginacion ? this.respuestaCalculos?.['VENTANC']?.datosGenerales?.totalRegistrosPaginacion : 0;
            this.cantidadRegistrosCompra = this.respuestaCalculos?.['COMPRANC']?.datosGenerales?.totalRegistrosPaginacion ? this.respuestaCalculos?.['COMPRANC']?.datosGenerales?.totalRegistrosPaginacion : 0;
            
            if(validaCantidad) {
              if(this.cantidadRegistrosCompra > 0 || this.cantidadRegistrosVenta > 0) {
                this.orquestadorMapeos();
                if(obtenerRuts){
                  this.obtenerRuts();
                }
                this.obtenerCantidadPaginas(true);
              } else {
                this.alertService.error('No se encontraron registros para los filtros seleccionados');
                this.spinner.hide();
                this.logicaRetorno();
              }
            } else {
              this.orquestadorMapeos();
              if(obtenerRuts){
                this.obtenerRuts();
              }
              this.obtenerCantidadPaginas(true);
            }
            
          }
        },
        error => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener los calculos');
          this.spinner.hide();
          this.logicaRetorno();
        }
      );
    }
  }

  cambioPaginacion(): void {
    this.obtenerCantidadPaginas(true);
  }

  obtenerRuts(): void {
    this.listaRuts = [];
    const meses: string = this.filtroForm.get('selectPeriodos')?.value || '36';
    const tipo: string = this.seeVentas ? 'VENTA' : (this.seeCompras ? 'COMPRA' : '');
    const esTipoDocNC: boolean | null = this.seeVentas || this.seeCompras ? false : null;

    this.showSpinnerRut = true;
    this.compraVentaService.obtenerListaRuts(this.rut, tipo, meses, this.periodoInicial, esTipoDocNC).subscribe(
      (resp: any) => {
        if (resp && Array.isArray(resp) && resp.length > 0) {
          for(const element of resp) {
            this.listaRuts.push({rut: `${element?.rutDoc}-${element?.dvDoc}`, nombre: `${element?.razonSocial}`});
          }
          this.showSpinnerRut = false;
        } else {
          console.warn('Se obtuvo filtro de ruts vacio');
          this.showSpinnerRut = false;
        }
      },
      ({ error }) => {
        console.error(error.message || 'Ocurrió un error al obtener el filtro de ruts');
        this.showSpinnerRut = false;
    });
  }

  getEstadoVenta(element: any): string {
    let estado: string = '';
    if(element && Object.keys(element).length > 0) {
      const detEventoReceptor: string = element?.detEventoReceptor || '';
      const detEventoReceptorLeyenda: string = element?.detEventoReceptorLeyenda || '';
      const fechaAcuseRecibo: string = element?.FechaAcuseRecibo || '';
      const fechaDocto: string = element?.FechaDocto || '';
      let diasFechaDocto: number|null = null;
      if(fechaDocto){
        diasFechaDocto = this.esFormatoFecha(fechaDocto) ? this.cambiarFormatoFecha(fechaDocto) : fechaDocto;
        if(diasFechaDocto) {
          const fechaDeseada = new Date(diasFechaDocto);
          const diferenciaMilisegundos = Math.abs(Date.now() - fechaDeseada.getTime());
          diasFechaDocto = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
        }
      }

      if(detEventoReceptor.toUpperCase() === 'R' && detEventoReceptorLeyenda.toUpperCase() === 'RECLAMADO POR EL RECEPTOR') {
        estado = 'Reclamado por el receptor';
      } else if(!fechaAcuseRecibo && !detEventoReceptor && diasFechaDocto != null && diasFechaDocto < 8) {
        estado = 'Pendiente';
      } else if(fechaAcuseRecibo) {
        estado = 'Recibo Otorgado por el receptor';
      }
    }
    return estado;
  }

  getFormaPago(element: any): string {
    let formaPago: string = '';
    if(element && Object.keys(element).length > 0) {
      const detEventoReceptor: string = element?.detEventoReceptor || '';
      const detEventoReceptorLeyenda: string = element?.detEventoReceptorLeyenda || '';
      if(detEventoReceptor.toUpperCase() === 'P' && detEventoReceptorLeyenda.toUpperCase() === 'FORMA DE PAGO CONTADO') {
        formaPago = 'Contado';
      } else if((detEventoReceptor.toUpperCase() === 'C' && detEventoReceptorLeyenda.toUpperCase() === 'RECIBO OTORGADO POR EL RECEPTOR')
        || (!detEventoReceptor && !detEventoReceptorLeyenda)) {
        formaPago = 'Crédito';
      }
    }
    return formaPago;
  }

  crearTablaBoletaByTipoDocumento(datos: any, codigos: string[]): any[] {
    const tabla: any[] = [];
    if(datos && Object.keys(datos).length > 0) {
      for(const codigo of codigos) {
        const data = datos?.[Number(codigo)];
        if(data) {
          const tipo = this.compraVentaService.getTipoDocumento(codigo);
          tabla.push({
            nombre: `${tipo} (${codigo})`,
            totalDocumentos: data?.totalDocumentos,
            montoExtento: data?.montoExtento,
            montoNeto: data?.montoNeto,
            montoIva: data?.montoIva,
            montoIvaNoRec: data?.montoIvaNoRec,
            montoIvaUsoComun: data?.montoIvaUsoComun,
            montoTotal: data?.montoTotal
          });
        }
      }
    }

    return tabla || [];
  }

  descargarRegistros(tipo: string): void {
    if(tipo !== 'COMPRA' && tipo !== 'VENTA')
      return;
    
    if(tipo === 'VENTA' && this.cantidadRegistrosVenta <= 0)
      return;
    if(tipo === 'COMPRA' && this.cantidadRegistrosCompra <= 0)
      return;

    const meses: string = this.filtroForm.get('selectPeriodos')?.value || '36';
    const rutFiltro = this.filtroForm.get('entidadRut')?.value;

    const operacion: string = tipo === 'VENTA' ? 'VENTA' : 'COMPRA';
    
    let esTipoDocNC: boolean | null = null;
    if (this.seeVentas || this.seeCompras) {
      esTipoDocNC = false;
    }

    this.spinner.show();

    this.compraVentaService.descargarRegistros(this.rut, this.currentPage, this.currentItem, operacion, meses, rutFiltro, this.periodoInicial, esTipoDocNC).subscribe(
      (resp: any) => {
        if(resp && resp?.url) {
          const url = resp.url;
          window.open(url, '_blank');
        } else {
          this.alertService.error('No se obtuvo url para descargar registros');
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Ocurrió un error al descargar los registros');
        this.spinner.hide();
    });
    
  }

}
