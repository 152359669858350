    <div class="container__form d-flex justify-content-center text-center" *ngIf="!showReporte">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="consolidadoReportForm" (ngSubmit)="submit()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block text-left">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary mr-3"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="box-reporte-consolidado" *ngIf="showReporte" afterPrint [title]='titlePrint'>
        <div class="panel panel-default not-print">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button *ngIf="showReporteGeneral" (click)="printPdf()"
                        class="btn btn-primary float-right">Descargar PDF</button>
                </div>
            </div>
        </div>
        <!-- <div id="print-section"> -->
        <accordion [isAnimated]="true">
            <accordion-group heading="Reportes disponibles" [isOpen]="true" panelClass="not-print bg-transparent">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="card shadow-lg border-0 float-right">
                            <div class="card-body pb-2 font-weight-bold">
                                Seleccionar Todo
                                <label class="switch switch-primary">
                                    <input type="checkbox" (click)="selectAll($event)"
                                        [disabled]="showReporteGeneral">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Reporte basico -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !reporteBasicohasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="gi gi-notes_2 sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">
                    
                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteBasico')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" (click)="switchReporte($event)"
                                                        id="reporteBasico"
                                                        [(ngModel)]="switchReporteBasico">
                                                    <span></span>
                                                </label>
                                            </ng-container>
                    
                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('reporteBasico')?.error">
                    
                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('reporteBasico').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteBasico()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>
                    
                                            <ng-container *ngIf="showReporteGeneral && !filterObjetoGeneral('reporteBasico')?.error && (filterObjetoGeneral('reporteBasico')?.body || filterObjetoGeneral('reporteBasico')?.obj)">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>
                    
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2 mb-0">Reporte básico</h4>
                                        <div class="d-flex">
                                            <div class="flex-fill">
                                                <h5 class="pt-1">Con informe Comercial</h5>
                                            </div>
                                            <div class="flex-fill text-right">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label" for="radio1">
                                                        <input type="radio" class="form-check-input" id="radio1"
                                                            name="conInforme" value="11"
                                                            [(ngModel)]="radioReporteBasico"
                                                            (click)="limpiarVendor()"
                                                            [disabled]="showReporteGeneral || !permisoReporteComercial">Si
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label" for="radio2">
                                                        <input type="radio" class="form-check-input" id="radio2"
                                                            name="conInforme" value="10"
                                                            [(ngModel)]="radioReporteBasico"
                                                            (click)="limpiarVendor()"
                                                            [disabled]="showReporteGeneral || !permisoReporteComercial">No
                                                    </label>
                                                </div>
                                            </div>
                    
                                        </div>
                    
                                        <span class="badge badge-success span-clase mb-1" *ngIf="vendor">{{vendor || ''}}</span>
                                        
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteBasico">
                                                    {{objReporteBasico?.InformacionBasica?.FechaReporte | date:
                                                    'dd-MM-yyyy'}}
                                                </ng-container>
                    
                                                <ng-template #problemaReporteBasico>
                                                    <div class="text-heart">
                                                        {{filterObjetoGeneral('reporteBasico')?.msg}}</div>
                                                </ng-template>
                    
                                                <ng-container *ngIf="!objReporteBasico">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('reporteBasico')?.msg; else problemaReporteBasico">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteBasico')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteBasico"
                                                (click)="updateGlobalReport($event, 'reporteBasico'); limpiarVendor()"
                                                [disabled]="(!filterObjetoGeneral('reporteBasico') || !filterObjetoGeneral('reporteBasico')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Informe Judicial -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !reporteJudicialhasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-balance-scale sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteJudicial')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" (click)="getReporteJudicial($event)"
                                                        id="poderJudicial"
                                                        [(ngModel)]="switchReporteJudicial">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('reporteJudicial')?.error">

                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('reporteJudicial').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteJudicial()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>

                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('reporteJudicial')?.error && filterObjetoGeneral('reporteJudicial')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Informe Judicial</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteJudicial">
                                                    {{formatDate(objReporteJudicial.poderJudicialReporte?.datosBasicosSolicitud?.FechaReporte
                                                    || '')
                                                    | date:
                                                    'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaReporteJudicial>
                                                    <div class="text-heart">
                                                        {{filterObjetoGeneral('reporteJudicial')?.msg}}</div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteJudicial">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('reporteJudicial')?.msg; else problemaReporteJudicial">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteJudicial')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteJudicial"
                                                (click)="updateGlobalReport($event, 'reporteJudicial')"
                                                [disabled]="(!filterObjetoGeneral('reporteJudicial') || !filterObjetoGeneral('reporteJudicial')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Boletin Concursal -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !boletinConcursalhasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-newspaper-o sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('boletinConcursal')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" (click)="getReporteBoletinConcursal($event)"
                                                        id="boletinConcursal"
                                                        [(ngModel)]="switchReporteBoletinConcursal">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('boletinConcursal')?.error">

                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('boletinConcursal').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteBoletinConcursal()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>

                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('boletinConcursal')?.error && filterObjetoGeneral('boletinConcursal')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Boletin Concursal</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteBoletinConcursal">
                                                    {{formatDate(objReporteBoletinConcursal?.boletinConcursalReporte?.datosBasicosSolicitud?.FechaReporte
                                                    || '')
                                                    | date: 'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaBoletinConcursal>
                                                    <div class="text-heart">
                                                        {{filterObjetoGeneral('boletinConcursal')?.msg}}</div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteBoletinConcursal">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('boletinConcursal')?.msg; else problemaBoletinConcursal">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('boletinConcursal')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteBoletinConcursal"
                                                (click)="updateGlobalReport($event, 'boletinConcursal')"
                                                [disabled]="(!filterObjetoGeneral('boletinConcursal') || !filterObjetoGeneral('boletinConcursal')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Deuda Sbif -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !deudaSbifhasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-credit-card sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('deudaSbif')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" [(ngModel)]="switchReporteDeudaSbif"
                                                        id="deudaSbif"
                                                        (click)="getReporteDeudaSbif($event)">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('deudaSbif')?.error">
                                                        
                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('deudaSbif')?.error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteDeudaSbif()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>

                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('deudaSbif')?.error  && filterObjetoGeneral('deudaSbif')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Deuda Sbif</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteDeudaSbif">
                                                    <ng-template #sbifSinInfo>
                                                        ---------
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="objReporteDeudaSbif?.Documentos?.SbifOnline || 
                                                objReporteDeudaSbif?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline; else sbifSinInfo">

                                                        <ng-template #otherDeudaSbif>
                                                            {{formatDate(objReporteDeudaSbif?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.DatosBasicosSolicitud?.FechaReporte
                                                            || '')
                                                            | date: 'dd-MM-yyyy'}}
                                                        </ng-template>
                                                        <ng-container
                                                            *ngIf="objReporteDeudaSbif?.Documentos; else otherDeudaSbif">
                                                            {{formatDate(objReporteDeudaSbif?.Documentos?.SbifOnline?.DatosBasicosSolicitud?.FechaReporte
                                                            || '')
                                                            | date: 'dd-MM-yyyy'}}
                                                        </ng-container>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-template #problemaDeudaSbif>
                                                    <div class="text-heart">{{filterObjetoGeneral('deudaSbif')?.msg}}
                                                    </div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteDeudaSbif">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('deudaSbif')?.msg; else problemaDeudaSbif">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('deudaSbif')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteDeudaSbif"
                                                (click)="updateGlobalReport($event, 'deudaSbif')"
                                                [disabled]="(!filterObjetoGeneral('deudaSbif') || !filterObjetoGeneral('deudaSbif')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Reporte Financiero -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !reporteFinancierohasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-bar-chart sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteFinanciero')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" [(ngModel)]="switchReporteFinanciero"
                                                        id="reporteFinanciero"
                                                        (click)="getReporteFinanciero($event)">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('reporteFinanciero')?.error">

                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('reporteFinanciero').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteFinanciero()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>

                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('reporteFinanciero')?.error && filterObjetoGeneral('reporteFinanciero')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Reporte Financiero</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteFinanciero">
                                                    {{objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte
                                                    | date: 'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaReporteFinanciero>
                                                    <div class="text-heart">
                                                        {{filterObjetoGeneral('reporteFinanciero')?.msg}}</div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteFinanciero">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('reporteFinanciero')?.msg; else problemaReporteFinanciero">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('reporteFinanciero')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="fa-stack fa-lg float-right" [popover]="popTemplateFinanciero"
                                            popoverTitle="Información" placement="bottom"
                                            triggers="mouseenter:mouseleave">
                                             <button class="unstyled-button" (click)="open()" [disabled]="!filterObjetoGeneral('reporteFinanciero')?.busqueda">
                                                <i class="fa fa-refresh sidebar-nav-icon"></i>
                                            </button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Bienes Raices -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !bienesRaiceshasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-home sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('bienesRaices')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" [(ngModel)]="switchReporteBeienesRaices"
                                                        id="reporteBienesRaices"
                                                        (click)="getReporteBienesRaices($event)">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('bienesRaices')?.error">
                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('bienesRaices').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteBienesRaices()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>

                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('bienesRaices')?.error && filterObjetoGeneral('bienesRaices')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Bienes Raices</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteBienesRaices">
                                                    {{objReporteBienesRaices?.bienesraicesReporte?.datosBasicosSolicitud?.FechaReporte
                                                    | date: 'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaBienesRaices>
                                                    <div class="text-heart">{{filterObjetoGeneral('bienesRaices')?.msg}}
                                                    </div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteBienesRaices">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('bienesRaices')?.msg; else problemaBienesRaices">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('bienesRaices')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteBeienesRaices"
                                                (click)="updateGlobalReport($event, 'bienesRaices')"
                                                [disabled]="(!filterObjetoGeneral('bienesRaices') || !filterObjetoGeneral('bienesRaices')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Vehiculos -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0" [ngClass]="{'disabled': !vehiculoshasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-car sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('vehiculos')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" [(ngModel)]="switchReporteVehiculos"
                                                        id="reporteVehiculos"
                                                        (click)="getReporteVehiculos($event)">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('vehiculos')?.error">
                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('vehiculos').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteVehiculos()"
                                                    triggers="mouseenter:mouseleave"></i>
                                                
                                            </ng-container>
                                            
                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('vehiculos')?.error && filterObjetoGeneral('vehiculos')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Vehiculos</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteVehiculos">
                                                    {{objReporteVehiculos?.vehiculoReporte?.datosBasicosSolicitud?.FechaReporte
                                                    | date: 'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaVehiculos>
                                                    <div class="text-heart">{{filterObjetoGeneral('vehiculos')?.msg}}
                                                    </div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteVehiculos">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('vehiculos')?.msg; else problemaVehiculos">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('vehiculos')?.error">
                                    <div class="flex-fill">
                                        <h5 class="font-weight-bold">Actualizar</h5>
                                    </div>
                                    <div class="flex-fill text-right">
                                        <label class="switch switch-primary">
                                            <input type="checkbox" [(ngModel)]="switchUpdateReporteVehiculos"
                                                (click)="updateGlobalReport($event, 'vehiculos')"
                                                [disabled]="(!filterObjetoGeneral('vehiculos') || !filterObjetoGeneral('vehiculos')?.busqueda)">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Empresa en un día -->
                    <div class="col-md-3 col-lg-2 mb-4">
                        <div class="card shadow-lg border-0"  [ngClass]="{'disabled': !empresaDiahasUserAccess}">
                            <div class="card-body">
                                <div class="top-card">
                                    <div class="d-flex pb-2 justify-content-center align-items-center">
                                        <div class="flex-fill">
                                            <span class="fa-stack fa-lg">
                                                <i class="fa fa-industry sidebar-nav-icon"></i>
                                            </span>
                                        </div>
                                        <div class="pt-2 mt-1 flex-fill text-right">

                                            <ng-container *ngIf="!showReporteGeneral && !filterObjetoGeneral('empresaDia')?.error">
                                                <label class="switch switch-primary">
                                                    <input #checkboxes type="checkbox" [(ngModel)]="switchReporteEmpresaDia"
                                                        id="reporteEmpresaDia"
                                                        (click)="getReporteEmpresaDia($event)">
                                                    <span></span>
                                                </label>
                                            </ng-container>

                                            <ng-container *ngIf="!showReporteGeneral && filterObjetoGeneral('empresaDia')?.error">

                                                <i class="fa fa-close fa-2x text-danger"
                                                    [popover]="filterObjetoGeneral('empresaDia').error"
                                                    popoverTitle="Información" placement="bottom"
                                                    (click)="quitarReporteEmpresaDia()"
                                                    triggers="mouseenter:mouseleave"></i>

                                            </ng-container>
                                            
                                            <ng-container
                                                *ngIf="showReporteGeneral && !filterObjetoGeneral('empresaDia')?.error && filterObjetoGeneral('empresaDia')?.obj">
                                                <i class="fa fa-check-circle fa-2x text-success"></i>
                                            </ng-container>
                                            
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 flex-column">
                                        <h4 class="font-weight-bold pt-2">Empresa en un día</h4>
                                        <h5>
                                            <span class="text-muted">
                                                <ng-container *ngIf="objReporteEmpresaDia">
                                                    {{formatDate(objReporteEmpresaDia?.EmpresaEnUnDiaReporte?.DatosBasicosSolicitud?.FechaReporte
                                                    || '')
                                                    | date: 'dd-MM-yyyy'}}
                                                </ng-container>

                                                <ng-template #problemaEmpresaDia>
                                                    <div class="text-heart">{{filterObjetoGeneral('empresaDia')?.msg}}
                                                    </div>
                                                </ng-template>

                                                <ng-container *ngIf="!objReporteEmpresaDia">
                                                    <ng-container
                                                        *ngIf="!filterObjetoGeneral('empresaDia')?.msg; else problemaEmpresaDia">
                                                        ---------
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="d-flex footer-card mt-3 border-top justify-content-center align-items-center"
                                    *ngIf="!showReporteGeneral && !filterObjetoGeneral('empresaDia')?.error">
                                    <div class="flex-fill">
                                        <span class="fa-stack bg-white fa-lg border-danger border"
                                            [popover]="popTemplateUnDia" popoverTitle="Información" placement="bottom"
                                            triggers="mouseenter:mouseleave">
                                            <i class="fa fa-info sidebar-nav-icon text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 pt-5" *ngIf="!showReporteGeneral">
                        <div class="text-center">
                            <button type="button" class="btn btn-sm btn-warning" (click)="callServices()"
                                [disabled]="groupNameConsulting.length === 0" *ngIf="groupNameConsulting.length > 0">
                                Buscar Reportes Vigentes
                            </button>
                            <button type="button" class="btn btn-sm btn-warning" (click)="submitReporte()" [disabled]="objGeneralReport.length === 0 || !validacionExtra() || 
                                groupNameConsulting.length > 0 || validaErroresAct()" *ngIf="groupNameConsulting.length === 0">
                                Generar reporte
                            </button>
                        </div>
                    </div>
                </div>
            </accordion-group>
            <accordion-group heading="Reportes generado" *ngIf="showReporteGeneral" [isOpen]="true"
                panelClass="disabled-custom">
            </accordion-group>
        </accordion>
        <div id="print-section">
            <ng-container *ngIf="showReporteGeneral">
                <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
                <h3 class="text-center pt-3"><span class="badge bg-secondary text-center">
                        {{rut | rutFormat | uppercase}}
                    </span></h3>
                <h2 class="text-center pb-1">REPORTE CONSOLIDADO</h2>
                <h4 class="text-center font-weight-bold pb-2">{{fechaReporte}}</h4>
            </ng-container>

            <app-reporte-consolidado-view #reporteConsolidadoView [rut]="rut"
                (updateReporteGeneral)="updateReporteGeneral($event)" [informeComercial]="radioReporteBasico" (showReporteGeneral)="showReporteGeneral = $event;">
            </app-reporte-consolidado-view>
        </div>
    </div>

    
<ng-template #popTemplateFinanciero>** Debe cargar una carpeta para realizar la actualización</ng-template>
<ng-template #popTemplateUnDia>** Para obtener un nuevo reporte ir al módulo empresa en un día</ng-template>



<!-- Modal Carpeta Tributaria -->
<app-modal-consultar-carpeta-tributaria [rut]="rut" [alertSuccess]="false" [alertError]="false" (reporteFullContent)="setResponseModal('reporteFinanciero', $event)" (service)="setError($event)"></app-modal-consultar-carpeta-tributaria>