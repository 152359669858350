
<div class="panel panel-default border-0 no-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="row p-4">

    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    I. Resumen saldo cuentas por cobrar
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="bg-blue text-center">RUT</th>
                                            <th class="bg-blue text-center">CLIENTES</th>
                                            <th class="bg-blue text-center">LC CMT M$</th>
                                            <th class="bg-blue text-center">VENCIDO M$</th>
                                            <th class="bg-blue text-center">POR VENCER M$</th>
                                            <th class="bg-blue text-center">TOTAL FACT. M$</th>
                                            <th class="bg-blue text-center">SALDO PEND. FACT. M$</th>
                                            <th class="bg-blue text-center">ALDO DOC. BLOQUEADOS M$</th>
                                            <th class="bg-blue text-center">TOTAL OCUP. M$</th>
                                            <th class="bg-blue text-center">LC CIA. DE SEGURO UF</th>
                                            <th class="bg-blue text-center">LC CIA. DE SEGURO M$</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="validateArray(tablaResumenSaldo?.body); else elseResumen">
                                            <ng-container *ngFor="let item of tablaResumenSaldo?.body">
                                                <tr>
                                                    <td class="text-center">{{item.rut ? (item.rut | rutFormat) : ''}}</td>
                                                    <td class="text-center">{{item.clientes || ''}}</td>
                                                    <td class="text-center">{{(item.lcCmt || item.lcCmt == 0) ? (item.lcCmt | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.vencido || item.vencido == 0) ? (item.vencido | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.porVencer || item.porVencer == 0) ? (item.porVencer | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.totalFact || item.totalFact == 0) ? (item.totalFact | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.saldoPendFact || item.saldoPendFact == 0) ? (item.saldoPendFact | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.saldoDocBloq || item.saldoDocBloq == 0) ? (item.saldoDocBloq | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.totalOcup || item.totalOcup == 0) ? (item.totalOcup | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.lcCiaDeSeguroUf || item.lcCiaDeSeguroUf == 0) ? (item.lcCiaDeSeguroUf | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item.lcCiaDeSeguroM || item.lcCiaDeSeguroM == 0) ? (item.lcCiaDeSeguroM | number: '1.0-2') : ''}}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elseResumen>
                                            <tr>
                                                <td colspan="11">Sin información</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <td class="bg-gris" colspan="2">
                                                TOTAL
                                            </td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.lcCmt || tablaResumenSaldo?.totales?.lcCmt == 0) ? (tablaResumenSaldo?.totales?.lcCmt | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.vencido || tablaResumenSaldo?.totales?.vencido == 0) ? (tablaResumenSaldo?.totales?.vencido | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.porVencer || tablaResumenSaldo?.totales?.porVencer == 0) ? (tablaResumenSaldo?.totales?.porVencer | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.totalFact || tablaResumenSaldo?.totales?.totalFact == 0) ? (tablaResumenSaldo?.totales?.totalFact | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.saldoPendFact || tablaResumenSaldo?.totales?.saldoPendFact == 0) ? (tablaResumenSaldo?.totales?.saldoPendFact | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.saldoDocBloq || tablaResumenSaldo?.totales?.saldoDocBloq == 0) ? (tablaResumenSaldo?.totales?.saldoDocBloq | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.totalOcup || tablaResumenSaldo?.totales?.totalOcup == 0) ? (tablaResumenSaldo?.totales?.totalOcup | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.lcCiaDeSeguroUf || tablaResumenSaldo?.totales?.lcCiaDeSeguroUf == 0) ? (tablaResumenSaldo?.totales?.lcCiaDeSeguroUf | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(tablaResumenSaldo?.totales?.lcCiaDeSeguroM || tablaResumenSaldo?.totales?.lcCiaDeSeguroM == 0) ? (tablaResumenSaldo?.totales?.lcCiaDeSeguroM | number: '1.0-2') : ''}}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Caratula puntual.-->
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    II. Caratula puntual
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12 mb-3 pl-5">
                            <div class="row">
                                <div class="col-sm-12 font-size">
                                    <strong>Rut: </strong>{{rut ? (rut | rutFormat) : ''}}
                                </div>
                                <div class="col-sm-12 font-size">
                                    <strong>Razon Social: </strong>{{razonSocial || ''}}
                                </div>
                            </div>
                        </div>
        
                        <div class="col-sm-12">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <ng-container *ngFor="let item of caratulaPuntual; let i = index">
                                        <thead>
                                            <tr>
                                                <ng-container *ngFor="let element of item.body">
                                                    <th class="bg-blue text-center">
                                                        <div class="col-12">
                                                            {{element.text || ''}}
                                                        </div>
                                                        <div class="col-12" *ngIf="element.moneda">
                                                            {{element.moneda || ''}}
                                                        </div>
                                                    </th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="item.listaValores && item.listaValores.length > 0; else elseValores">
                                                <ng-container *ngFor="let element of item.listaValores; let indiceValores = index">
                                                    <tr [ngClass]="{'pointer-cursor': item.modal}" (click)="abrirModalTitular(item.modal)">
                                                        
                                                        <ng-container *ngFor="let itemElement of element; let j = index">
            
                                                            <td [ngClass]="{'text-center': !(validaTipoFormulario(i, j) && stepper === 4 && plantillaNoEditable === false)}">
                                                                <ng-container *ngIf="validaTipoFormulario(i, j) != '' && stepper === 4 && plantillaNoEditable === false; else elseFormulario">
                                                                    <input type="{{validaTipoFormulario(i, j)}}" 
                                                                        class="form-control" 
                                                                        [ngClass]="{'error-input': validaListaIds('caratulaPuntual'+i+indiceValores+j)}" 
                                                                        title="{{validaTipoFormulario(i, j) === 'date' ? 'Ingrese una fecha' : 'Ingrese un numero entero o decimal'}}" 
                                                                        id='caratulaPuntual{{i}}{{indiceValores}}{{j}}'
                                                                        name='caratulaPuntual{{i}}{{indiceValores}}{{j}}' 
                                                                        [(ngModel)]="caratulaPuntualInput[validaTipoFormulario(i, j)][obtenerIndiceString(i,indiceValores,j)]"
                                                                        (input)="calculosById(i, indiceValores, j)"
                                                                    >
                                                                </ng-container>
                                                                <ng-template #elseFormulario>
            
                                                                    <ng-container *ngIf="validaDate(caratulaPuntual[i]?.body[j]?.text); else elseDate1">
                                                                        {{itemElement ? (itemElement | date: 'dd-MM-yyyy') : ''}}
                                                                    </ng-container>
                                                                    <ng-template #elseDate1>
            
                                                                        <ng-container *ngIf="validaText(caratulaPuntual[i]?.body[j]?.text); else elseText1">
                                                                            {{itemElement || ''}}
                                                                        </ng-container>
                                                                        <ng-template #elseText1>
                                                                            {{(itemElement || itemElement == 0) ? (itemElement | number: '1.0-2') : ''}}
                                                                        </ng-template>
                                                                        
                                                                    </ng-template>
                                                                    
                                                                </ng-template>
                                                            </td>
            
                                                        </ng-container>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseValores>
                                                <tr>
                                                    <td colspan="11"></td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </ng-container>
                                </table>
                            </div>
                        </div>
        
                        <ng-container *ngFor="let itemCaratula of caratulaPuntualPart2; let x = index">
                            <div class="col-sm-12 mb-3 pl-5">
                                <div class="row">
                                    <div class="col-sm-12 font-size">
                                        <strong>Rut Garante: </strong>{{rutGarante[x] ? (rutGarante[x] | rutFormat) : ''}}
                                    </div>
                                    <div class="col-sm-12 font-size">
                                        <strong>Razon Social Garante: </strong>{{razonSocialGarante[x] || ''}}
                                    </div>
                                </div>
                            </div>
            
                            <div class="col-sm-12">
                                <div class="table-responsive table-entidad">
                                    <table class="table table-hover table-vcenter table-condensed table-bordered">
                                        <ng-container *ngFor="let item of itemCaratula; let i = index">
                                            <thead>
                                                <tr>
                                                    <ng-container *ngFor="let element of item.body">
                                                        <th class="bg-blue text-center">
                                                            <div class="col-12">
                                                                {{element.text || ''}}
                                                            </div>
                                                            <div class="col-12">
                                                                {{element.moneda || ''}}
                                                            </div>
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let element of item.listaValores">
                                                    <tr>
                                                        <ng-container *ngFor="let itemElement of element; let j = index">
        
                                                            <ng-container *ngIf="validaDate(caratulaPuntualPart2[x][i]?.body[j]?.text); else elseDate2">
                                                                <td class="text-center">{{itemElement ? (itemElement | date: 'dd-MM-yyyy') : ''}}</td>
                                                            </ng-container>
                                                            <ng-template #elseDate2>
            
                                                                <ng-container *ngIf="validaText(caratulaPuntualPart2[x][i]?.body[j]?.text); else elseText2">
                                                                    <td class="text-center">{{itemElement || ''}}</td>
                                                                </ng-container>
                                                                <ng-template #elseText2>
                                                                    <td class="text-center">{{(itemElement || itemElement == 0) ? (itemElement | number: '1.0-2') : ''}}</td>
                                                                </ng-template>
            
                                                            </ng-template>
        
                                                        </ng-container>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </ng-container>
                                    </table>
                                </div>
                            </div>
        
                        </ng-container>
                        
        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Materiales y márgenes:-->
    <div class="col-12">
        <div class="row pagebreak">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    III. Materiales y márgenes
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12 no-print">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="bg-blue text-center" *ngIf="stepper === 4 && plantillaNoEditable === false">
                                                <button type="button" class="btn btn-success btn-mas" (click)="addRowCargaFinanciera()" title="Agregar Fila">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </th>
                                            <th class="bg-blue text-center">PEDIDO/COTIZACION/OC</th>
                                            <th class="bg-blue text-center">DISTRIBUCIÓN</th>
                                            <th class="bg-blue text-center">PRODUCTO</th>
                                            <th class="bg-blue text-center">PROVEEDOR</th>
                                            <th class="bg-blue text-center">MONTO M$</th>
                                            <th class="bg-blue text-center">MG%</th>
                                            <th class="bg-blue text-center">CONTRIBUCIÓN M$</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <ng-container *ngIf="validateArray(materialesMargenes?.body); else elseMateriales">
                                            <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false; else elseMaterialesNoEditable">
                                                <tr *ngFor="let item of materialesMargenes.body | paginate:{itemsPerPage: currentItem, currentPage: currentPage}; let i = index">
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-danger btn-mas" (click)="deleteRowCargaFinanciera(i)" title="Quitar Fila">
                                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-pedidoCotizacionOc'+absoluteIndex(i))}" 
                                                            id='materiales-pedidoCotizacionOc{{absoluteIndex(i)}}' 
                                                            name='materiales-pedidoCotizacionOc{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].pedidoCotizacionOc"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-distribucion'+absoluteIndex(i))}" 
                                                            id='materiales-distribucion{{absoluteIndex(i)}}' 
                                                            name='materiales-distribucion{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].distribucion"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-producto'+absoluteIndex(i))}" 
                                                            id='materiales-producto{{absoluteIndex(i)}}' 
                                                            name='materiales-producto{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].producto"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-proveedor'+absoluteIndex(i))}" 
                                                            id='materiales-proveedor{{absoluteIndex(i)}}' 
                                                            name='materiales-proveedor{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].proveedor"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="number" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-montoM'+absoluteIndex(i))}" 
                                                            title="Ingrese un numero entero o decimal" 
                                                            id='materiales-montoM{{absoluteIndex(i)}}' 
                                                            name='materiales-montoM{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].montoM"
                                                            (input)="calcularTotal('montoM')"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="number" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-mgPorc'+absoluteIndex(i))}" 
                                                            title="Ingrese un numero entero o decimal" 
                                                            id='materiales-mgPorc{{absoluteIndex(i)}}' 
                                                            name='materiales-mgPorc{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].mgPorc"
                                                            (input)="calcularTotal('mgPorc')"
                                                        >
                                                    </td>
                                                    <td>
                                                        <input type="number" 
                                                            class="form-control" 
                                                            [ngClass]="{'error-input': validaListaIds('materiales-contribucionM'+absoluteIndex(i))}" 
                                                            title="Ingrese un numero entero o decimal" 
                                                            id='materiales-contribucionM{{absoluteIndex(i)}}' 
                                                            name='materiales-contribucionM{{absoluteIndex(i)}}' 
                                                            [(ngModel)]="materialesMargenes.body[absoluteIndex(i)].contribucionM"
                                                            (input)="calcularTotal('contribucionM')"
                                                        >
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #elseMaterialesNoEditable>
                                                <tr *ngFor="let item of materialesMargenes.body | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                                    <td class="text-center">{{item?.pedidoCotizacionOc || ''}}</td>
                                                    <td class="text-center">{{item?.distribucion || ''}}</td>
                                                    <td class="text-center">{{item?.producto || ''}}</td>
                                                    <td class="text-center">{{item?.proveedor || ''}}</td>
                                                    <td class="text-center">{{(item?.montoM || item?.montoM == 0) ? (item?.montoM | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item?.mgPorc || item?.mgPorc == 0) ? (item?.mgPorc | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item?.contribucionM || item?.contribucionM == 0) ? (item?.contribucionM | number: '1.0-2') : ''}}</td>
                                                </tr>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseMateriales>
                                            <tr>
                                                <td colspan="7">Sin información</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <td class="border-bottom-0 border-left-0 border-right-0" *ngIf="stepper === 4 && plantillaNoEditable === false"></td>
                                            <td class="border-bottom-0 border-left-0 border-right-0"></td>
                                            <td class="border-bottom-0 border-left-0 border-right-0"></td>
                                            <td class="border-bottom-0 border-left-0"></td>
                                            <td class="bg-gris text-center">
                                                VENTA TOTAL
                                            </td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.montoM || materialesMargenes?.totales?.montoM == 0) ? (materialesMargenes?.totales?.montoM | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.mgPorc || materialesMargenes?.totales?.mgPorc == 0) ? (materialesMargenes?.totales?.mgPorc | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.contribucionM || materialesMargenes?.totales?.contribucionM == 0) ? (materialesMargenes?.totales?.contribucionM | number: '1.0-2') : ''}}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 d-none">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="bg-blue text-center">PEDIDO/COTIZACION/OC</th>
                                            <th class="bg-blue text-center">DISTRIBUCIÓN</th>
                                            <th class="bg-blue text-center">PRODUCTO</th>
                                            <th class="bg-blue text-center">PROVEEDOR</th>
                                            <th class="bg-blue text-center">MONTO M$</th>
                                            <th class="bg-blue text-center">MG%</th>
                                            <th class="bg-blue text-center">CONTRIBUCIÓN M$</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <ng-container *ngIf="validateArray(materialesMargenes?.body); else elseMateriales">
                                            <tr *ngFor="let item of materialesMargenes.body">
                                                <td class="text-center">{{item?.pedidoCotizacionOc || ''}}</td>
                                                <td class="text-center">{{item?.distribucion || ''}}</td>
                                                <td class="text-center">{{item?.producto || ''}}</td>
                                                <td class="text-center">{{item?.proveedor || ''}}</td>
                                                <td class="text-center">{{(item?.montoM || item?.montoM == 0) ? (item?.montoM | number: '1.0-2') : ''}}</td>
                                                <td class="text-center">{{(item?.mgPorc || item?.mgPorc == 0) ? (item?.mgPorc | number: '1.0-2') : ''}}</td>
                                                <td class="text-center">{{(item?.contribucionM || item?.contribucionM == 0) ? (item?.contribucionM | number: '1.0-2') : ''}}</td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #elseMateriales>
                                            <tr>
                                                <td colspan="7">Sin información</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <td class="border-bottom-0 border-left-0 border-right-0"></td>
                                            <td class="border-bottom-0 border-left-0 border-right-0"></td>
                                            <td class="border-bottom-0 border-left-0"></td>
                                            <td class="bg-gris text-center">
                                                VENTA TOTAL
                                            </td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.montoM || materialesMargenes?.totales?.montoM == 0) ? (materialesMargenes?.totales?.montoM | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.mgPorc || materialesMargenes?.totales?.mgPorc == 0) ? (materialesMargenes?.totales?.mgPorc | number: '1.0-2') : ''}}</td>
                                            <td class="bg-gris text-center">{{(materialesMargenes?.totales?.contribucionM || materialesMargenes?.totales?.contribucionM == 0) ? (materialesMargenes?.totales?.contribucionM | number: '1.0-2') : ''}}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 no-print">
                            <div id="pagination">
                                <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                    <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Comentarios:-->
    <div class="col-12 margin-top-print">
        <div class="row pagebreak-after">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    IV. Comentarios
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
        
                        <ng-container *ngFor="let comentario of comentarios; let i = index">
                            <div class="col-sm-12 mb-3">
                                <div class="form-group">
                                    <label class="pl-3" [attr.for]="'comentarios-'+comentario?.id">{{comentario.title}}</label>
                                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false; else elseComentario">
                                        <textarea class="form-control" [id]="'comentarios-'+comentario?.id" [attr.name]="'comentarios-'+comentario?.id" rows="5" maxlength="2000" [(ngModel)]="comentarios[i].comentario"></textarea>
                                    </ng-container>
                                    <ng-template #elseComentario>
                                        <div class="col-12 border rounded p-4 my-3" [innerHTML]="agregarSaltosDeLinea(comentarios[i].comentario)"></div>
                                        
                                    </ng-template>
                                    <div class="col-12" *ngIf="comentarios[i]?.autor">
                                        <p class="font-weight-light font-italic">{{comentarios[i]?.autor || ''}} - {{comentarios[i]?.fecha || ''}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Resolución seguro-->
    <div class="col-12">
        <div class="row margin-top-print">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    V. Resolución seguro
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12 mb-3 pl-5">
                            <div class="row">
                                <div class="col-sm-12 font-size">
                                    <strong>Rut: </strong>{{rutResolucion ? (rutResolucion | rutFormat) : ''}}
                                </div>
                                <div class="col-sm-12 font-size">
                                    <strong>Razon Social: </strong>{{razonSocialResolucion || ''}}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="bg-blue text-center">COMPAÑIA DE SEGURO</th>
                                            <th class="bg-blue text-center">ID. NACIONAL</th>
                                            <th class="bg-blue text-center">CLIENTE</th>
                                            <th class="bg-blue text-center">VIGENCIA DESDE</th>
                                            <th class="bg-blue text-center">VIGENCIA HASTA</th>
                                            <th class="bg-blue text-center">FECHA RESOLUCION</th>
                                            <th class="bg-blue text-center">MONTO SOLICITADO</th>
                                            <th class="bg-blue text-center">MONTO APROBADO</th>
                                            <th class="bg-blue text-center">ESTADO</th>
                                            <th class="bg-blue text-center">CONDICION DE VENTA</th>
                                            <th class="bg-blue text-center">OBSERVACION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="validateArray(resolucionSeguro); else elseResolucion">
                                            <ng-container *ngFor="let item of resolucionSeguro">
                                                <tr>
                                                    <td class="text-center">{{item?.companiaSeguro || ''}}</td>
                                                    <td class="text-center">{{item?.idNacional ? (item.idNacional | rutFormat) : ''}}</td>
                                                    <td class="text-center">{{item?.cliente || ''}}</td>
                                                    <td class="text-center">{{item?.vigenciaDesde ? (item.vigenciaDesde | date: 'dd-MM-yyyy') : ''}}</td>
                                                    <td class="text-center">{{item?.vigenciaHasta ? (item.vigenciaHasta | date: 'dd-MM-yyyy') : ''}}</td>
                                                    <td class="text-center">{{item?.fechaResolucion ? (item.fechaResolucion | date: 'dd-MM-yyyy') : ''}}</td>
                                                    <td class="text-center">{{(item?.montoSolicitado || item?.montoSolicitado == 0) ? (item?.montoSolicitado | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{(item?.montoAprobado || item?.montoAprobado == 0) ? (item?.montoAprobado | number: '1.0-2') : ''}}</td>
                                                    <td class="text-center">{{item?.estado || ''}}</td>
                                                    <td class="text-center">{{item?.condicionVenta || ''}}</td>
                                                    <td class="text-center">{{item?.observacion || ''}}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elseResolucion>
                                            <tr>
                                                <td colspan="11">Sin información</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Volumen de negocio.-->
    <div class="col-12">
        <div class="row pagebreak">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    VI. Volumen de negocio
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12 mb-3 pl-5">
                            <div class="row">
                                <div class="col-sm-12 font-size">
                                    <strong>Rut: </strong>{{rutVolumenNegocio ? (rutVolumenNegocio | rutFormat) : ''}}
                                </div>
                                <div class="col-sm-12 font-size">
                                    <strong>Razon Social: </strong>{{razonSocialVolumenNegocio || ''}}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="table-responsive table-entidad">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="bg-blue text-center" rowspan="2">MESES</th>
                                            <th class="bg-blue text-center" colspan="3">VENTA BRUTA M$</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="validateArray(volumenNegocio?.anios); else elseAnioVolumen">
                                                <ng-container *ngFor="let item of volumenNegocio?.anios">
                                                    <th class="bg-blue text-center">{{item}}</th>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseAnioVolumen>
                                                <th class="bg-blue text-center"></th>
                                            </ng-template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of volumenNegocio?.meses">
                                            <tr>
                                                <td class="text-center">{{item.mes}}</td>
                                                <ng-container *ngIf="validateArray(item?.montos); else elseMontosVolumen">
                                                    <ng-container *ngFor="let item of item?.montos">
                                                        <td class="text-center">{{(item || item == 0) ? (item | number: '1.0-2') : ''}}</td>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseMontosVolumen>
                                                    <td class="text-center"></td>
                                                </ng-template>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th class="bg-gris text-center">TOTAL</th>
                                            <ng-container *ngIf="validateArray(volumenNegocio?.totales); else elseTotalVolumen">
                                                <ng-container *ngFor="let item of volumenNegocio?.totales">
                                                    <th class="bg-gris text-center">{{(item || item == 0) ? (item | number: '1.0-2') : ''}}</th>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseTotalVolumen>
                                                <th class="bg-gris text-center"></th>
                                            </ng-template>
                                        </tr>
                                        <tr>
                                            <th class="bg-gris text-center">PROMEDIO</th>
                                            <ng-container *ngIf="validateArray(volumenNegocio?.promedios); else elsePromedioVolumen">
                                                <ng-container *ngFor="let item of volumenNegocio?.promedios">
                                                    <th class="bg-gris text-center">{{(item || item == 0) ? (item | number: '1.0-2') : ''}}</th>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elsePromedioVolumen>
                                                <th class="bg-gris text-center"></th>
                                            </ng-template>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Análisis declaración de IVA-->
    <div class="col-12">
        <div class="row pagebreak">
            <div class="col-12">
                <h3 class="border-0 shadow p-3 my-4 rounded-header bg-white">
                    VII. Análisis declaración de IVA
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="block full min-height-block">
                    <div class="row">
                        <div class="col-sm-12 mb-3 pl-5">
                            <div class="row">
                                <div class="col-sm-12 font-size">
                                    <strong>Rut: </strong>{{rutAnalisisDeclaracionIva ? (rutAnalisisDeclaracionIva | rutFormat) : ''}}
                                </div>
                                <div class="col-sm-12 font-size">
                                    <strong>Razon Social: </strong>{{razonSocialAnalisisDeclaracionIva || ''}}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="table-responsive table-entidad" *ngIf="showReportes && totalesInformacionFinanciera && informacionFinancieraTable.length > 0">
                                <table class="table table-hover table-vcenter table-condensed table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="bg-blue text-center" rowspan="3">MESES</th>
                                        <th scope="col" class="bg-blue text-center" colspan="6">ANALISIS DECLARACION DE IVA</th>
                                    </tr>
                                    <tr>
                                        <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                                        <th scope="col" class="bg-blue text-center" colspan="3">{{totalesInformacionFinanciera.totalAnterior.anio}}</th>
                                        </ng-container>
                                        <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                                        <th scope="col" class="bg-blue text-center" colspan="3">{{totalesInformacionFinanciera.totalActual.anio}}</th>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                                        <th scope="col" class="bg-blue text-center">VENTAS M$</th>
                                        <th scope="col" class="bg-blue text-center">COMPRAS M$</th>
                                        <th scope="col" class="bg-blue text-center">MARGEN M$</th>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                                        <th scope="col" class="bg-blue text-center">VENTAS M$</th>
                                        <th scope="col" class="bg-blue text-center">COMPRAS M$</th>
                                        <th scope="col" class="bg-blue text-center">MARGEN M$</th>               
                                        </ng-container>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ng-container *ngFor="let item of informacionFinancieraTable; let i = index">
                                        <tr>
                                        <td class="text-center text-uppercase">{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                                        <td class="text-center">{{item?.anterior?.ventas | number: '1.0-2'}}</td>
                                        <td class="text-center">{{item?.anterior?.compras | number: '1.0-2'}}</td>
                                        <td class="text-center">{{item?.anterior?.diferencia | number: '1.0-2'}}</td>
                                        <td class="text-center">{{item?.actual?.ventas | number: '1.0-2'}}</td>
                                        <td class="text-center">{{item?.actual?.compras | number: '1.0-2'}}</td>
                                        <td class="text-center">{{item?.actual?.diferencia | number: '1.0-2'}}</td>
                                        </tr>              
                                    </ng-container>
                                    <tr>
                                        <th class="bg-gris text-center">TOTAL</th>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.dif | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.dif | number: '1.0-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-gris text-center">PROMEDIO</th>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.dif | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.compras | number: '1.0-2'}}</td>
                                        <td class="bg-gris text-center">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.dif | number: '1.0-2'}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 my-2 no-print" *ngIf="showArchivos">
        <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
    </div>

    <ng-container *ngIf="plantillaNoEditable === false">
        <ng-template #botonFinalizar>
            <ng-container *ngIf="validaUsuarioAdmin()">
                <div class="col-12 d-flex justify-content-center pt-4 no-print">
                <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
                    Devolver Etapa Previa
                </button>
                <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
                    Continuar
                </button>
                </div>
            </ng-container>
        </ng-template>
        
        <ng-container *ngIf="stepper === 4 else botonFinalizar">
        
            <div class="col-12 d-flex justify-content-center pt-4 no-print">
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
                Guardar
            </button>
            <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
                Continuar
            </button>
            </div>
        
        </ng-container>
    </ng-container>

</div>

<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
