<div class="container-gtp mb-5">
    <div class="break-box">
        <div class="row"> 
            <ng-container *ngIf="hasUserAccess; else elseAccess">
                <div class="w-100">
    
                    <!-- Lista Ruts -->
    
                    <ng-container *ngIf="showRuts && !showList && !showReporte && !showListaBalances && !showFormBalances">
    
                        <div class="col-sm-12">
                    
                            <div class="card w-100 mx-auto border-0 rounded m-5">
    
                                <div class="card-body p-5">
                                    
                                    <div class="row">
    
                                        <div class="col-sm-12">
                                            <div class="row container__back-button">
                                                <button (click)="cerrarBalanceManual()" class="btn btn-primary formulario ml-4 mr-auto">Volver</button>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-12 col-md-8 mx-auto my-3">
                                            <button class="btn btn-warning float-right" *ngIf="tieneControles() && !cpListaRutsForm.disabled" (click)="bloquearForm()">Bloquear Formulario</button>
                                            <button class="btn btn-warning float-right" *ngIf="tieneControles() && cpListaRutsForm.disabled" (click)="bloquearForm()">Desbloquear Formulario</button>
                                        </div>
        
                                        <div class="col-sm-12 col-md-8 mx-auto">
    
                                            <form [formGroup]="cpListaRutsForm">
    
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover border my-0">
                                                        <tbody>
                                                            <tr>
                                                                <th class="font-size align-middle text-nowrap text-center" width="40%">
                                                                    Rut
                                                                </th>
                                                                <td class="font-size align-middle">
                                                                    {{rut || '' | rutFormat}}
                                                                </td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th class="font-size align-middle text-nowrap text-center" width="40%">
                                                                    Razon Social
                                                                </th>
                                                                <td class="font-size align-middle">
                                                                    {{razonSocialPrincipal || ''}}
                                                                </td>
                                                                
                                                            </tr>                                                            
    
                                                                <ng-container *ngFor="let campo of camposPersonalizados">
    
                                                                    <ng-container *ngIf="campo.enabled == true && campo.nameAttribute !== 'Razon Social'">
                                                                        
                                                                        <tr>
                                                                            <th class="font-size align-middle text-nowrap text-center" width="40%">
                                                                                {{ campo.nameAttribute || '' }}
                                                                            </th>
                                                                            <td class="font-size align-middle">
                                                                                <ng-container *ngIf="!campo?.allowedValues || objectKeys(campo?.allowedValues).length === 0">
                                                                                    <input [attr.type]="campo.typeAttibute" class="form-control"
                                                                                    [formControlName]="campo.nameAttribute">
                                                                                    <div class="w-100 mb-2">
                                                                                        <app-error [control]="cpListaRutsForm.get(campo.nameAttribute)" 
                                                                                        [validarValid]="campo.typeAttibute === 'Number' ? true : false"
                                                                                        [formato]="campo.formatAttribute"
                                                                                        ></app-error>
                                                                                    </div>
                                                                                </ng-container>
                                                                                
                                                                                <ng-container *ngIf="campo?.allowedValues && objectKeys(campo?.allowedValues).length > 0">
    
                                                                                    <select class="form-control text-capitalize"
                                                                                    [formControlName]="campo.nameAttribute">
                                                                                        <option 
                                                                                        value="">Seleccione...</option>
                                                                                        <ng-container *ngFor="let item of objectKeys(campo.allowedValues); let i = index">
                                                                                            <option class="text-capitalize"
                                                                                                [value]="item">
                                                                                                {{campo.allowedValues[item]}}
                                                                                            </option>
                                                                                        </ng-container>
                                                                                    </select>
                                                                                    <div class="w-100">
                                                                                        <app-error [control]="cpListaRutsForm.get(campo.nameAttribute)"></app-error>
                                                                                    </div>
    
                                                                                </ng-container>
                                                                            </td>
                                                                        </tr>
    
                                                                    </ng-container>
                                                                    
                                                                </ng-container>
    
                                                        </tbody>
                                                    </table>
                                                </div>
    
                                            </form>
                                        </div>
    
                                        <div class="col-sm-12 my-1 text-center mt-5">
                                            <button class="btn btn-primary mx-2" (click)="continuarPrincipal()" [disabled]="disabledButtonPrincipal">Continuar Rut Principal</button>       
                                            <button class="btn btn-info mx-2" *ngIf="!validaCambioGrupo()" (click)="obtenerListaRutsAsociados()">Obtener Ruts Asociados</button>
                                            <button class="btn btn-success mx-2" *ngIf="tieneControles() && !cpListaRutsForm.disabled" (click)="asignarGrupo()">Guardar</button>
                                        </div>
    
                                    </div>
    
                                </div>
    
                                <div class="card-body pb-5 pr-5 pl-5 pt-3" *ngIf="(!tieneControles() || !validaCambioGrupo() || validaCamposUndefined()) && rutsConsultados">
    
                                    <div class="row border">
        
                                        <div class="col-sm-12 my-5">
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover border my-0">
                                                    <thead>
                                                        <tr class="bg-nivel1">
                                                            <th scope="col" colspan="100&" class="text-center align-middle font-size">
                                                                Seleccionar Rut
                                                            </th>
                                                        </tr>
                                                        <tr class="bg-nivel2">
                                                            <th scope="col" class="text-center align-middle font-size">
                                                                Rut
                                                            </th>
                                                            <th scope="col" class="text-center align-middle font-size">
                                                                Razon Social
                                                            </th>
                                                            <th scope="col" class="text-center align-middle font-size">
                                                                Relación
                                                            </th>
                                                            <th scope="col" class="text-center align-middle font-size"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of listaRuts | paginate:{id: 'pagination-rut', itemsPerPage: 5, currentPage: currentPageRuts}">
                                                            <td class="font-size align-middle text-nowrap text-center">
                                                                {{item.rut || '' | rutFormat }}
                                                            </td>
                                                            <td class="font-size align-middle">
                                                                {{item.nombre || ''}}
                                                            </td>
                                                            <td class="font-size align-middle">
                                                                {{item.relacion || ''}}
                                                            </td>

                                                            <ng-container *ngIf="validaRutPrincipal(item.rut); else rutNoPrincipal">
                                                                <td class="font-size align-middle text-nowrap text-center">
                                                                    <label class="switch switch-primary switch-sm ml-3">
                                                                        <input type="checkbox" id="rut-principal" checked disabled>
                                                                        <span></span>
                                                                    </label>
                                                                </td>
                                                            </ng-container>
                                                            <ng-template #rutNoPrincipal>
                                                                <td class="font-size align-middle text-nowrap text-center">
                                                                    <label class="switch switch-primary switch-sm ml-3">
                                                                        <input type="checkbox" [id]="'rut-' + item.rut" (click)="seleccionarRut($event, item.rut)" [checked]="validarRutChecked(item.rut)">
                                                                        <span></span>
                                                                    </label>
                                                                </td>
                                                            </ng-template>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="pagination">
                                                <div class="col-sm-12 mt-3">
                                                    <pagination-controls id="pagination-rut" (pageChange)="currentPageRuts=$event"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 col-md-5 my-5">
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover border my-0">
                                                    <thead>
                                                        <tr class="bg-nivel1">
                                                            <th scope="col" colspan="2" class="text-center align-middle font-size">
                                                                Seleccionar Periodo
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="periodos.length > 0">
                                                            <tr *ngFor="let item of periodos | paginate:{id: 'pagination-periodo',itemsPerPage: 3, currentPage: currentPagePeriodos}" class="text-center">
                        
                                                                <td class="font-size align-middle text-nowrap">
                                                                    {{ item }}
                                                                </td>
                        
                                                                <td class="font-size align-middle text-nowrap">
                                                                    <label class="switch switch-primary switch-sm ml-3">
                                                                        <input type="checkbox" id="validar" (click)="agregarPeriodo($event, item)" [checked]="validarPeriodo(item)">
                                                                        <span></span>
                                                                    </label>
                                                                </td>
                        
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="pagination">
                                                <div class="col-sm-12 mt-3">
                                                    <pagination-controls id="pagination-periodo" (pageChange)="currentPagePeriodos=$event"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-12 my-5">
                                            <div class="row container__back-button">
                                                <button class="btn btn-primary mx-auto" (click)="seleccionarRutPeriodo()" *ngIf="validaPermisoBuscar()">Continuar</button>
                                            </div>
                                        </div>
    
                                    </div>
    
                                </div>
                                
                            </div>
    
                        </div>
                        
                    </ng-container>
                    
    
                    <!-- Lista balances y pre balances principal -->
    
                    <ng-container *ngIf="!showRuts && showList && !showReporte && !showListaBalances && !showFormBalances">
                        <div class="col-sm-12">
                    
                            <div class="card w-100 mx-auto border-0 rounded m-5">
                                <div class="card-body p-5">
                    
                                    <div class="col-sm-12">
                                        <div class="row container__back-button">
                                            <button (click)="volverListaRuts()" class="btn btn-primary formulario ml-4 mr-auto">Volver</button>
                                            <button class="btn btn-success mr-4" (click)="verSeleccion()">Ver Estado Financiero</button>
                                            <button class="btn btn-primary mr-4" (click)="crearPreBalance()" *ngIf="validaPermisoCrear()">Crear</button>
                                        </div>
                                    </div>
                    
                                    <div class="col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover border my-0">
                                                <thead>
                                                    <tr class="bg-nivel1">
                                                        <th scope="col" class="text-center align-middle font-size">
                                                            Rut Empresa
                                                        </th>
                                                        <th scope="col" class="text-center align-middle font-size" width="25%">
                                                            Razón Social
                                                        </th>
                                                        <ng-container *ngFor="let item of periodoSelected">
                                                            <th scope="col" class="text-center align-middle font-size">
                                                                Definitivo
                                                            </th>
                                                            <th scope="col" class="text-center align-middle font-size">
                                                                Prebalance
                                                            </th>
                                                        </ng-container>
                                                        <th scope="col" class="text-center align-middle font-size">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="listaBalances.length > 0; else elseListaBalances">
                                                        <tr *ngFor="let item of listaBalances | paginate:{id: 'pagination-balance', itemsPerPage: currentItem, currentPage: currentPageBalance}">
                    
                                                            <td class="font-size align-middle text-nowrap">
                                                                {{ item[0] || '' | rutFormat }}
                                                            </td>
                    
                                                            <td class="font-size align-middle">
                                                                {{ item[1][0].nombreRazonSocial || '' }}
                                                            </td>
    
                                                            <ng-container *ngFor="let itemPeriodoSelected of periodoSelected">
    
                                                                <td class="font-size align-middle" width="10%">
    
                                                                    <ng-container *ngIf="item[1]">
            
                                                                        <ng-container *ngFor="let itemPeriodo of item[1]">
                                
                                                                            <ng-container *ngIf="itemPeriodo?.periodo == itemPeriodoSelected">
        
                                                                                <ng-container *ngIf="obtenerIndicadorBalance('balance', itemPeriodo?.periodo, item[0]) === true">
                                                                                    <div class="container-fluid px-0 d-flex align-items-start flex-column">
                                                                                        <div class="row mb-auto p-2">
                                                                                            <div class="col-12">
                                                                                                <input class="form-check-input cursor-pointer" 
                                                                                                    type="checkbox" 
                                                                                                    value="" 
                                                                                                    id="flexCheckDefault" 
                                                                                                    (click)="agregarBalanceComparacion($event, 'balance', itemPeriodo?.periodo, item[0])"
                                                                                                    [checked]="validarBalance('balance', itemPeriodo?.periodo, item[0])"
                                                                                                    [ngClass]="validarEditar('balance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear() ? 'mt-0': 'mt-3'"
                                                                                                >
                                                                                                <i 
                                                                                                    class="fa fa-pencil-square edit-balance" 
                                                                                                    *ngIf="validarEditar('balance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear()" 
                                                                                                    aria-hidden="true"
                                                                                                    (click)="editarByTable('balance', itemPeriodo?.periodo, item[0])"
                                                                                                ></i>
                                                                                            </div>
                                                                                            <div class="col-12" [ngClass]="validarEditar('balance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear() ? 'mt-3' : 'mt-5'">
                                                                                                {{obtenerMeses('balance', itemPeriodo?.periodo, item[0])}} {{itemPeriodo?.periodo}}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row p-2">
                                                                                            <ng-container *ngTemplateOutlet="estadosTemplate; context: { $implicit: {estado: obtenerEstado('balance', itemPeriodo?.periodo, item[0])} }"></ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
        
                                                                            </ng-container>
                                    
                                                                        </ng-container>
            
                                                                    </ng-container>
    
                                                                </td>
    
                                                                <td class="font-size align-middle" width="10%">
                                                                    <ng-container *ngIf="item[1]">
            
                                                                        <ng-container *ngFor="let itemPeriodo of item[1]">
                                
                                                                            <ng-container *ngIf="itemPeriodo?.periodo == itemPeriodoSelected">
                                    
                                                                                <ng-container *ngIf="obtenerIndicadorBalance('preBalance', itemPeriodo?.periodo, item[0]) === true">
                                                                                    <div class="container-fluid container-fluid px-0 d-flex align-items-start flex-column">
                                                                                        <div class="row mb-auto p-2">
                                                                                            <div class="col-12">
                                                                                                <input class="form-check-input cursor-pointer" 
                                                                                                    type="checkbox" 
                                                                                                    value="" 
                                                                                                    id="flexCheckDefault" 
                                                                                                    (click)="agregarBalanceComparacion($event, 'prebalance', itemPeriodo?.periodo, item[0])"
                                                                                                    [checked]="validarBalance('prebalance', itemPeriodo?.periodo, item[0])"
                                                                                                    [ngClass]="validarEditar('prebalance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear() ? 'mt-0' : 'mt-3'"
                                                                                                >
                                                                                                <i 
                                                                                                    class="fa fa-pencil-square edit-balance" 
                                                                                                    *ngIf="validarEditar('prebalance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear()" 
                                                                                                    aria-hidden="true"
                                                                                                    (click)="editarByTable('prebalance', itemPeriodo?.periodo, item[0])"
                                                                                                ></i>
                                                                                            </div>
                                                                                            <div class="col-12" [ngClass]="validarEditar('prebalance', itemPeriodo?.periodo, item[0]) && validaPermisoCrear() ? 'mt-3' : 'mt-5'">
                                                                                                {{obtenerMeses('preBalance', itemPeriodo?.periodo, item[0])}} {{itemPeriodo?.periodo}}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row p-2">
                                                                                            <ng-container *ngTemplateOutlet="estadosTemplate; context: { $implicit: {estado: obtenerEstado('prebalance', itemPeriodo?.periodo, item[0])} }"></ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
        
                                                                            </ng-container>
                                    
                                                                        </ng-container>
            
                                                                    </ng-container>
                                                                </td>
    
                                                            </ng-container>
    
                                                            <td class="font-size align-middle text-center text-nowrap">
                                                                <button type="button" class="btn btn-success" (click)="verListadoBalances(item[0])">Ver todos</button>
                                                            </td>
                    
                                                        </tr>
                                                    </ng-container>
                                                    <ng-template #elseListaBalances>
                                                        <tr>
                                                            <td class="font-size text-nowrap" colspan="100%">
                                                                Sin información
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div id="pagination" class="mt-3">
                                            <div class="col-xs-10">
                                                <pagination-controls id="pagination-balance" (pageChange)="currentPageBalance=$event"></pagination-controls>
                                            </div>
                                            <div class="col-xs-2 text-right">
                                                <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div class="col-sm-12 my-5" *ngIf="showTablaF22F29">
                                        <app-f22-f29-informativo-view class="container-fluid" [periodos]="periodoSelected" [objectF29]="f29" [objectF22]="f22"></app-f22-f29-informativo-view>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </ng-container>
    
                    <!-- Lista balances y pre balances por rut -->
    
                    <ng-container *ngIf="!showRuts && !showList && !showReporte && showListaBalances && !showFormBalances">
                        <div class="col-sm-12">
                    
                            <div class="card w-100 mx-auto border-0 rounded m-5">
                                <div class="card-body p-5">
                    
                                    <div class="col-sm-12">
                                        <div class="row container__back-button">
                                            <button (click)="volverLista()" class="btn btn-primary formulario ml-4 mr-auto">Volver</button>
                                        </div>
                                    </div>
    
                                    <div class="row px-4">
    
                                        <div class="col-sm-12 mb-3">
        
                                            <h3>Lista Balances rut: {{this.listaSeleccionada[0].rut || ''}}</h3>
                                            <button class="btn btn-success float-right mr-4" (click)="verSeleccion()" [disabled]="dataReporte.length <= 0">Ver Estado Financiero</button>
                                            <button class="btn btn-warning text-white float-right mr-4" *ngIf="validaPermisoAdmin()" (click)="verLogLista()">Logs</button>
        
                                        </div>
    
                                        <ng-container *ngFor="let periodo of periodoSelected; let i = index">
    
                                            <div class="col-sm-12">
        
                                                <accordion [isAnimated]="true" class="m-0 mb-3">
                                                    <accordion-group [isOpen]="i == 0 ? true:  false" panelClass="m-0 border-0" #accBalanceManualGroup id="accBalanceManualGroup">
                                                        <span accordion-heading>
                                                            <i class="fa" [ngClass]="{'fa-angle-down': accBalanceManualGroup?.isOpen, 'fa-angle-right': !accBalanceManualGroup?.isOpen}"></i>
                                                            Periodo: {{periodo}}
                                                        </span>
                                                        <div class="row px-3">
                                                            <div class="col-sm-12">
                                                                <div class="table-responsive">
                                                                    <table class="table table-bordered table-hover border my-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" class="text-center align-middle font-size"></th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    Estado
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    Mes Inicio
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    Mes Fin
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    SubTipo
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    Auditado
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size">
                                                                                    Moneda
                                                                                </th>
                                                                                <th scope="col" class="text-center align-middle font-size"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
    
                                                                            <tr>
                                                                                <th colspan="100%">Balances</th>
                                                                            </tr>
    
                                                                            <ng-container *ngIf="listaSeleccionada && listaSeleccionada.length > 0">
                                                                                <ng-container *ngFor="let item of listaSeleccionada; let i = index">
                                                                                    <ng-container *ngIf="item?.periodo == periodo && item?.listaBalances && item?.listaBalances.length > 0">
                                                                                        <ng-container *ngFor="let balance of item?.listaBalances; let j = index">
                                                                                            <tr>
                                                                                                <td class="text-center pr-4">
                                                                                                    <div class="container-width">
                                                                                                        <input class="form-check-input cursor-pointer" 
                                                                                                            type="checkbox" 
                                                                                                            value="" 
                                                                                                            id="flexCheckDefault" 
                                                                                                            (click)="agregarBalanceFromLista($event, balance)"
                                                                                                            [checked]="validarBalanceFromLista(balance)"
                                                                                                        >
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreEstado(balance?.Reporte?.estado) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreMes(balance?.Reporte?.mesInicio) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreMes(balance?.Reporte?.mesFin) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ balance?.Reporte?.subTipo || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ balance?.Reporte?.auditado === true ? 'Si' : (balance?.Reporte?.auditado === false ? 'No' : '') }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ balance?.Reporte?.moneda || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    <ng-container *ngIf="validaPermisoAdmin() && validaRutBalance(balance)">
                                                                                                        <button type="button" class="btn btn-danger mx-2" (click)="borrarBalance(balance?.Reporte?.periodo, balance?.DatosBasicosSolicitud || {}, i, j)">Borrar</button>
                                                                                                    </ng-container>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </ng-container>
    
                                                                            <tr>
                                                                                <th colspan="100%">Pre Balances</th>
                                                                            </tr>
    
                                                                            <ng-container *ngIf="listaSeleccionada && listaSeleccionada.length > 0">
                                                                                <ng-container *ngFor="let item of listaSeleccionada; let i = index">
                                                                                    <ng-container *ngIf="item?.periodo == periodo && item?.listaPreBalances && item?.listaPreBalances.length > 0">
                                                                                        <ng-container *ngFor="let preBalance of item?.listaPreBalances; let j = index">
                                                                                            <tr>
                                                                                                <td class="text-center pr-4">
                                                                                                    <div class="container-width mt-2">
                                                                                                        <input class="form-check-input cursor-pointer" 
                                                                                                            type="checkbox" 
                                                                                                            value="" 
                                                                                                            id="flexCheckDefault" 
                                                                                                            (click)="agregarBalanceFromLista($event, preBalance)"
                                                                                                            [checked]="validarBalanceFromLista(preBalance)"
                                                                                                        >
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreEstado(preBalance?.Reporte?.estado) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreMes(preBalance?.Reporte?.mesInicio) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ obtenerNombreMes(preBalance?.Reporte?.mesFin) || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ preBalance?.Reporte?.subTipo || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ preBalance?.Reporte?.auditado === true ? 'Si' : (preBalance?.Reporte?.auditado === false ? 'No' : '') }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    {{ preBalance?.Reporte?.moneda || '' }}
                                                                                                </td>
                                                                                                <td class="font-size align-middle text-nowrap">
                                                                                                    <ng-container *ngIf="validaBorrarPreBalance(preBalance)">
                                                                                                        <button type="button" class="btn btn-danger mx-2" (click)="borrarPreBalance(preBalance?.DatosBasicosSolicitud || {}, i, j)">Borrar</button>
                                                                                                    </ng-container>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </ng-container>
    
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </accordion-group>
                                                </accordion>
                                            </div>
    
                                        </ng-container>
    
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                    <!-- Editar/Crear balance y pre balance-->
    
                    <ng-container *ngIf="!showRuts && !showList && !showReporte && !showListaBalances && showFormBalances">
                    
                        <div class="col-sm-12">
                            <div class="card w-100 mx-auto border-0 rounded m-5">
                                <div class="card-body p-5">
                        
                                    <div class="col-sm-12">
                                        <div class="row container__back-button">
                                            <button (click)="volverLista()" class="btn btn-primary formulario ml-4">Volver</button>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex justify-content-center mb-3 no-print" *ngIf="showOpcionCarga">
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-info active" (click)="changeCarga(true)">
                                                <input type="radio" name="optionsCarga" id="optioncarga1" checked> Carga via Formulario
                                            </label>
                                            <label class="btn btn-info" (click)="changeCarga(false)">
                                                <input type="radio" name="optionsCarga" id="optioncarga2"> Carga via Archivo
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" *ngIf="!cargaFormulario && showOpcionCarga">
                                        <h3>Carga Balance Manual</h3>
                                    </div>
                        
                                    <div class="col-sm-12" *ngIf="cargaFormulario">
                                        <h3>Formulario Balance Manual</h3>
                                    </div>

                                    <!--Carga via archivo-->
                                    <div class="col-sm-12 mb-5" *ngIf="!cargaFormulario && showOpcionCarga">

                                        <div class="col-md-12 mx-auto my-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Periodo
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" 
                                                                id='periodoIngresoUsuario' 
                                                                name='periodoIngresoUsuario' 
                                                                [(ngModel)]="periodo"
                                                            >
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of periodos" [ngValue]="e">{{ e }}</option>
                                                            </select>

                                                        </div>
                                                        
                                                    </div>
                        
                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Tipo
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" 
                                                                id='tipoBalanceWeb' 
                                                                name='tipoBalanceWeb' 
                                                                [(ngModel)]="tipoBalanceWeb"
                                                                (change)="onChangeTipo()"
                                                            >
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of tipoBalanceWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Subtipo de Balance/PreBalance
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='tipoBalance2Web' name='tipoBalance2Web' [(ngModel)]="tipoBalance2Web">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of tipoBalance2WebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                        
                                                    <ng-container *ngIf="tipoBalanceWeb === 'prebalance' || tipoBalanceWeb === 'balance'">
                                                        <div class="col-sm-12 my-2">
                        
                                                            <div class="col-sm-12 my-1">
                                                                Mes Inicio
                                                            </div>
                                                            <div class="col-sm-12 col-md-8 my-1">
                                                                <select class="form-control" id='mesInicioIngresado' name='mesInicioIngresado' [(ngModel)]="mesInicioIngresado">
                                                                    <option value="" selected>Seleccione una opción</option>
                                                                    <option *ngFor="let e of mesWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                        
                                                        <div class="col-sm-12 my-2">
                        
                                                            <div class="col-sm-12 my-1">
                                                                Mes Final
                                                            </div>
                                                            <div class="col-sm-12 col-md-8 my-1">
                                                                <select class="form-control" id='mesFinalIngresado' name='mesFinalIngresado' [(ngModel)]="mesFinalIngresado">
                                                                    <option value="" selected>Seleccione una opción</option>
                                                                    <option *ngFor="let e of mesWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                                                    </ng-container>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Auditado
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='auditadoWeb' name='auditadoWeb' [(ngModel)]="auditadoWeb">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of auditadoWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Moneda
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='monedaWeb' name='monedaWeb' [(ngModel)]="monedaWeb">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of monedaWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                        
                                                </div>
                                            </div>
                                        </div>

                                        <form [formGroup]="formularioArchivo">
                                            <div class="form-group ml-2 mt-5">
                            
                                                <label class="uploadbutton ml-5">
                                                    <input class="input-file" formControlName="file" type="file" name="file" accept=".xlsx"
                                                        (change)="handleUpload($event)" id="file-csv-carga-automatica"/>
                                                    <div class="button btn">Nueva Carga</div>
                                                    <div class='input-file-text' [ngClass]="{'text-success': formularioArchivo.controls.file.value}">
                                                        {{formularioArchivo.controls.file.value || 'Subir Archivo'}}
                                                    </div>
                                                </label>
                                            </div>
                                        </form>
                                        <div class="justify-content-center row">
                                            <div class="col-sm-6 mx-auto mt-3">
                                                <fieldset>
                                                    <legend> Observaciones</legend>
                                                    <ng-template #sinAdvertencias>
                                                        Sin observaciones
                                                    </ng-template>
                            
                                                    <div class="pb-5">
                                                        <ng-container *ngIf="advertencias && advertencias.length > 0; else sinAdvertencias">
                                                            Se {{advertencias.length > 1? 'encontraron': 'encontro'}}
                                                            <strong> {{advertencias.length}} </strong> {{advertencias.length > 1? 'problemas':
                                                            'problema'}}
                                                            en el archivo
                                                        </ng-container>
                                                    </div>
                            
                                                    <div class="form-group form-actions container__actions">
                                                        <button type="button" class="btn btn-sm btn-warning" (click)="showErrors()" 
                                                        *ngIf="advertencias && advertencias.length > 0">Ver
                                                            Errores</button>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div class="col-sm-12 mx-auto mb-2 text-center mt-2" *ngIf="base64Xlsx != ''">
                                                <a 
                                                    id="descargaejemplo"
                                                    class="text-muted cursor-pointer"
                                                    title="Descargar archivo"
                                                    (click)="descargarExcel()"
                                                >
                                                    Descargar archivo template
                                                    <i class="fa fa-download text-success align-middle mx-2" 
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 my-1 text-center" *ngIf="!cargaFormulario && showOpcionCarga">                                        
                                        <ng-container *ngIf="tipoBalanceWeb === 'prebalance'">
                                            <button class="btn btn-primary mx-2" (click)="onSubmitArchivo(false)">Guardar</button>
                                        </ng-container>
                                        <ng-container *ngIf="tipoBalanceWeb === 'balance'">
                                            <button class="btn btn-primary mx-2" (click)="onSubmitArchivo(true)">Cerrar Balance</button>
                                            <button class="btn btn-success mx-2" (click)="onSubmitArchivo(false)">Guardar</button>
                                        </ng-container>
                                    </div>

                                    <!--Carga via formulario-->
                                    <div class="col-sm-12" *ngIf="cargaFormulario">
                        
                                        <div class="col-md-12 mx-auto my-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Periodo
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" 
                                                                id='periodoIngresoUsuario' 
                                                                name='periodoIngresoUsuario' 
                                                                [(ngModel)]="periodo"
                                                            >
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of periodos" [ngValue]="e">{{ e }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                        
                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Tipo
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" 
                                                                id='tipoBalanceWeb' 
                                                                name='tipoBalanceWeb' 
                                                                [(ngModel)]="tipoBalanceWeb"
                                                                [disabled]="!showOpcionCarga && tipoBalanceWebOriginal === 'balance' ? true : false"
                                                                (change)="onChangeTipo()"
                                                            >
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of tipoBalanceWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Subtipo de Balance/PreBalance
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='tipoBalance2Web' name='tipoBalance2Web' [(ngModel)]="tipoBalance2Web">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of tipoBalance2WebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                        
                                                    <ng-container *ngIf="tipoBalanceWeb === 'prebalance' || tipoBalanceWeb === 'balance'">
                                                        <div class="col-sm-12 my-2">
                        
                                                            <div class="col-sm-12 my-1">
                                                                Mes Inicio
                                                            </div>
                                                            <div class="col-sm-12 col-md-8 my-1">
                                                                <select class="form-control" id='mesInicioIngresado' name='mesInicioIngresado' [(ngModel)]="mesInicioIngresado">
                                                                    <option value="" selected>Seleccione una opción</option>
                                                                    <option *ngFor="let e of mesWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                        
                                                        <div class="col-sm-12 my-2">
                        
                                                            <div class="col-sm-12 my-1">
                                                                Mes Final
                                                            </div>
                                                            <div class="col-sm-12 col-md-8 my-1">
                                                                <select class="form-control" id='mesFinalIngresado' name='mesFinalIngresado' [(ngModel)]="mesFinalIngresado">
                                                                    <option value="" selected>Seleccione una opción</option>
                                                                    <option *ngFor="let e of mesWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                                                    </ng-container>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Auditado
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='auditadoWeb' name='auditadoWeb' [(ngModel)]="auditadoWeb">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of auditadoWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-sm-12 my-2">
                        
                                                        <div class="col-sm-12 my-1">
                                                            Moneda
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 my-1">
                                                            <select class="form-control" id='monedaWeb' name='monedaWeb' [(ngModel)]="monedaWeb">
                                                                <option value="" selected>Seleccione una opción</option>
                                                                <option *ngFor="let e of monedaWebOptions" [ngValue]="e.value">{{ e.text }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                        
                                                </div>
                                            </div>
                                        </div>
                        
                                        <form [formGroup]="formularioForm">
                        
                                            <ng-container *ngFor="let nivel1 of arrayContrato; let inivel1 = index;">
                                                <div class="col-md-12 mx-auto my-2">
                        
                                                    <ng-container *ngIf="!nivel1?.nivel1; else nivel1Template">
                        
                                                        <ng-container *ngIf="nivel1?.variables && nivel1?.variables.length > 0">
                                                            <div class="card w-100 mx-auto border-0">
                                                                <div class="card-body">
                        
                                                                    <div class="table-responsive">
                                                                        <table class="table table-bordered table-hover border my-0">
                                                                            <tbody>
                                                                                <ng-container *ngFor="let campo of nivel1?.variables">
                        
                                                                                    <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: ''} }"></ng-container>
                                    
                                                                                </ng-container>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                        
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        
                                                    </ng-container>
                        
                                                    <ng-template #nivel1Template>
                        
                                                        <div class="card w-100 mx-auto border-0">
                                                            <div class="card-body">
                        
                                                                <div class="table-responsive">
                                                                    <table class="table table-bordered table-hover border my-0">
                                                                        <thead>
                                                                            <tr class="bg-nivel1 cursor-pointer" (click)="toggleContent($event, 'header-nivel1' + inivel1)">
                                                                                <th scope="col" class="align-middle font-size font-weight-bold bg-nivel1">
                                                                                    {{  nivel1?.nivel1Name  }}
                                                                                </th>
                                                                                <th class="align-middle font-size text-right bg-nivel1" width="25%">
                                                                                    {{obtenerTotalSeccion(nivel1?.nivel1) | number: '1.0-2'}}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container *ngIf="nivel1?.nivel2 && nivel1?.nivel2.length > 0; else notNivel2Template">
                        
                                                                                <ng-container *ngFor="let nivel2 of nivel1?.nivel2; let inivel2 = index;">
                                
                                                                                    <ng-container *ngIf="!nivel2?.nivel2; else nivel2Template">
                                
                                                                                        <ng-container *ngFor="let campo of nivel2?.variables">
                                
                                                                                            <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' +inivel2} }"></ng-container>
                                
                                                                                        </ng-container>
                                
                                                                                    </ng-container>
                                                                                    <ng-template #nivel2Template>
                                                                                        <tr [id]="'header-nivel1' + inivel1" class="bg-nivel2 cursor-pointer" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2)">
                                                                                            <th scope="col" class="align-middle font-size bg-nivel2">
                                                                                                {{  nivel2?.nivel2Name  }}
                                                                                            </th>
                                                                                            <th class="align-middle font-size text-right bg-nivel2" width="25%">
                                                                                                {{obtenerTotalSubSeccion(nivel1?.nivel1, nivel2?.nivel2) | number: '1.0-2'}}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <ng-container *ngIf="nivel2?.nivel3 && nivel2?.nivel3.length > 0; else notNivel3Template">
                                
                                                                                            <ng-container *ngFor="let nivel3 of nivel2?.nivel3; let inivel3 = index;">
                                                                                                
                                                                                                <ng-container *ngIf="!nivel3?.nivel3; else nivel3Template">
                        
                                                                                                    <ng-container *ngFor="let campo of nivel3?.variables">
                        
                                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                                
                                                                                                    </ng-container>
                        
                                                                                                </ng-container>
                                                                                                <ng-template #nivel3Template>
                        
                                                                                                    <tr [id]="'header-nivel1' + inivel1 + '-nivel2' + inivel2" class="bg-nivel3 cursor-pointer" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2 + '-nivel3' + inivel3 + 'element')">
                                                                                                        <th scope="col" class="align-middle font-size bg-nivel3">
                                                                                                            {{  nivel3?.nivel3Name  }}
                                                                                                        </th>
                                                                                                        <th class="align-middle font-size text-right bg-nivel3" width="25%">
                                                                                                            {{obtenerTotalSeccion3(nivel1?.nivel1, nivel2?.nivel2, nivel3?.nivel3) | number: '1.0-2'}}
                                                                                                        </th>
                                                                                                    </tr>
                        
                                                                                                    <ng-container *ngFor="let campo of nivel3?.variables">
                        
                                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                                
                                                                                                    </ng-container> 
                        
                                                                                                </ng-template>
                        
                                                                                            </ng-container>
                        
                                                                                        </ng-container>
                        
                                                                                        <ng-template #notNivel3Template>
                                                                                                
                                                                                            <ng-container *ngFor="let campo of nivel2?.variables">
                        
                                                                                                <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2} }"></ng-container>
                        
                                                                                            </ng-container>
                        
                                                                                        </ng-template>
                                
                                                                                    </ng-template>
                                
                                                                                </ng-container>
                                
                                                                            </ng-container>
                                                                            <ng-template #notNivel2Template>
                                                                                
                                                                                <ng-container *ngFor="let campo of nivel1?.variables">
                                
                                                                                    <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1} }"></ng-container>
                                
                                                                                </ng-container>
                                                                                
                                                                            </ng-template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                        
                                                            </div>
                                                        </div>
                        
                                                    </ng-template>
                        
                                                </div>
                                            </ng-container>
                                
                                        </form>
                        
                                        <div class="col-sm-12 my-1 text-center">                                        
                                            <ng-container *ngIf="tipoBalanceWeb === 'prebalance'">
                                                <button class="btn btn-primary mx-2" (click)="onSubmit()">Guardar</button>
                                            </ng-container>
                                            <ng-container *ngIf="tipoBalanceWeb === 'balance'">
                                                <button class="btn btn-primary mx-2" (click)="onSubmitBalance(true)">Cerrar Balance</button>
                                                <button class="btn btn-success mx-2" (click)="onSubmitBalance()">Guardar</button>
                                            </ng-container>
                                        </div>
                        
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                    
                    </ng-container>
    
                </div>  
            </ng-container>
            <ng-template #elseAccess>
                <div class="w-100 mt-5">
                    <app-request-access-view></app-request-access-view>
                </div>
            </ng-template>
            
        </div>
    </div>
</div>


<!-- Visualizacion de reporte -->

<ng-container *ngIf="showReporte && !showListaBalances && !showList && !showFormBalances">
    <div class="col-sm-12">
        <div class="container-gtp mb-5">
            <div class="break-box">
                <div class="row">
                    <div class="panel panel-default w-100 mx-4">
                        <div class="panel-body">
                            <div class="col-sm-12 px-1">
                                <button (click)="volverLista()" class="btn btn-primary">Volver</button>
                                <button class="btn btn-primary float-right mx-2" 
                                    [printTitle]='"BalanceManual_"+(rutSeleccionado | rutFormat)+"_"+today'
                                    [useExistingCss]="true" 
                                    printSectionId="print-section" 
                                    ngxPrint
                                >
                                    Descargar PDF
                                </button>
                                <button class="btn btn-primary float-right mx-2" 
                                    (click)="exportAsXLSX()"
                                >
                                    Descargar Excel
                                </button>
                                <ng-container *ngIf="dataReporte.length === 1; else noDataBalance">
                                    <ng-container *ngIf="validaEditarBalance(); else elseEditar">
                                        <button class="btn btn-success float-right mx-2" (click)="editarPreBalance()" *ngIf="validaPermisoCrear()">Editar</button>
                                    </ng-container>
                                    <ng-template #elseEditar>
                                        <div></div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #noDataBalance>
                                    <div></div>
                                </ng-template>
                                <button class="btn btn-warning text-white float-right mx-2" *ngIf="dataReporte.length === 1 && validaPermisoAdmin()" (click)="verLog()">Logs</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12" id="print-section">
        <app-reporte-balance-manual-view [rut]="rutSeleccionado" [data]="dataReporte" [listaArchivos]="listaArchivos" [razonSocial]="razonSocialSeleccionado"></app-reporte-balance-manual-view>
    </div>
</ng-container>

<!-- Template input formulario -->
<ng-template #inputsTemplate let-elemento>

    <ng-container [formGroup]="formularioForm">
        <tr [id]="'header-' + elemento?.index">
            <td class="font-size align-middle">
                {{ elemento?.campo?.name || elemento?.campo?.key }}
            </td>
            <td class="font-size align-middle" width="25%">
                <ng-container *ngIf="elemento?.campo?.type !== 'Boolean'; else booleanTemplate">
                    <input 
                    [attr.type]="(elemento?.campo?.type === 'String') ? 'text' : ((elemento?.campo?.type === 'Bigint' || elemento?.campo?.type === 'decimal') ? 'number' : elemento?.campo?.type)"
                    class="form-control"
                    [formControlName]="elemento?.campo?.key">
                </ng-container>
                <ng-template #booleanTemplate>
                    <select class="form-control" [formControlName]="elemento?.campo?.key">
                        <option value="">Seleccione una opción</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </ng-template>
                <div class="w-100 mb-2">
                    <app-error [control]="formularioForm.get(elemento?.campo?.key)"></app-error>                    
                    <div class="w-100 mt-2 error-custom" *ngIf="formularioForm?.get(elemento?.campo?.key)?.hasError('errorPersonalizado')">
                        <span class="text-danger font-weight-bolder" *ngIf="formularioForm?.get(elemento?.campo?.key)?.errors?.errorPersonalizado?.mensaje">
                            * {{ formularioForm?.get(elemento?.campo?.key)?.errors?.errorPersonalizado?.mensaje }}
                        </span>
                    </div>
                </div>
            </td>
        </tr>
    </ng-container>
    
</ng-template>


<!-- Template estados -->
<ng-template #estadosTemplate let-elemento>
    <div class="col-12" *ngIf="elemento?.estado === 'in-progress'">
        <span class="badge badge-warning p-2">En Progreso</span>
    </div>
    <div class="col-12" *ngIf="elemento?.estado === 'closed'">
        <span class="badge badge-success p-2">Cerrada</span>
    </div>    
</ng-template>
