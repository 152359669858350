import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { ModalBasicoConsolidadoService } from 'src/app/shared/services/modal-basico-consolidado.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment as env } from 'src/environments/environment';

interface TablaResumenSaldo {
  rut: string, 
  clientes: string, 
  lcCmt: number, 
  vencido: number, 
  porVencer: number, 
  totalFact: number, 
  saldoPendFact: number, 
  saldoDocBloq: number, 
  totalOcup: number, 
  lcCiaDeSeguroUf: number | string, 
  lcCiaDeSeguroM: number | string
}

interface TablaResumenSaldoTotales {
  lcCmt: number, 
  vencido: number, 
  porVencer: number, 
  totalFact: number, 
  saldoPendFact: number, 
  saldoDocBloq: number, 
  totalOcup: number, 
  lcCiaDeSeguroUf: number | string, 
  lcCiaDeSeguroM: number | string
}

interface MaterialesMargenes {
  pedidoCotizacionOc: string, 
  distribucion: string, 
  producto: string, 
  proveedor: string, 
  montoM: number, 
  mgPorc: number, 
  contribucionM: number
}

interface MaterialesMargenesTotales {
  montoM: number, 
  mgPorc: number, 
  contribucionM: number
}

interface Comentario {
  title: string, 
  id: string, 
  comentario: string,
  autor?: string,
  fecha?: string
}

interface ResolucionSeguro {
  companiaSeguro: string,
  idNacional: string,
  cliente: string,
  vigenciaDesde: string,
  vigenciaHasta: string,
  fechaResolucion: string,
  montoSolicitado: number,
  montoAprobado: number,
  estado: string,
  condicionVenta: string,
  observacion: string
}

interface VolumenNegocio {
  anios: number[],
  meses: VolumenMes[],
  totales: number[]
  promedios: number[]
}

interface VolumenMes {
  mes: string,
  montos: any[]
}

interface AnalisisDeclaracionIva {
  anios: number[],
  anioActual: AnalisisDeclaracionIvaAnio,
  anioAnterior: AnalisisDeclaracionIvaAnio,
}

interface AnalisisDeclaracionIvaAnio {
  meses: AnalisisDeclaracionIvaMes[],
  totales: number[],
  promedios: number[]
}

interface AnalisisDeclaracionIvaMes {
  mes: string,
  ventaM: number | string,
  compraM: number | string,
  margenM: number | string
}

@Component({
  selector: 'app-plantilla-puntual-caratula',
  templateUrl: './plantilla-puntual-caratula.component.html',
  styleUrls: ['./plantilla-puntual-caratula.component.scss']
})
export class PlantillaPuntualCaratulaComponent implements OnInit {
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;

  public objectKeys = Object.keys;
  public subscriptions: Subscription[] = [];
  public today = '';
  public dataCargada: boolean = false;
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingAprobacion: string[] = ['buscarReportesDerivados', 'buscarReportePrincipal'];
  public atributos: any[] = [];
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public reportesPrincipales: any = {};
  public reportesDerivados: any = {};
  public errores: any[] = [];
  public bodyModelos: any = {};
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar
  public showReportes: boolean = false;
  public showArchivos: boolean = false;
  public motorCalculos: any;
  public motorConDatos: boolean = false;
  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};


  // primer bloque
  public tablaResumenSaldo: {body: TablaResumenSaldo[], totales: TablaResumenSaldoTotales} | null = null; 
  // segundo bloque
  public razonSocial: string = '';
  public caratulaPuntual: any[] = [];
  //tercer bloque
  public rutGarante: string[] = [];
  public razonSocialGarante: string[] = [];
  public caratulaPuntualPart2: any[] = [];
  // cuarto bloque
  public materialesMargenes: {body: MaterialesMargenes[], totales: MaterialesMargenesTotales} = {
    body: [
      {
        pedidoCotizacionOc: '',
        distribucion: '',
        producto: '',
        proveedor: '',
        montoM: 0,
        mgPorc: 0,
        contribucionM: 0
      }
    ],
    totales: {
      montoM: 0,
      mgPorc: 0,
      contribucionM: 0
    }
  };
  // quinto bloque
  public comentarios: Comentario[] = [];
  // sexto bloque
  public rutResolucion: string = '';
  public razonSocialResolucion: string = '';
  public resolucionSeguro: ResolucionSeguro[] = [];
  // septimo bloque
  public rutVolumenNegocio: string = '';
  public razonSocialVolumenNegocio: string = '';
  public volumenNegocio: VolumenNegocio | null = null;
  // octavo bloque
  public rutAnalisisDeclaracionIva: string = '';
  public razonSocialAnalisisDeclaracionIva: string = '';
  public analisisDeclaracionIva: AnalisisDeclaracionIva | null = null;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public reportesSeleccionado: any = {};
  public consolidadoTitular: any = {};
  public rutSeleccionado: string = '';
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true; 
  public informeComercial: string = '';

  public currentPage = 1;
  public currentItem = env.initItemPerPage;
  public listValidatorsIds: string[] = [];

  public caratulaPuntualInput: any = {};

  public totalesInformacionFinanciera: any = {};
  public informacionFinancieraTable: any[] = [];

  public lineaOperacionPropuesta: any = {};
  public lineaOperacionPropuestaTotales: any = {};

  public date: string[] = [
    'VENCIMIENTO PUNTUAL',
    'ANTIGÜEDAD CMT',
    'FECHA COMPROMISO'
  ];

  public text: string[] = [
    'SEGMENTO',
    'TIPO GARANTIA',
    'FECHA BALANCE',
    'CON. DE VENTA'
  ];

  public valorUFActual: number = 0;

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private modalService: ModalBasicoConsolidadoService,
    private _sessionService: SessionService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    } else {
      this.mapeoNoEditable();
      this.showArchivos = true;
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServicesFicha();
          }
        }
      }
      
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  mapeoNoEditable(): void {
    this.callServicesFichaAprobacion();    
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFichaAprobacion(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingAprobacion.length > 0) {
      for await (const value of this.groupNameConsultingAprobacion) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaAprobacion");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  mapeoFichaGuardada(): void {
    this.fichaGuardada = this.objEntradaFicha;
    const ficha = this.objEntradaFicha?.ficha;

    this.mapeoFromFicha(ficha);

    this.motorCalculos = this.objEntradaMotor;
    if(this.motorCalculos?.ResponseReglas?.message){
      this.motorConDatos = false;
    } else {
      this.motorConDatos = true;
    }
    (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
  }

  mapeoFromFicha(ficha: any): void {
    this.rut = ficha?.rut || '';
    this.consolidadoTitular = ficha?.consolidadoTitular || {};
    this.razonSocial = ficha?.razonSocial || '';
    this.caratulaPuntual = ficha?.caratulaPuntual || [];
    this.tablaResumenSaldo = ficha?.tablaResumenSaldo || null;
    this.rutGarante = ficha?.rutGarante || [];
    this.razonSocialGarante = ficha?.razonSocialGarante || [];
    this.caratulaPuntualPart2 = ficha?.caratulaPuntualPart2 || [];
    this.materialesMargenes = ficha?.materialesMargenes || {body: [], totales: {}};
    this.comentarios = ficha?.comentarios || [];
    this.rutResolucion = ficha?.rutResolucion || '';
    this.razonSocialResolucion = ficha?.razonSocialResolucion || '';
    this.resolucionSeguro = ficha?.resolucionSeguro || [];
    this.rutVolumenNegocio = ficha?.rutVolumenNegocio || '';
    this.razonSocialVolumenNegocio = ficha?.razonSocialVolumenNegocio || '';
    this.volumenNegocio = ficha?.volumenNegocio || null;
    this.rutAnalisisDeclaracionIva = ficha?.rutAnalisisDeclaracionIva || '';
    this.razonSocialAnalisisDeclaracionIva = ficha?.razonSocialAnalisisDeclaracionIva || '';
    this.analisisDeclaracionIva = ficha?.analisisDeclaracionIva || null;
    this.caratulaPuntualInput = ficha?.caratulaPuntualInput || {};
    this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
    this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];
    this.valorUFActual = ficha?.valorUFActual;
  }

  setResponse(service: string, response: any): void { // depende del mapeo que se defina
    if (service === 'buscarReportePrincipal'){
      //this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      //this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito'){

      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = JSON.parse(JSON.stringify(response?.FichaDeCredito?.ficha));
      
      this.mapeoFromFicha(ficha);

    } else if (service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.mapeoFromFicha(ficha);

      // parte motor calculos para score
      this.motorCalculos = response?.MotorDeCalculos;

      if(this.motorCalculos?.ResponseReglas?.message){
        this.motorConDatos = false;
      } else {
        this.motorConDatos = true;
      }

      (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
    }
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        //console.log("count 0")
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.obtenerUf();
        } else if (etapa === 'callServicesFicha'){
          this.showReportes = true;
          //this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0 ? this.dataCargada = true : this.dataCargada = false; --> validar si se usa
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaAprobacion'){ // validar *
          this.mapeoFichaGuardada();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        //console.log("count 0 2")
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  logicaMapeo(): void {
    this.obtenerBalanceAutomatico();
    this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
    this.mapeoReportes();
    this.spinner.hide();
  }

  obtenerUf(): void {
    const date = new Date();
    date.setUTCHours(0,0,0,0);
    const dateFormatted: string = date.toISOString();
    let uf = 0;
    this.solicitudCreditoService.calcularUF(dateFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
      uf = resp?.valores?.[0]?.valor || 0;
      this.valorUFActual = Number(uf);
      if(!(uf > 0)){
        this.alertService.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.');
        console.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.', resp?.valores?.[0]?.valor)
      }
      this.logicaMapeo();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
      this.valorUFActual = uf;
      this.logicaMapeo();
    })
  }

  obtenerBalanceAutomatico(): void {
    const datos = this.obtenerDatosF22();

    if(datos.length > 0){
      //this.data = this.utilsService.calculosVariacion(datos);
      //let periodos = this.utilsService.obtenerPeriodos(this.data);
      //periodos = periodos.slice(0, 2);
      //this.dataFiltrada = this.utilsService.obtenerReporteFiltrado(this.data, periodos);
      this.dataCargada = true;
    } else {
      this.dataCargada = false;
    }
  }

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if((element?.tipo !== 'manual' || !element?.tipo) && element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  getLineaContinental(): any {
    let reporteLineaNominada: any[] = this.reportesPrincipalesObj?.consultaSegurosContinental?.reporte?.ConsultaSegurosContinentalLineasNominadas || null;
    let reporteLineaInnominada: any[] = this.reportesPrincipalesObj?.consultaSegurosContinental?.reporte?.ConsultaSegurosContinentalLineasInnominadas || null;

    let respuesta: any = null;
    // validacion de lineas nominadas
    if(reporteLineaNominada && reporteLineaNominada.length > 0){
      reporteLineaNominada = reporteLineaNominada.filter((linea: any) => linea?.Estado && typeof(linea?.Estado) == 'string' && 
        linea?.Estado.toUpperCase().includes("ACTIVA")
      );

      // Sort reporteLineaNominada array based on FechaResolucion in descending order
      reporteLineaNominada = reporteLineaNominada.sort((a: { FechaResolucion?: string }, b: { FechaResolucion?: string }) => {
        const fechaResolucionA = a?.FechaResolucion || '';
        const fechaResolucionB = b?.FechaResolucion || '';
        return new Date(fechaResolucionB).getTime() - new Date(fechaResolucionA).getTime();
      });

      if(reporteLineaNominada.length > 0){
        respuesta = reporteLineaNominada[0];
      }

    }
    // validacion de lineas innominadas
    if(respuesta === null && reporteLineaInnominada && reporteLineaInnominada.length > 0){
      reporteLineaInnominada = reporteLineaInnominada.filter((linea: any) => linea?.Estado && typeof(linea?.Estado) == 'string' && 
        linea?.Estado.toUpperCase().includes("ACTIVA")
      );
      if(reporteLineaInnominada.length > 0){
        const vigencias: any [] = [];
        for(const element of reporteLineaInnominada){
          if(element?.Vigencias && element?.Vigencias.length > 0){
            vigencias.push(...element?.Vigencias);
          }
        }

        if(vigencias.length > 0) {
          const vigenciasActivas = vigencias.filter((vigencia: any) => vigencia?.Estado && typeof(vigencia?.Estado) == 'string' &&
            vigencia?.Estado.toUpperCase().includes("ACTIVA")
          );
          if(vigenciasActivas.length > 0){
            // Sort vigenciasActivas array based on FechaDesde in descending order
            vigenciasActivas.sort((a: { FechaDesde?: string }, b: { FechaDesde?: string }) => {
              const fechaDesdeA = a?.FechaDesde || '';
              const fechaDesdeB = b?.FechaDesde || '';
              return new Date(fechaDesdeB).getTime() - new Date(fechaDesdeA).getTime();
            });
            const object: any = {
              IdNacion: vigenciasActivas[0]?.Rut || '',
              Nombre: reporteLineaInnominada[0]?.Nombre || '',
              FechaDesde: vigenciasActivas[0]?.FechaDesde || '',
              FechaHasta: '',
              FechaResolucion: vigenciasActivas[0]?.FechaDesde || '',
              MtoSolicitado: 600,
              MtoAprobado: 600,
              Estado: vigenciasActivas[0]?.Estado || '',
              CondicionVenta: '',
              Observacion: ''
            }
            respuesta = object;
          }
        }
      }
    }

    return respuesta;
  }

  getLineaAvla(reporteLinea: any[]): any {
    let respuesta: any = null;
    if(reporteLinea && reporteLinea.length > 0) {
      const estadoAprobado = [
        "APROBADA",
        "APROBADA PARCIAL",
        "REDUCIDA",
        "REDUCIDA DE APROBADA PARCIAL"
      ];
      reporteLinea = reporteLinea.filter((linea: any) => linea?.stateDto?.name && typeof(linea?.stateDto?.name) == 'string' && 
        estadoAprobado.some(elemento => linea?.stateDto?.name.toUpperCase().includes(elemento))
      );
      
      // Sort reporteLinea array based on createDateStr in descending order
      reporteLinea = reporteLinea.sort((a: { createDateStr?: string }, b: { createDateStr?: string }) => {
        const fechaResolucionA = a?.createDateStr || '';
        const fechaResolucionB = b?.createDateStr || '';
        return new Date(fechaResolucionB).getTime() - new Date(fechaResolucionA).getTime();
      });
      
      if(reporteLinea.length > 0) {
        respuesta = reporteLinea[0];
      }
    }

    return respuesta;
  }

  mapeoTablaResumenSaldo(): void {

    this.tablaResumenSaldo = {
      body: [],
      totales: new Object() as TablaResumenSaldoTotales
    }
    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    const bpCredito = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.bpCredito) : null;
    const montoOcupado = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.montoOcupado) : null;
    const lineaNominadaContinental: any = this.getLineaContinental();
    const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);
    
    let montoAprobadoUF: number | string = '';
    if(lineaNominadaContinental){
      montoAprobadoUF = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
    }
    if(lineaAvla){
      const tempMontoAprobadoUF = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
      if(tempMontoAprobadoUF > montoAprobadoUF){
        montoAprobadoUF = tempMontoAprobadoUF;
      }
    }

    // Sort bpCredito array based on limitValidDate
    if (bpCredito?.bpCredito && bpCredito?.bpCredito.length > 0) {
      bpCredito.bpCredito.sort((a: any, b: any) => {
        const dateA = a.limitValidDate ? new Date(a.limitValidDate) : new Date('9999-12-31');
        const dateB = b.limitValidDate ? new Date(b.limitValidDate) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime();
      });
    }

    const objetoTabla = new Object() as TablaResumenSaldo;
    objetoTabla.rut = this.rut || '';
    objetoTabla.clientes = bpCredito?.bpCredito[0]?.nameOrg1 || '';
    objetoTabla.lcCmt = bpCredito?.bpCredito[0]?.creditLimit || bpCredito?.bpCredito[0]?.creditLimit == 0 ? bpCredito?.bpCredito[0]?.creditLimit : '';
    objetoTabla.vencido = montoOcupado?.montoOcupado[0]?.tvenceDesde || montoOcupado?.montoOcupado[0]?.tvenceDesde == 0 ? montoOcupado?.montoOcupado[0]?.tvenceDesde : '';
    objetoTabla.porVencer = montoOcupado?.montoOcupado[0]?.tvenceEn || montoOcupado?.montoOcupado[0]?.tvenceEn == 0 ? montoOcupado?.montoOcupado[0]?.tvenceEn : '';
    objetoTabla.totalFact = Number(objetoTabla.vencido || 0) + Number(objetoTabla.porVencer || 0);
    objetoTabla.saldoPendFact = montoOcupado?.montoOcupado[0]?.saldoPendiFact || montoOcupado?.montoOcupado[0]?.saldoPendiFact == 0 ? montoOcupado?.montoOcupado[0]?.saldoPendiFact : '';
    objetoTabla.saldoDocBloq = montoOcupado?.montoOcupado[0]?.saldoDocBlq || montoOcupado?.montoOcupado[0]?.saldoDocBlq == 0 ? montoOcupado?.montoOcupado[0]?.saldoDocBlq : '';
    objetoTabla.totalOcup = montoOcupado?.montoOcupado[0]?.montoOcupado || montoOcupado?.montoOcupado[0]?.montoOcupado == 0 ? montoOcupado?.montoOcupado[0]?.montoOcupado : '';
    objetoTabla.lcCiaDeSeguroUf = montoAprobadoUF || montoAprobadoUF == 0 ? montoAprobadoUF : '';
    objetoTabla.lcCiaDeSeguroM = ((montoAprobadoUF || montoAprobadoUF == 0) && this.valorUFActual > 0) ? 
      (this.toMiles(Number(montoAprobadoUF || 0) * this.valorUFActual)) : '';

    this.tablaResumenSaldo.body.push(objetoTabla);

    for(const element of this.tablaResumenSaldo.body) {
      this.tablaResumenSaldo.totales.lcCmt = Number(this.tablaResumenSaldo.totales.lcCmt || 0) + Number(element.lcCmt || 0);
      this.tablaResumenSaldo.totales.vencido = Number(this.tablaResumenSaldo.totales.vencido || 0) + Number(element.vencido || 0);
      this.tablaResumenSaldo.totales.porVencer = Number(this.tablaResumenSaldo.totales.porVencer || 0) + Number(element.porVencer || 0);
      this.tablaResumenSaldo.totales.totalFact = Number(this.tablaResumenSaldo.totales.totalFact || 0) + Number(element.totalFact || 0);
      this.tablaResumenSaldo.totales.saldoPendFact = Number(this.tablaResumenSaldo.totales.saldoPendFact || 0) + Number(element.saldoPendFact || 0);
      this.tablaResumenSaldo.totales.saldoDocBloq = Number(this.tablaResumenSaldo.totales.saldoDocBloq || 0) + Number(element.saldoDocBloq || 0);
      this.tablaResumenSaldo.totales.totalOcup = Number(this.tablaResumenSaldo.totales.totalOcup || 0) + Number(element.totalOcup || 0);
      this.tablaResumenSaldo.totales.lcCiaDeSeguroUf = Number(this.tablaResumenSaldo.totales.lcCiaDeSeguroUf || 0) + Number(element.lcCiaDeSeguroUf || 0);
      this.tablaResumenSaldo.totales.lcCiaDeSeguroM = Number(this.tablaResumenSaldo.totales.lcCiaDeSeguroM || 0) + Number(element.lcCiaDeSeguroM || 0);
    }
    
  }

  getLastDayOfMonth(year: number, month: number): string {
    const lastDay = new Date(year, month, 0).getDate();
    const formattedMonth = month.toString().padStart(2, '0');
    return `${lastDay}-${formattedMonth}-${year}`;
  }

  obtenerComprasUltimos12Meses(): any {
    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    let compraUltima = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.compraUltima) : null;
    compraUltima = compraUltima?.compraUltima || [];

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1; 

    const mesesOrdenados = compraUltima.sort((a: any, b: any) => {
      const anioA = a.gjahr;
      const mesA = a.monat;
      const anioB = b.gjahr;
      const mesB = b.monat;

      if (anioA === anioB) {
        return mesA - mesB;
      } else {
        return anioA - anioB;
      }
    });

    const ultimos12Meses = mesesOrdenados.filter((mes: any) => {
      const anioMes = mes.gjahr;
      const numeroMes = mes.monat;

      // Solo incluir los meses que estén dentro de los últimos 12 meses
      const diferenciaMeses = (anioActual - anioMes) * 12 + mesActual - numeroMes;
      return diferenciaMeses >= 0 && diferenciaMeses < 12;
    });

    const sumaTotal = ultimos12Meses.reduce((total: number, mes: any) => total + (Number(mes?.dmbtr || 0) || 0), 0);

    return sumaTotal;
  }

  calculosById(indice1: number, indice2: number, indice3: number): void {
    const text: any = this.caratulaPuntual[indice1].body[indice3].text;
    if(text){
      if(text === 'MONTO PUNTUAL M$') {
        const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
        const bpCredito = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.bpCredito) : null;

        // Sort bpCredito array based on limitValidDate
        if (bpCredito?.bpCredito && bpCredito?.bpCredito.length > 0) {
          bpCredito.bpCredito.sort((a: any, b: any) => {
            const dateA = a.limitValidDate ? new Date(a.limitValidDate) : new Date('9999-12-31');
            const dateB = b.limitValidDate ? new Date(b.limitValidDate) : new Date('9999-12-31');
            return dateB.getTime() - dateA.getTime();
          });
        }
        const creditLimit = bpCredito?.bpCredito[0]?.creditLimit || bpCredito?.bpCredito[0]?.creditLimit == 0 ? bpCredito?.bpCredito[0]?.creditLimit : '';
        const input = this.caratulaPuntualInput.number[`${indice1}-${indice2}-${indice3}`];
        const maximo = this.caratulaPuntual[0].body.findIndex((e: any) => e.text === 'MÁXIMO USO M$');
        if(maximo >= 0) {
          this.caratulaPuntual[0].body[maximo].value[0] = Number(creditLimit || 0) + Number(input || 0);
          this.caratulaPuntual[0].listaValores[0][maximo] = Number(creditLimit || 0) + Number(input || 0);
        }
        const descalce = this.caratulaPuntual[0].body.findIndex((e: any) => e.text === 'DESCALCE CON CIA. M$');
        if(descalce >= 0) {
          const lineaNominadaContinental: any = this.getLineaContinental();
          const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);
          
          let montoAprobadoUF: number | string = '';
          if(lineaNominadaContinental){
            montoAprobadoUF = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
          }
          if(lineaAvla){
            const tempMontoAprobadoUF = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
            if(tempMontoAprobadoUF > montoAprobadoUF){
              montoAprobadoUF = tempMontoAprobadoUF;
            }
          }

          const puntualesAprobados = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.puntualesAprobados) : null;
          if (puntualesAprobados?.puntualesAprobados && puntualesAprobados?.puntualesAprobados.length > 0) {
            puntualesAprobados.puntualesAprobados.sort((a: any, b: any) => {
              const dateA = a.dateto ? new Date(a.dateto) : new Date('9999-12-31');
              const dateB = b.dateto ? new Date(b.dateto) : new Date('9999-12-31');
              return dateB.getTime() - dateA.getTime();
            });
          }

          const lcCiaSeguroM = ((montoAprobadoUF || montoAprobadoUF == 0) && this.valorUFActual > 0) ? (this.toMiles(Number(montoAprobadoUF || 0) * this.valorUFActual)) : '';
          const maximoUso = Number(creditLimit || 0) + Number(input || 0);
          const totalCobertura = Number(lcCiaSeguroM || 0) + Number(puntualesAprobados?.puntualesAprobados[0]?.amnt || 0);

          this.caratulaPuntual[0].body[descalce].value[0] = Number(maximoUso || 0) - Number(totalCobertura || 0);
          this.caratulaPuntual[0].listaValores[0][descalce] = Number(maximoUso || 0) - Number(totalCobertura || 0);
        }
      }
    }
  }

  obtenerPPP3M(plazoPromedio: any[]): number | string {
    /*
    Ponderación del mes = Monto del Pago del mes / Total de Pagos de los tres meses
    Factor del mes = PPP Mensual del mes * Ponderación del mes
    PPP de los últimos tres meses = Suma de Factores de los tres meses
    */
    const fechaActual = new Date(); // new Date(2023, 3, 1);
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    
    const plazoPromedioMesActual = plazoPromedio.filter((item: any) => {
      const fecha = new Date(item?.bldat2);
      const fechaLimite = new Date(anioActual, mesActual - 4, 1); // Get the date three months ago

      return fecha >= fechaLimite;
    });
    
    let pppMensual: any = '';
    if(plazoPromedioMesActual && plazoPromedioMesActual.length > 0){
      let totalPagos = 0; //dmbtr2
      pppMensual = 0;

      for (const item of plazoPromedioMesActual) {
        totalPagos += Number(item?.dmbtr2 || 0);
      }

      for(const item of plazoPromedioMesActual) {
        const fecha1 = new Date(item?.bldat1);
        const fecha2 = new Date(item?.bldat2);
        const diffInTime = fecha2.getTime() - fecha1.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
        const ponderacion = Number(item?.dmbtr2 || 0) / Number(totalPagos || 0);
        const factor = Number(diffInDays || 0) * Number(ponderacion || 0);
        pppMensual += (Number(factor || 0));
      }

    }

    return pppMensual;
  }

  mapeoCaratulaPuntual(): void {
    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    const puntualesAprobados = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.puntualesAprobados) : null;
    const bpCredito = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.bpCredito) : null;
    const montoOcupado = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.montoOcupado) : null;
    const deudaVencida = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.deudaVencida) : null;
    const lineaNominadaContinental: any = this.getLineaContinental();
    const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);

    let plazoPromedio = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.plazoPromedio) : [];
    plazoPromedio = plazoPromedio?.plazoPromedio || [];
    
    let montoAprobadoUF: number | string = '';
    if(lineaNominadaContinental){
      montoAprobadoUF = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
    }
    if(lineaAvla){
      const tempMontoAprobadoUF = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
      if(tempMontoAprobadoUF > montoAprobadoUF){
        montoAprobadoUF = tempMontoAprobadoUF;
      }
    }

    // Sort bpCredito array based on limitValidDate
    if (bpCredito?.bpCredito && bpCredito?.bpCredito.length > 0) {
      bpCredito.bpCredito.sort((a: any, b: any) => {
        const dateA = a.limitValidDate ? new Date(a.limitValidDate) : new Date('9999-12-31');
        const dateB = b.limitValidDate ? new Date(b.limitValidDate) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime();
      });
    }

    if (puntualesAprobados?.puntualesAprobados && puntualesAprobados?.puntualesAprobados.length > 0) {
      puntualesAprobados.puntualesAprobados.sort((a: any, b: any) => {
        const dateA = a.dateto ? new Date(a.dateto) : new Date('9999-12-31');
        const dateB = b.dateto ? new Date(b.dateto) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime();
      });
    }

    // Balance manual

    const listadoBalances: any = [];
    if(this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances && this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances);
    }

    if(this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances && this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances);
    }

    let sortedBalances = [];

    if(listadoBalances && listadoBalances.length > 0){
      sortedBalances = listadoBalances.sort((a: any, b:any) => {
        const periodoA = a?.BalanceManualReporte?.Reporte?.periodo || '';
        const periodoB = b?.BalanceManualReporte?.Reporte?.periodo || '';
        const mesInicioA = a?.BalanceManualReporte?.Reporte?.mesInicio || 0;
        const mesInicioB = b?.BalanceManualReporte?.Reporte?.mesInicio || 0;
        const mesFinA = a?.BalanceManualReporte?.Reporte?.mesFin || 0;
        const mesFinB = b?.BalanceManualReporte?.Reporte?.mesFin || 0;
  
        if (periodoA === '' && periodoB === '') {
          return 0;
        } else if (periodoA === '') {
          return 1;
        } else if (periodoB === '') {
          return -1;
        }
  
        if (periodoA !== periodoB) {
          return parseInt(periodoB) - parseInt(periodoA);
        }
  
        if (mesInicioA === 1 && mesFinA === 12) {
          return -1;
        } else if (mesInicioB === 1 && mesFinB === 12) {
          return 1;
        }
  
        return mesFinB - mesFinA;
      });
    }
    
    const balanceFinal: any[] = [];
    let count = 0;

    for(const e of sortedBalances) {
      if (count < 2 && e?.BalanceManualReporte?.Reporte?.estado === 'closed' && e?.BalanceManualReporte?.Reporte?.tipo === 'balance') {
        balanceFinal.push(e);
        count++;
      }
    }
    if (balanceFinal.length > 0 && balanceFinal.length < 2) { // siempre debe haber un balance y si falta algo se completa con prebalance
      for(const e of sortedBalances) {
        if (count < 2 && e?.BalanceManualReporte?.Reporte?.tipo === 'prebalance') {
          balanceFinal.push(e);
          count++;
        }
      }
    }

    const fechaBalance: any[] = [];
    const ingresosBalance: any[] = [];
    const ebitdaBalance: any[] = [];
    const resultadoBalance: any[] = [];
    const activosBalance: any[] = [];
    const pasivosBalance: any[] = [];
    const patrimonioBalance: any[] = [];
    const existenciaBalance: any[] = [];
    const capitalBalance: any[] = [];
    const liquidezBalance: any[] = [];
    const leverageVBalance: any[] = [];
    const leverageBalance: any[] = [];
    const deudaBalance: any[] = [];
    const rentabilidadNetaBalance: any[] = [];
    const rentabilidadBalance: any[] = [];
    const permanenciaBalance: any[] = [];
    let monedaBalance: any = '';

    for(const elementBalance of balanceFinal){
      const periodo = elementBalance?.BalanceManualReporte?.Reporte?.periodo || '';
      const month = (elementBalance?.BalanceManualReporte?.Reporte?.mesFin || 1) - 1;
      const fechaBalancevalue = periodo ? this.getLastDayOfMonth(periodo, month) : '';
      
      monedaBalance = monedaBalance + (monedaBalance != '' ? ('-' + elementBalance?.BalanceManualReporte?.Reporte?.moneda || '') : (elementBalance?.BalanceManualReporte?.Reporte?.moneda || ''));

      fechaBalance.push(fechaBalancevalue);
      ingresosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser : '');
      ebitdaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda : '');
      resultadoBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida : '');
      activosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo : '');
      pasivosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo : '');
      patrimonioBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio : '');
      existenciaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac : '');
      capitalBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo : '');
      liquidezBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente : '');
      leverageVBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage : '');
      leverageBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos : '');
      deudaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos : '');
      rentabilidadNetaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos : '');
      rentabilidadBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos : '');
      permanenciaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar || 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar == 0 ? 
        elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar : '');
    }

    // fin Balance manual
    const dicom = this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
    const dicomM = dicom || dicom == 0 ? dicom / 1000 : '';
    this.razonSocial = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';

    const rango010 = deudaVencida?.deudaVencida && deudaVencida?.deudaVencida.length > 0 ? deudaVencida?.deudaVencida.find((e: any) => e.r11 === '00') : null;
    const rango1120 = deudaVencida?.deudaVencida && deudaVencida?.deudaVencida.length > 0 ? deudaVencida?.deudaVencida.find((e: any) => e.r11 === '11') : null;
    const comprasUltimos12Meses = this.obtenerComprasUltimos12Meses();
    const creditLimit = bpCredito?.bpCredito[0]?.creditLimit || bpCredito?.bpCredito[0]?.creditLimit == 0 ? bpCredito?.bpCredito[0]?.creditLimit : '';
    const totalFactM = Number(montoOcupado?.montoOcupado?.[0]?.tvenceDesde || 0) + Number(montoOcupado?.montoOcupado?.[0]?.tvenceEn || 0);
    const montoOcupadoM = Number(totalFactM || 0) + Number(montoOcupado?.montoOcupado?.[0]?.saldoPendiFact || 0) + Number(montoOcupado?.montoOcupado?.[0]?.saldoDocBlq || 0);
    const lcCiaSeguroM = ((montoAprobadoUF || montoAprobadoUF == 0) && this.valorUFActual > 0) ? (this.toMiles(Number(montoAprobadoUF || 0) * this.valorUFActual)) : '';
    const totalCobertura = Number(lcCiaSeguroM || 0) + Number(puntualesAprobados?.puntualesAprobados[0]?.amnt || 0);
    const ppp3Meses = this.obtenerPPP3M(plazoPromedio);

    this.caratulaPuntual = 
    [
      {
        body:[
          {text: 'VENCIMIENTO PUNTUAL', value: ['']}, // input
          {text: 'MONTO PUNTUAL M$', value: [0]}, // input
          {text: "LC CMT M$", value: [(creditLimit)]},
          {text: "LC CIA. DE SEGURO M$", value: [lcCiaSeguroM]},
          {text: "TEMPORAL M$", value: [puntualesAprobados?.puntualesAprobados[0] ? 
          (puntualesAprobados?.puntualesAprobados[0]?.amnt || puntualesAprobados?.puntualesAprobados[0]?.amnt == 0 ? puntualesAprobados?.puntualesAprobados[0]?.amnt : '') : '']},
          {text: "TOTAL COBERTURA M$", value: [totalCobertura]},
          {text: "MÁXIMO USO M$", value: [Number(creditLimit || 0)]},
          {text: "DESCALCE CON CIA. M$", value: [Number(creditLimit || 0) - Number(totalCobertura || 0)]} // (maximo uso + totalCobertura)
        ],
        listaValores: [],
        modal: false
      },
      {
        body:[
          {text: "TOTAL FACT. M$", value: [totalFactM]},
          {text: "SALDO PEND. FACT M$", value: [montoOcupado?.montoOcupado[0]?.saldoPendiFact || montoOcupado?.montoOcupado[0]?.saldoPendiFact == 0 ? montoOcupado?.montoOcupado[0]?.saldoPendiFact : '']},
          {text: "SALDO DOC. BLOQUEADOS M$", value: [montoOcupado?.montoOcupado[0]?.saldoDocBlq || montoOcupado?.montoOcupado[0]?.saldoDocBlq == 0 ? montoOcupado?.montoOcupado[0]?.saldoDocBlq : '']},
          {text: "MONTO OCUPADO M$", value: [montoOcupadoM]}, // TOTAL FACT. M$ + SALDO PEND. FACT M$ + SALDO DOC. BLOQUEADOS M$
          {text: "SEGMENTO", value: [bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.zdeSegment || '') : '']},
          {text: "ANTIGÜEDAD CMT", value: [bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.crdat || '') : '']},
          {text: "DICOM M$", value: [dicomM]},
          {text: "TIPO GARANTIA", value: [puntualesAprobados?.puntualesAprobados[0] ? (puntualesAprobados?.puntualesAprobados[0]?.text1 || '') : '']}
        ],
        listaValores: [],
        modal: false
      },
      {
        body:[
          {text: "FECHA BALANCE", value: fechaBalance},
          {text: `INGRESOS`, moneda: monedaBalance, value: ingresosBalance}, //`INGRESOS ${monedaBalance || 'M$'}`
          {text: `EBITDA`, moneda: monedaBalance, value: ebitdaBalance},
          {text: `RESULTADO EJERCICIO`, moneda: monedaBalance, value: resultadoBalance},
          {text: `ACTIVOS`, moneda: monedaBalance, value: activosBalance},
          {text: `PASIVOS`, moneda: monedaBalance, value: pasivosBalance},
          {text: `PATRIMONIO`, moneda: monedaBalance, value: patrimonioBalance},
          {text: `EXISTENCIA`, moneda: monedaBalance, value: existenciaBalance}
        ],
        listaValores: [],
        modal: true
      },
      {
        body:[
          {text: `CAPITAL DE TRABAJO`, moneda: monedaBalance, value: capitalBalance},
          {text: "LIQUIDEZ CORRIENTE (VECES)", value: liquidezBalance},
          {text: "LEVERAGE (VECES)", value: leverageVBalance},
          {text: "LEVERANGE S. ANTICIPO (VECES)", value: leverageBalance},
          {text: "DEUDA / INGRESO (%)", value: deudaBalance},
          {text: "RENTABILIDAD NETA (%)", value: rentabilidadNetaBalance},
          {text: "RENTABILIDAD OPERACIONAL %", value: rentabilidadBalance},
          {text: "PERMANENCIA CXP", value: permanenciaBalance}
        ],
        listaValores: [],
        modal: false
      },
      {
        body:
        [
          {text: "CON. DE VENTA", value: [bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.zterm || '') : '']},
          {text: "PPP", value: [ppp3Meses]},
          {text: "DSO", value: [bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.idxFigure || '') : '']},
          {text: "VENCIDO 0 - 10 DIAS M$", value: [rango010?.vend || rango010?.vend == 0 ? rango010?.vend : '']},
          {text: "VENCIDO 11 - 20 DIAS M$", value: [rango1120?.vend || rango1120?.vend == 0 ? rango1120?.vend : '']},
          {text: "COMPRAS ULTIMOS 12 MESES M$", value: [comprasUltimos12Meses]},
          {text: "COMPROMISO DE PAGO M$", value: [0]}, // input
          {text: "FECHA COMPROMISO", value: ['']} // input
        ],
        listaValores: [],
        modal: false
      }
    ];
    this.generarListaValores(this.caratulaPuntual);
    this.generarCaraturaPuntualInput();

    // Segunda parte del mapeo - separar esta parte en otra funcion

  }
  
  mapeosCaratulaPuntualPart2(): void {
    this.caratulaPuntualPart2 = [];
    if(this.reportesDerivadosObj?.garantes && Object.keys(this.reportesDerivadosObj?.garantes).length > 0) {
      for(const [index, element] of Object.entries(this.reportesDerivadosObj?.garantes)) {
        this.rutGarante.push(index || '');
        this.razonSocialGarante.push((element as any)?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '');

        const listadoBalances: any = [];
        if((element as any)?.balanceManual?.reporte?.listadoBalances && (element as any)?.balanceManual?.reporte?.listadoBalances.length > 0) {
          listadoBalances.push(...(element as any)?.balanceManual?.reporte?.listadoBalances);
        }

        if((element as any)?.preBalanceManual?.reporte?.listadoPreBalances && (element as any)?.preBalanceManual?.reporte?.listadoPreBalances.length > 0) {
          listadoBalances.push(...(element as any)?.preBalanceManual?.reporte?.listadoPreBalances);
        }

        let sortedBalances = [];

        if(listadoBalances && listadoBalances.length > 0){
          sortedBalances = listadoBalances.sort((a: any, b:any) => {
            const periodoA = a?.BalanceManualReporte?.Reporte?.periodo || '';
            const periodoB = b?.BalanceManualReporte?.Reporte?.periodo || '';
            const mesInicioA = a?.BalanceManualReporte?.Reporte?.mesInicio || 0;
            const mesInicioB = b?.BalanceManualReporte?.Reporte?.mesInicio || 0;
            const mesFinA = a?.BalanceManualReporte?.Reporte?.mesFin || 0;
            const mesFinB = b?.BalanceManualReporte?.Reporte?.mesFin || 0;
      
            if (periodoA === '' && periodoB === '') {
              return 0;
            } else if (periodoA === '') {
              return 1;
            } else if (periodoB === '') {
              return -1;
            }
      
            if (periodoA !== periodoB) {
              return parseInt(periodoB) - parseInt(periodoA);
            }
      
            if (mesInicioA === 1 && mesFinA === 12) {
              return -1;
            } else if (mesInicioB === 1 && mesFinB === 12) {
              return 1;
            }
      
            return mesFinB - mesFinA;
          });
        }
        
        const balanceFinal: any[] = [];
        let count = 0;

        for(const e of sortedBalances) {
          if (count < 2 && e?.BalanceManualReporte?.Reporte?.estado === 'closed' && e?.BalanceManualReporte?.Reporte?.tipo === 'balance') {
            balanceFinal.push(e);
            count++;
          }
        }
        if (balanceFinal.length > 0 && balanceFinal.length < 2) { // siempre debe haber un balance y si falta algo se completa con prebalance
          for(const e of sortedBalances) {
            if (count < 2 && e?.BalanceManualReporte?.Reporte?.tipo === 'prebalance') {
              balanceFinal.push(e);
              count++;
            }
          }
        }

        const fechaBalance: any[] = [];
        const ingresosBalance: any[] = [];
        const ebitdaBalance: any[] = [];
        const resultadoBalance: any[] = [];
        const activosBalance: any[] = [];
        const pasivosBalance: any[] = [];
        const patrimonioBalance: any[] = [];
        const existenciaBalance: any[] = [];
        const permanenciaBalance: any[] = [];
        const capitalBalance: any[] = [];
        const liquidezBalance: any[] = [];
        const leverageVBalance: any[] = [];
        const leverageBalance: any[] = [];
        const deudaBalance: any[] = [];
        const rentabilidadNetaBalance: any[] = [];
        const rentabilidadBalance: any[] = [];
        let monedaBalance: any = '';
        
        for(const elementBalance of balanceFinal) {
          const periodo = elementBalance?.BalanceManualReporte?.Reporte?.periodo || '';
          const month = (elementBalance?.BalanceManualReporte?.Reporte?.mesFin || 1) - 1;
          const fechaBalancevalue = periodo ? this.getLastDayOfMonth(periodo, month) : '';
          monedaBalance = monedaBalance + (monedaBalance != '' ? ('-' + elementBalance?.BalanceManualReporte?.Reporte?.moneda || '') : (elementBalance?.BalanceManualReporte?.Reporte?.moneda || ''));

          fechaBalance.push(fechaBalancevalue);
          ingresosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser : '');
          ebitdaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.ebitda : '');
          resultadoBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.gananciaperdida : '');
          activosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.activo : '');
          pasivosBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.pasivo : '');
          patrimonioBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.patrimonio : '');
          existenciaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.inventariosac : '');
          permanenciaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.permanenciactaxpagar : '');
          capitalBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.capitaldetrabajo : '');
          liquidezBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.razoncorriente : '');
          leverageVBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.leverage : '');
          leverageBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.leveragesinanticipos : '');
          deudaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.deudaingresos : '');
          rentabilidadNetaBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.rentabilidadingresos : '');
          rentabilidadBalance.push(elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos || 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos == 0 ? 
            elementBalance?.BalanceManualReporte?.Reporte?.balance?.resultadooperacionalporcentajedelosingresos : '');
        }

        const objectArray = [
          {
            body: [
              {text: "FECHA BALANCE", value: fechaBalance},
              {text: `INGRESOS`, moneda: monedaBalance, value: ingresosBalance},
              {text: `EBITDA`, moneda: monedaBalance, value: ebitdaBalance},
              {text: `RESULTADO EJERCICIO`, moneda: monedaBalance, value: resultadoBalance},
              {text: `ACTIVOS`, moneda: monedaBalance, value: activosBalance},
              {text: `PASIVOS`, moneda: monedaBalance, value: pasivosBalance},
              {text: `PATRIMONIO`, moneda: monedaBalance, value: patrimonioBalance},
              {text: `EXISTENCIA`, moneda: monedaBalance, value: existenciaBalance}
            ],
            listaValores: [],
            modal: false
          },
          {
            body: [
              {text: "PERMANENCIA CXP", value: permanenciaBalance},
              {text: `CAPITAL DE TRABAJO`, moneda: monedaBalance, value: capitalBalance},
              {text: "LIQUIDEZ CORRIENTE (VECES)", value: liquidezBalance},
              {text: "LEVERAGE (VECES)", value: leverageVBalance},
              {text: "LEVERANGE S. ANTICIPO (VECES)", value: leverageBalance},
              {text: "DEUDA / INGRESO (%)", value: deudaBalance},
              {text: "RENTABILIDAD NETA (%)", value: rentabilidadNetaBalance},
              {text: "RENTABILIDAD OPERACIONAL %", value: rentabilidadBalance}
            ],
            listaValores: [],
            modal: false
          }
        ];

        this.caratulaPuntualPart2.push(objectArray);
      }
    }
    this.generarListaValoresArray(this.caratulaPuntualPart2);
  }

  mapeoResolucionSeguro(): void {
    this.rutResolucion = this.rut || '';
    this.razonSocialResolucion = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';
    this.resolucionSeguro = [];
    // Parte continental
    const lineaNominadaContinental = this.getLineaContinental();
    if(lineaNominadaContinental){
      const object = new Object() as ResolucionSeguro;
      object.companiaSeguro = lineaNominadaContinental ? 'Continental' : '';
      object.idNacional = lineaNominadaContinental?.IdNacion || '';
      object.cliente = lineaNominadaContinental?.Nombre || '';
      object.vigenciaDesde = lineaNominadaContinental?.FechaDesde || '';
      object.vigenciaHasta = lineaNominadaContinental?.FechaHasta || '';
      object.fechaResolucion = lineaNominadaContinental?.FechaResolucion || '';
      object.montoSolicitado = lineaNominadaContinental?.MtoSolicitado || lineaNominadaContinental?.MtoSolicitado == 0 ? lineaNominadaContinental?.MtoSolicitado : '';
      object.montoAprobado = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
      object.estado = lineaNominadaContinental?.Estado || '';
      object.condicionVenta = lineaNominadaContinental?.CondicionVenta || '';
      object.observacion = lineaNominadaContinental?.Observacion || '';

        this.resolucionSeguro.push(object);
    }
    
    // Parte Avla
    const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);
    if(lineaAvla){
      const object = new Object() as ResolucionSeguro;
      
      let idNacional = lineaAvla?.debtorDto?.identityField || '';
      if(idNacional) { // solo funcional para rut chileno
        const lastChar = idNacional[idNacional.length - 1];
        const rutCut = idNacional.substring(0, idNacional.length - 1);
        idNacional = rutCut.replace(/\D/g, '') + '-' + lastChar;
      }
      let observacion = '';
      if(lineaAvla?.comments && lineaAvla?.comments.length > 0) {
        observacion = lineaAvla?.comments.map((comentario: any) => comentario?.comments?.commentsToEnsured).join(' | ');
      }

      object.companiaSeguro = lineaAvla ? 'Avla' : '';
      object.idNacional = idNacional || '';
      object.cliente = lineaAvla?.debtorDto?.completeName || '';
      object.vigenciaDesde = lineaAvla?.feStartOfValidityStr || '';
      object.vigenciaHasta = lineaAvla?.feEndOfValidityStr || '';
      object.fechaResolucion = lineaAvla?.createDateStr || '';
      object.montoSolicitado = lineaAvla?.requestedAmount || lineaAvla?.requestedAmount == 0 ? lineaAvla?.requestedAmount : '';
      object.montoAprobado = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
      object.estado = lineaAvla?.stateDto?.name || '';
      object.condicionVenta = '';
      object.observacion = observacion || '';

      this.resolucionSeguro.push(object);
    }
  }

  obtenerNombreMes(numeroMes: number): string {
    const meses = [
        'ENERO',
        'FEBRERO',
        'MARZO',
        'ABRIL',
        'MAYO',
        'JUNIO',
        'JULIO',
        'AGOSTO',
        'SEPTIEMBRE',
        'OCTUBRE',
        'NOVIEMBRE',
        'DICIEMBRE'
    ];

    if (numeroMes < 1 || numeroMes > 12) {
      return ''
    }

    return meses[numeroMes - 1];
  }

  mapeoVolumenNegocio(): void {
    this.rutVolumenNegocio = this.rut || '';
    this.razonSocialVolumenNegocio = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';

    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    let compraUltima = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.compraUltima) : null;
    const anioActual = new Date().getFullYear();
    const anioAnterior1 = anioActual - 1;
    const anioAnterior2 = anioActual - 2;

    compraUltima = compraUltima?.compraUltima || [];

    this.volumenNegocio = new Object() as VolumenNegocio;
    this.volumenNegocio.anios = [anioAnterior2, anioAnterior1, anioActual];
    this.volumenNegocio.meses = [];
    this.volumenNegocio.totales = [0,0,0];
    this.volumenNegocio.promedios = [0,0,0];

    for(let i = 1; i <= 12; i++) {
      const objectMes = new Object() as VolumenMes;
      objectMes.mes = this.obtenerNombreMes(i);
      objectMes.montos = [];

      for(const anio of [anioAnterior2, anioAnterior1, anioActual]) {
        const mes = compraUltima.find((e: any) => e.gjahr === anio && e.monat === i);
        if(mes) {
          objectMes.montos.push(mes?.dmbtr || mes?.dmbtr == 0 ? Number(mes?.dmbtr || 0) : '');
        } else {
          objectMes.montos.push('');
        }
      }
      this.volumenNegocio.meses.push(objectMes);
    }

    for(const mes of this.volumenNegocio.meses) {
      this.volumenNegocio.totales[0] += Number(mes.montos[0] || 0);
      this.volumenNegocio.totales[1] += Number(mes.montos[1] || 0);
      this.volumenNegocio.totales[2] += Number(mes.montos[2] || 0);
    }

    this.volumenNegocio.promedios[0] = this.volumenNegocio.totales[0] / 12;
    this.volumenNegocio.promedios[1] = this.volumenNegocio.totales[1] / 12;
    this.volumenNegocio.promedios[2] = this.volumenNegocio.totales[2] / 12;
  }

  mapeoAnalisisDeclaracionIva(anioActualVentas: number, anioAnteriorVentas: number, F29Limpio: any[]): void {
    this.rutAnalisisDeclaracionIva = this.rut || '';
    this.razonSocialAnalisisDeclaracionIva = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';

    const { totalesInformacionFinanciera, informacionFinancieraTable } = this.utilsService.crearInformacionFinancieraTable(anioActualVentas, anioAnteriorVentas, F29Limpio);
    this.totalesInformacionFinanciera = totalesInformacionFinanciera;
    this.informacionFinancieraTable = informacionFinancieraTable;
  }

  mapeoReportes(): void {
    this.llenadoBase([
      'caratulaPuntual',
      'comentarios',
      'volumentNegocio',
      'analisisDeclaracionIva'
    ]); // llenado base sin datos

    /* INICIO Logica de obtencion de datos de F22 y F29 */
    const F22Limpio = this.obtenerDatosF22(); // Funcion donde hace la logica

    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })
    /* FIN Logica de obtencion de datos de F22 y F29 */


    this.mapeoTablaResumenSaldo();
    this.mapeoCaratulaPuntual();
    this.mapeosCaratulaPuntualPart2();
    this.mapeoResolucionSeguro();
    this.mapeoVolumenNegocio();
    
    const anioActualVentas = new Date().getFullYear();
    const anioAnteriorVentas = Number(anioActualVentas - 1);
    this.mapeoAnalisisDeclaracionIva(anioActualVentas, anioAnteriorVentas, F29Limpio);

    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.showReportes = true;
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  validaMaterialesMargenes(): boolean {
    let valido: boolean = false;
    for(const [index, elemento] of this.materialesMargenes?.body.entries()) { // usar index para indicar cual fila tiene error
      
      for (const property in elemento) {
        if(property === 'pedidoCotizacionOc' || property === 'distribucion' || property === 'producto' || property === 'proveedor'){

          if (elemento[property] && typeof elemento[property] === 'string' && elemento[property].trim().length > 0) {
            if (this.listValidatorsIds.includes('materiales-' + property + index)) {
              const position = this.listValidatorsIds.indexOf('materiales-' + property + index);
              this.listValidatorsIds.splice(position, 1);
            }
            valido =  true;
          } else {
            if (!this.listValidatorsIds.includes('materiales-' + property + index)) {
              this.listValidatorsIds.push('materiales-' + property + index);
            }
            return false;
          }
        }

        if(property === 'montoM' || property === 'mgPorc' || property === 'contribucionM'){

          if(typeof elemento[property] === 'number' && !isNaN(elemento[property])) {

            if (this.listValidatorsIds.includes('materiales-' + property + index)) {
              const position = this.listValidatorsIds.indexOf('materiales-' + property + index);
              this.listValidatorsIds.splice(position, 1);
            }
            if(property === 'mgPorc') { // se valida dato ingresado que corresponda con formula
              const valorCorrecto = Number(elemento['montoM']) !== 0 ? 
              Number(elemento['contribucionM']) / Number(elemento['montoM']) * 100 : 0;

              if(elemento[property] !== valorCorrecto){
                if (!this.listValidatorsIds.includes('materiales-' + property + index)) {
                  this.listValidatorsIds.push('materiales-' + property + index);
                }
                return false;
              }
            }
            valido =  true;
          } else {
            if (!this.listValidatorsIds.includes('materiales-' + property + index)) {
              this.listValidatorsIds.push('materiales-' + property + index);
            }
            return false;
          }
        }

      }     
      
    }

    return valido;

  }

  validaComentarios(): boolean {
    let valido: boolean = false;
    for(const comentario of this.comentarios) {
      if(comentario.id === 'a' || comentario.id === 'b') { // solo se validan los dos primeros comentarios
        const elementoHTML = document.getElementById('comentarios-' + comentario?.id);
  
        if(comentario?.comentario && typeof comentario?.comentario === 'string' && comentario?.comentario.trim().length > 0){
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className.replace(" error-input", "");
            elementoHTML.title = '';
          }
          valido = true;
        } else {
          if(elementoHTML){
            elementoHTML.className = !elementoHTML.className.includes("error-input") ? elementoHTML.className + " error-input" : elementoHTML.className;
            elementoHTML.title = 'Error - Ingrese un comentario';
          }
          return false;
        }
      }

      // validar si el comentario no esta vacio
      if(comentario?.comentario && typeof comentario?.comentario === 'string' && comentario?.comentario.trim().length > 0){
        // validar si existe comentario en ficha guardada
        const comentarioGuardado = this.fichaGuardada?.ficha?.comentarios?.find((element: any) => element.id === comentario.id);
        // si no existe comentario, se debe guardar el autor y la fecha de creacion
        if(!comentarioGuardado){
          // guardar comentario
          comentario.autor = this._sessionService.getUserInfo().username; // validar
          comentario.fecha = new Date().toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-'); // formato DD-MM-YYYY HH:mm:ss
          
          
        } else {
          // validar si los comentarios son iguales a los de ficha guardada
          if(comentario?.comentario !== comentarioGuardado?.comentario){
            // si son diferentes, se debe guardar el autor y la fecha de modificacion
            comentario.autor = this._sessionService.getUserInfo().username; // validar
            comentario.fecha = new Date().toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-'); // formato DD-MM-YYYY HH:mm:ss
          }
  
        }
      }

    }
    return valido;
  }

  validarCaratulaPuntual(): boolean {
    let valido: boolean = false;
    const objectNumber = this.caratulaPuntualInput.number;
    const objectDate = this.caratulaPuntualInput.date;

    if(objectNumber && Object.keys(objectNumber).length > 0) {
      for (const index in objectNumber) {
        const [i, indiceValores, j] = index.split('-');
        const value = Number(objectNumber[index]);
        if(typeof value === 'number' && !isNaN(value)) {
          if (this.listValidatorsIds.includes('caratulaPuntual' + i + indiceValores + j)) {
            const position = this.listValidatorsIds.indexOf('caratulaPuntual' + i + indiceValores + j);
            this.listValidatorsIds.splice(position, 1);
          }
          valido = true;
        } else {
          if (!this.listValidatorsIds.includes('caratulaPuntual' + i + indiceValores + j)) {
            this.listValidatorsIds.push('caratulaPuntual' + i + indiceValores + j);
          }
          return false;
        }

        this.caratulaPuntual[Number(i)].listaValores[Number(indiceValores)][Number(j)] = value;
        // Use the value and index as needed
      }
    }

    if(objectDate && Object.keys(objectDate).length > 0) {
      for (const index in objectDate) {
        const [i, indiceValores, j] = index.split('-');
        const value = objectDate[index];
        if(value) {
          if (this.listValidatorsIds.includes('caratulaPuntual' + i + indiceValores + j)) {
            const position = this.listValidatorsIds.indexOf('caratulaPuntual' + i + indiceValores + j);
            this.listValidatorsIds.splice(position, 1);
          }
          valido = true;
        } else {
          if (!this.listValidatorsIds.includes('caratulaPuntual' + i + indiceValores + j)) {
            this.listValidatorsIds.push('caratulaPuntual' + i + indiceValores + j);
          }
          return false;
        }

        this.caratulaPuntual[Number(i)].listaValores[Number(indiceValores)][Number(j)] = value;
        // Use the value and index as needed
      }
    }

    return valido;
  }

  validaListaIds(id: string): boolean {
    return this.listValidatorsIds.includes(id);
  }

  guardarFicha(): void {
    this.listValidatorsIds = [];

    if(!(this.valorUFActual > 0)){
      this.alertService.error('Solicitud de Crédito - No se logró recuperar un valor válido de la uf.');
      return;
    }

    if(!this.validarCaratulaPuntual()) {
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en las filas de la tabla caratula puntual.');
      return;
    }

    if(!this.validaMaterialesMargenes()) {
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en las filas de la tabla materiales y margenes.');
      return;
    }

    // falta limpiar mensajes de error de comentarios
    if(!this.validaComentarios()) {
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en los comentarios');
      return;
    }

    this.crearLineaOperacionPropuesta();

    const ficha: any = {
      ficha: {
        rut: this.rut,
        consolidadoTitular: this.consolidadoTitular,
        razonSocial: this.razonSocial,
        caratulaPuntual: this.caratulaPuntual,
        tablaResumenSaldo: this.tablaResumenSaldo,
        rutGarante: this.rutGarante,
        razonSocialGarante: this.razonSocialGarante,
        caratulaPuntualPart2: this.caratulaPuntualPart2,
        materialesMargenes: this.materialesMargenes,
        comentarios: this.comentarios,
        rutResolucion: this.rutResolucion,
        razonSocialResolucion: this.razonSocialResolucion,
        resolucionSeguro: this.resolucionSeguro,
        rutVolumenNegocio: this.rutVolumenNegocio,
        razonSocialVolumenNegocio: this.razonSocialVolumenNegocio,
        volumenNegocio: this.volumenNegocio,
        rutAnalisisDeclaracionIva: this.rutAnalisisDeclaracionIva,
        razonSocialAnalisisDeclaracionIva: this.razonSocialAnalisisDeclaracionIva,
        caratulaPuntualInput: this.caratulaPuntualInput,
        totalesInformacionFinanciera: this.totalesInformacionFinanciera,
        informacionFinancieraTable: this.informacionFinancieraTable,
        valorUFActual: this.valorUFActual,
        lineaOperacionPropuesta: this.lineaOperacionPropuesta,
        lineaOperacionPropuestaTotales: this.lineaOperacionPropuestaTotales,
        lineaOperacionPropuestaTipoFecha: ['fechaPuntual'] // depende de cada ficha, modelo y organizacion
      }
    }

    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));

  }

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  continuar(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[9]?.optional === false){
        if(this.bodyModelos?.stages[9]?.completed){
          
          // Llamada a servicio para inciar aprobacion
          this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            const error = resp?.ServicioAprobacional?.errors || null;
            if(error?.hassError === true){
              this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              this.spinner.hide();
            } else {
              this.solicitudCreditoService.setStep('next');
              this.bloqueaPasos6.emit(true);
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        // Llamada a servicio para inciar aprobacion
        this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
          const error = resp?.ServicioAprobacional?.errors || null;
          if(error?.hassError === true){
            this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
            this.spinner.hide();
          } else {
            this.solicitudCreditoService.setStep('next');
            this.bloqueaPasos6.emit(true);
            this.spinner.hide();
          }
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
          this.spinner.hide();
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  generarListaValores(items: any[]): void {
    for(const item of items) {
      item.listaValores = this.obtenerListaValores(item.body);
    }
  }

  generarListaValoresArray(items: any[]): void {
    for(const item of items) {
      for(const subItem of item) {
        subItem.listaValores = this.obtenerListaValores(subItem.body);
      }
    }
  }

  obtenerListaValores(item: any[]): any[] {
    const maxLength = Math.max(...item.map(element => element.value.length));
    const result = [];

    for (let i = 0; i < maxLength; i++) {
      const values = item.map(element => element.value[i] || element.value[i] == 0 ? element.value[i] : '');
      result.push(values);
    }

    return result;
  }

  obtenerElementoAnioActual(mes: string, key: string): any {
    const elemento = this.analisisDeclaracionIva?.anioActual.meses.find(element => element.mes === mes);
    return elemento ? elemento[key as keyof AnalisisDeclaracionIvaMes] : '';
  }

  abrirModalTitular(modal: boolean): void{
    if(modal){
      if(this.reportesPrincipalesObj){
        this.mapeoReporteTitular();
        this.mapeoPoderJudicial();
        this.mapeoSbif();
        this.validaObjetos();
      }
  
      this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
    
      this.rutSeleccionado = this.rut;
      this.plantillaAntecedentes = 'cesceDemo';
      this.modalDefault = false;
  
      this.modalService.open();
    }
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 

  }

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  mapeoVehiculos(): void{
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  validateArray(array: any): boolean {
    if(array && array.length > 0){
      return true;
    }
    return false;
  }

  addRowCargaFinanciera(): void {
    if(this.materialesMargenes?.body && this.materialesMargenes?.body.length < 30)
      this.materialesMargenes?.body.push({
        pedidoCotizacionOc: '',
        distribucion: '',
        producto: '',
        proveedor: '',
        montoM: 0,
        mgPorc: 0,
        contribucionM: 0
      });
  }

  deleteRowCargaFinanciera(index: number): void {
    if(this.materialesMargenes && this.materialesMargenes?.body && this.materialesMargenes?.body.length > 0){
      this.materialesMargenes?.body.splice(index, 1);
    }
  }

  absoluteIndex(indexOnPage: number): number {
    return this.currentItem * (this.currentPage - 1) + indexOnPage;
  }

  toMiles(value: any): string | number {
    if(value || value == 0) {
      return Math.round(Number(value) / 1000);
    }
    return '';
  }

  llenadoBase(elementos: string[]): void {
    if(elementos.includes('caratulaPuntual'))
      this.llenadoCaraturaPuntual();

    if(elementos.includes('comentarios'))
      this.llenadoComentarios();

    if(elementos.includes('volumentNegocio'))
      this.llenadoVolNegocio();

    if(elementos.includes('analisisDeclaracionIva'))
      this.llenadoAnalisisDeclaracionIva();
  }

  generarCaraturaPuntualInput(): void {
    if (!this.caratulaPuntualInput || Object.keys(this.caratulaPuntualInput).length === 0) {
      this.caratulaPuntualInput = {
        number: {},
        date: {}
      };
    }

    for (const [i, item] of this.caratulaPuntual.entries()) {
      for (const [indiceValores, element] of item.listaValores.entries()) {
        for (const [j, itemElement] of element.entries()) {
          const text: any = this.caratulaPuntual[i].body[j].text;

          if (text) {
            const indice = i.toString() + '-' + indiceValores.toString() + '-' + j.toString();
            if (text === 'MONTO PUNTUAL M$' || text === 'COMPROMISO DE PAGO M$') {
              this.caratulaPuntualInput.number[indice] = itemElement;
            } else if (text === 'VENCIMIENTO PUNTUAL' || text === 'FECHA COMPROMISO') {
              this.caratulaPuntualInput.date[indice] = itemElement;
            }
          }
        }
      }
    }
  }

  llenadoCaraturaPuntual(): void {
    this.razonSocial = 'SISTEMAS Y APLICACIONES SPA';
    this.caratulaPuntual = 
    [
      {
        body:[
          {text: 'VENCIMIENTO PUNTUAL', value: ['']},
          {text: 'MONTO PUNTUAL M$', value: [0]},
          {text: "LC CMT M$", value: ['']},
          {text: "LC CIA. DE SEGURO M$", value: ['']},
          {text: "TEMPORAL M$", value: ['']},
          {text: "TOTAL COBERTURA M$", value: ['']},
          {text: "MÁXIMO USO M$", value: ['']},
          {text: "DESCALCE CON CIA. M$", value: ['']}
        ],
        listaValores: [],
        modal: false
      },
      {
        body:[
          {text: "TOTAL FACT. M$", value: ['']},
          {text: "SALDO PEND. FACT M$", value: ['']},
          {text: "SALDO DOC. BLOQUEADOS M$", value: ['']},
          {text: "MONTO OCUPADO M$", value: ['']},
          {text: "SEGMENTO", value: ['']},
          {text: "ANTIGÜEDAD CMT", value: ['']},
          {text: "DICOM M$", value: ['']},
          {text: "TIPO GARANTIA", value: ['']}
        ],
        listaValores: [],
        modal: false
      },
      {
        body:[
          {text: "FECHA BALANCE", value: ['']},
          {text: "INGRESOS M$", value: ['']},
          {text: "EBITDA M$", value: ['']},
          {text: "RESULTADO EJERCICIO M$", value: ['']},
          {text: "ACTIVOS M$", value: ['']},
          {text: "PASIVOS M$", value: ['']},
          {text: "PATRIMONIO M$", value: ['']},
          {text: "EXISTENCIA M$", value: ['']}
        ],
        listaValores: [],
        modal: true
      },
      {
        body:[
          {text: "CAPITAL DE TRABAJO M$", value: ['']},
          {text: "LIQUIDEZ CORRIENTE (VECES)", value: ['']},
          {text: "LEVERAGE (VECES)", value: ['']},
          {text: "LEVERANGE S. ANTICIPO (VECES)", value: ['']},
          {text: "DEUDA / INGRESO (%)", value: ['']},
          {text: "RENTABILIDAD NETA (%)", value: ['']},
          {text: "RENTABILIDAD OPERACIONAL %", value: ['']},
          {text: "PERMANENCIA CXP", value: ['']}
        ],
        listaValores: [],
        modal: false
      },
      {
        body:
        [
          {text: "CON. DE VENTA", value: ['']},
          {text: "PPP", value: ['']},
          {text: "DSO", value: ['']},
          {text: "VENCIDO 0 - 10 DIAS M$", value: ['']},
          {text: "VENCIDO 11 - 20 DIAS M$", value: ['']},
          {text: "COMPRAS ULTIMOS 12 MESES M$", value: ['']},
          {text: "COMPROMISO DE PAGO M$", value: [0]},
          {text: "FECHA COMPROMISO", value: ['']}
        ],
        listaValores: [],
        modal: false
      }
    ];
    this.generarListaValores(this.caratulaPuntual);
    this.generarCaraturaPuntualInput();
  }

  llenadoComentarios(): void {
    this.comentarios = [
      {
        title: 'a. Comentarios Área de Venta (vendedor – Jefe)',
        comentario: '',
        id: 'a'
      },
      {
        title: 'b. Comentarios Jefe de Crédito y Cobranza',
        comentario: '',
        id: 'b'
      },
      {
        title: 'c. Comentarios Subgerente de Crédito y Cobranza',
        comentario: '',
        id: 'c'
      },
      {
        title: 'd. Comentarios Gerente Zonal',
        comentario: '',
        id: 'd'
      },
      {
        title: 'e. Comentario Gerente Comercial',
        comentario: '',
        id: 'e'
      },
      {
        title: 'f. Comentarios Gerente de Administración y Finanzas',
        comentario: '',
        id: 'f'
      },
      {
        title: 'g. Comentarios Gerente General',
        comentario: '',
        id: 'g'
      },
      {
        title: 'h. Comentarios Director 1',
        comentario: '',
        id: 'h'
      },
      {
        title: 'i. Comentarios Director 2',
        comentario: '',
        id: 'i'
      },
      {
        title: 'j. Comentarios Director 3',
        comentario: '',
        id: 'j'
      }
    ];
  }

  llenadoVolNegocio(): void {
    this.volumenNegocio = {
      anios: [],
      meses: [
        {
          mes: 'ENERO',
          montos: []
        },
        {
          mes: 'FEBRERO',
          montos: []
        },
        {
          mes: 'MARZO',
          montos: []
        },
        {
          mes: 'ABRIL',
          montos: []
        },
        {
          mes: 'MAYO',
          montos: []
        },
        {
          mes: 'JUNIO',
          montos: []
        },
        {
          mes: 'JULIO',
          montos: []
        },
        {
          mes: 'AGOSTO',
          montos: []
        },
        {
          mes: 'SEPTIEMBRE',
          montos: []
        },
        {
          mes: 'OCTUBRE',
          montos: []
        },
        {
          mes: 'NOVIEMBRE',
          montos: []
        },
        {
          mes: 'DICEMBRE',
          montos: []
        }
      ],
      totales: [],
      promedios: []
    };
  }

  llenadoAnalisisDeclaracionIva(): void {
    const date: any = new Date();
    const year = date.getFullYear();
    const yearAnterior = year - 1;

    this.analisisDeclaracionIva = {
      anios: [yearAnterior, year],
      anioActual: {
        meses: [
          {
            mes: 'ENERO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'FEBRERO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'MARZO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'ABRIL',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'MAYO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'JUNIO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'JULIO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'AGOSTO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'SEPTIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'OCTUBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'NOVIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'DICIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          }
        ],
        totales: [],
        promedios: []
      },
      anioAnterior: {
        meses: [
          {
            mes: 'ENERO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'FEBRERO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'MARZO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'ABRIL',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'MAYO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'JUNIO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'JULIO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'AGOSTO',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'SEPTIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'OCTUBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'NOVIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          },
          {
            mes: 'DICIEMBRE',
            ventaM: '',
            compraM: '',
            margenM: ''
          }
        ],
        totales: [],
        promedios: []
      }
    };
  }

  calcularTotal(id: string): void {
    if(id === 'montoM' || id === 'contribucionM') {
      let total = 0;
      for(const item of this.materialesMargenes?.body) {
        total += Number(item[id]);
      }
      this.materialesMargenes.totales[id] = total;
      this.materialesMargenes.totales['mgPorc'] = Number(this.materialesMargenes.totales['montoM']) !== 0 ?
      (Number(this.materialesMargenes.totales['contribucionM']) / Number(this.materialesMargenes.totales['montoM'])) * 100 : 
      0;
    }
  }

  validaTipoFormulario(indice1: number, indice2: number): string {
    const text: any = this.caratulaPuntual[indice1].body[indice2].text;
    if(text){
      if(text === 'VENCIMIENTO PUNTUAL' || text === 'FECHA COMPROMISO')
        return 'date';
      if(text === 'MONTO PUNTUAL M$' || text === 'COMPROMISO DE PAGO M$')
        return 'number';
    }
    return '';
  }

  obtenerIndiceString(indice1: number, indice2: number, indice3: number): string {
    return indice1.toString() + '-' + indice2.toString() + '-' + indice3.toString();
  }

  validaText(id: string): boolean {
    if(id) {
      if(this.text.includes(id))
        return true;
    }
    return false;
  }

  validaDate(id: string): boolean {
    if(id) {
      if(this.date.includes(id))
        return true;
    }
    return false;
  }

  agregarSaltosDeLinea(texto: string): string {
    return texto.replace(new RegExp('\n', 'g'), '<br />');
  }

  crearLineaOperacionPropuesta(): void {
    let [montoPuntual, fechaPuntual] = '';
    const indexFecha = this.caratulaPuntual?.[0]?.body?.findIndex((e: any) => e.text === 'VENCIMIENTO PUNTUAL');
    const indexMonto = this.caratulaPuntual?.[0]?.body?.findIndex((e: any) => e.text === 'MONTO PUNTUAL M$');

    if(indexFecha >= 0) {
      fechaPuntual = this.caratulaPuntual?.[0]?.listaValores?.[0]?.[indexFecha] || '';
    }
    if(indexMonto >= 0) {
      montoPuntual = this.caratulaPuntual?.[0]?.listaValores?.[0]?.[indexMonto] || '';
    }

    this.lineaOperacionPropuesta = {
      producto:[{
          id: 'montoPuntual',
          texto: 'Monto Puntual'
        },
        {
          id: 'fechaPuntual',
          texto: 'Fecha Puntual'
        }
      ],
      lineaPropuesta: {
        montoPuntual: montoPuntual,
        fechaPuntual: fechaPuntual
      }
    };

    this.lineaOperacionPropuestaTotales = {
      totalesLineaPropuesta: montoPuntual,
    }
  }

}
