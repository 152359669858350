<div [ngClass]="{'container-gtp': showHeader}">
  <ng-container *ngIf="showHeader">
    <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
    <h2 class="text-center">REPORTE VEHICULOS</h2>
    <h5 class="text-center"><span class="badge bg-secondary text-center">
        {{content.vehiculoReporte.datosBasicosSolicitud.Rut | number:
        '1.0-0'}}-{{content.vehiculoReporte.datosBasicosSolicitud.DV | uppercase}}
      </span></h5>
  </ng-container>

  <div class="card textos-campo mb-5">
    <h5 class="card-header list-group-item cuadro-color text-uppercase"><i class="fa fa-info-circle"
        style="font-size:28px;">&nbsp;</i>Información Básica <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content.vehiculoReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
    <div class="card-body">

      <table class="table table-hover">
        <tbody>
          <tr>
            <th>
              Rut
            </th>
            <td>
              {{content.vehiculoReporte.datosBasicosSolicitud.Rut | number:
              '1.0-0'}}-{{content.vehiculoReporte.datosBasicosSolicitud.DV | uppercase}}
            </td>
          </tr>
          <tr>
            <th>
              Nombre
            </th>
            <td>
              <ng-container *ngIf="content?.vehiculoReporte?.vehiculos?.listadoVehiculos.length > 0">
                {{content.vehiculoReporte.vehiculos.listadoVehiculos[0].propietario}}
              </ng-container>
            </td>
          </tr>
          <tr>
            <th>
              Número Total Vehículos
            </th>
            <td>
              <ng-template #sinInfo>
                0
              </ng-template>
              <ng-container *ngIf="content?.vehiculoReporte?.vehiculos?.listadoVehiculos.length > 0; else sinInfo">
                {{content.vehiculoReporte.vehiculos.listadoVehiculos.length | number: '1.0-0'}}
              </ng-container>
            </td>
          </tr>
          <tr>
            <th>
              Valor Total Vehículos
            </th>
            <td>
              <ng-template #sinInfo>
                0
              </ng-template>
              <ng-container *ngIf="content?.vehiculoReporte?.vehiculos?.listadoVehiculos.length > 0; else sinInfo">
                M$ {{((content.vehiculoReporte.vehiculos.listadoVehiculos | sumTd: 'tasacion') /1000) | number :
                '1.0-0'}}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card textos-campo">
    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
        style="font-size:28px;">&nbsp;</i>DETALLES <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content.vehiculoReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th *ngFor="let item of header"> {{vehiculosText[item]}}</th>
            </tr>
          </thead>
          <ng-container *ngIf="content?.vehiculoReporte?.vehiculos?.listadoVehiculos.length > 0">
            <tbody>
              <tr *ngFor="let item of content.vehiculoReporte.vehiculos.listadoVehiculos">
                <td *ngFor="let text of header">
                  <ng-template #firstField>
                    {{item['ppu'] + '-' + item['dv_ppu']}}
                  </ng-template>
                  <ng-container *ngIf="text !== 'ppu'; else firstField">
                    <ng-template #isNumber>
                      {{item[text] | number: '1.0-0'}}
                    </ng-template>
                    <ng-container *ngIf="text !== 'tasacion'; else isNumber">
                      {{item[text]}}
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>