<div [ngClass]="{'container-gtp': showHeader}">
    <ng-container *ngIf="showHeader">
    <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
    <h2 class="text-center">CERTIFICADO DE ESTATUTO ACTUALIZADO</h2>
    <h5 class="text-center"><span
            class="badge bg-secondary text-center">{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.Rut | number : '1.0-0'}}-{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.DV | uppercase}}</span>
    </h5>
    </ng-container>
    <div class="row">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                        style="font-size:28px;">&nbsp;</i>CERTIFICADO DE ESTATUTO ACTUALIZADO</h5>
                <div class="card-body">
                    <table class="table table-hover table-borderless">
                        <tbody>
                            <tr>
                                <th width="12%" nowrap="nowrap" scope="row">Razón Social:</th>
                                <td width="88%">
                                    {{certificado.RazonSocial}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Domicilio:</th>
                                <td>{{certificado.Domicilio}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Objeto:</th>
                                <td>{{certificado.Objeto}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Capital:</th>
                                <td>${{certificado.Capital}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
                        style="font-size:28px;">&nbsp;</i>ANTECEDENTES </h5>
                <div class="card-body">
                    <table class="table table-hover table-borderless">
                        <tbody>
                            <tr>
                                <th width="12%" valign="middle" nowrap="nowrap" scope="row">Inscripción:</th>
                                <td width="88%">
                                    {{certificado.CodigoVerificacion}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                        style="font-size:28px;">&nbsp;</i>INFORMACIÓN ADICIONAL</h5>
            </div>
        </div>
    </div>
    <br>

    <div class="card-body">
        <br>
        <br>
        <div class="bd-example">
            <nav>
                <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <div *ngFor="let buttonInfo of informacionAdicionalTable; let i = index">
                        <a
                            class="nav-link"
                            id="{{buttonInfo.dataTargetTag}}-tab"
                            [ngClass]="{'active': selectedIndex === i }"
                            data-bs-toggle="tab"
                            data-toggle="tab"
                            style="cursor: pointer;"
                            role="tab"
                            data-target="#{{buttonInfo.dataTargetTag}}"
                            [attr.aria-controls]="buttonInfo.dataTargetTag"
                            (click)="setIndex(i)"
                            aria-selected="true">{{buttonInfo.title}}&nbsp;<span class="badge bg-secondary">{{buttonInfo.total}}</span>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane" [ngClass]="{'active show': selectedIndex === 0 }" id="nav-comparecientes"
                    role="tabpanel" aria-labelledby="nav-comparecientes-tab">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="12%" align="center" scope="col">Rut</th>
                                <th width="36%" align="center">Nombre</th>
                                <th width="52%" align="center">Domicilio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let comp of certificado.Comparecientes">
                                <td align="left">{{comp.Rut | rutFormat}}</td>
                                <td align="left">{{comp.Nombre}}</td>
                                <td align="left">{{comp.Direccion}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" [ngClass]="{'active show': selectedIndex === 1 }" id="nav-socios"
                    role="tabpanel" aria-labelledby="nav-socios-tab">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="50%" align="left" scope="col">Nombre</th>
                                <th width="20%" align="left">Monto</th>
                                <th width="15%" align="left">Porcentaje</th>
                                <th width="15%" align="left">Tipo de Aporte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let so of certificado.Socios">
                                <td align="left" scope="row">{{so.Nombre}}</td>
                                <td align="left" scope="row">{{so.Monto}}</td>
                                <td align="left">{{so.Porcentaje}}</td>
                                <td align="left">{{so.TipoAporte}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" [ngClass]="{'active show': selectedIndex === 2 }" id="nav-facultades"
                    role="tabpanel" aria-labelledby="nav-facultades-tab">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="13%" align="center" scope="col">Poderes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fa of certificado.Facultades">
                                <td align="left" scope="row">{{fa}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" [ngClass]="{'active show': selectedIndex === 3 }" id="nav-articulos"
                    role="tabpanel" aria-labelledby="nav-articulos-tab">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="25%" align="center" scope="col">Tipo</th>
                                <th width="75%" align="center">Glosa</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let articulo of articulos">
                            <tr>
                                <td align="left" scope="row">Duración:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.Duracion}">
                                    {{articulo.Duracion}} 
                                    <p class="seeMore" (click)="showDeatils('Duracion')">
                                        {{!this.details.Duracion?'Ver mas':'Ver menos'}} 
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">Del Capital Social:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DelCapitalSocial}">
                                    {{articulo.DelCapitalSocial}}
                                    <p class="seeMore" (click)="showDeatils('DelCapitalSocial')">
                                        {{!this.details.DelCapitalSocial?'Ver mas':'Ver menos'}} 
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">De la responsabilidad de los socios:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DeLaResponsabilidadDeSocios}">
                                    {{articulo.DeLaResponsabilidadDeSocios}}
                                <p class="seeMore" (click)="showDeatils('DeLaResponsabilidadDeSocios')">
                                    {{!this.details.DeLaResponsabilidadDeSocios?'Ver mas':'Ver menos'}}  
                                </p>
                            </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">De la administración:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DeLaAdministracion}">
                                    {{articulo.DeLaAdministracion}} 
                                    <p class="seeMore" (click)="showDeatils('DeLaAdministracion')">
                                        {{!this.details.DeLaAdministracion?'Ver mas':'Ver menos'}}  
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">De las utilidades y pérdidas, y los retiros para gastos:
                                </td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DeLasUtilidadesPerdidasRetirosGastos}">
                                    {{articulo.DeLasUtilidadesPerdidasRetirosGastos}}
                                    <p class="seeMore" (click)="showDeatils('DeLasUtilidadesPerdidasRetirosGastos')">
                                        {{!this.details.DeLasUtilidadesPerdidasRetirosGastos?'Ver mas':'Ver menos'}}       
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">Del arbitraje:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DelArbitraje}">
                                    {{articulo.DelArbitraje}} 
                                    <p class="seeMore" (click)="showDeatils('DelArbitraje')">
                                        {{!this.details.DelArbitraje?'Ver mas':'Ver menos'}}        
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">De la liquidación:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DeLaLiquidacion}">
                                    {{articulo.DeLaLiquidacion}}
                                    <p class="seeMore" (click)="showDeatils('DeLaLiquidacion')">
                                        {{!this.details.DeLaLiquidacion?'Ver mas':'Ver menos'}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" scope="row">De los poderes conferidos a o los administradores:</td>
                                <td align="left" scope="row" [ngClass]="{'textHide': !this.details.DeLosPoderesALosAdministradores}">
                                {{articulo.DeLosPoderesALosAdministradores}}
                                <p class="seeMore" (click)="showDeatils('DeLosPoderesALosAdministradores')">
                                    {{!this.details.DeLosPoderesALosAdministradores?'Ver mas':'Ver menos'}}
                                </p>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
    <br>
    <br>
    <br>
    <br>
    <hr>
    <p>La información presentada en estos análisis proviene de fuentes consideradas altamente confiables. Sin embargo,
        dada la posibilidad de error humano o mecánico, no se garantiza la exactitud o integridad de la información y,
        por
        tanto, no se hace responsable de errores u omisiones, como tampoco de las consecuencias asociadas con el empleo
        de
        esa información.</p>
    <hr>
    <!--<table width="100%" border="0">
        <tbody>
            <tr>
                <td align="center">
                    <div class="container-gtp">
                        <img src="https://chart.googleapis.com/chart?chs=99x100&cht=qr&chl=https://www.gestionatupyme.cl"
                            width="99" height="100" alt="" />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>-->
    <br>
</div>