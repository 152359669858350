import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { CompraVentaService } from 'src/app/shared/services/compra-venta.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';


interface CompraVentaReportFormValues {
  rut: string;
  claveSii?: string;
  sincronizar?: boolean;
}

@Component({
  selector: 'app-compra-venta-consultar',
  templateUrl: './compra-venta-consultar.component.html',
  styleUrls: ['./compra-venta-consultar.component.scss']
})
export class CompraVentaConsultarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_LIBRO_COMPRA_VENTA, ESystemProfileList.WEB];
  public groupNameConsulting: string[] = ['primerPeriodo', 'segundoPeriodo', 'tercerPeriodo'];
  public hasUserAccess = false;
  public compraVentaReportForm: UntypedFormGroup;
  public today: string = '';
  public rut: string = '';

  public showReport = false;
  public actualPeriodo: number = 0;
  public consultacredencialesSinc = false;
  public credencialesSinc = false;

  public errores: any[] = [];
  public anyCompleted: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    //private rutValidator: RutValidator,
    private _sessionService: SessionService,
    private compraVentaService: CompraVentaService,
    private utilsService: UtilsService,
    private credencialesService: CredencialesService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.compraVentaReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      claveSii: ['', Validators.required]
    }) as FormGroupTyped<CompraVentaReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.compraVentaReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  guardarCreds(rut: string, contrato: any){
    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales(rut, contrato).subscribe(
      (data: any) => {
        this.crearReporte("", rut);
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar las credenciales.');
        this.showReport = false;
        this.spinner.hide();
    });
  }

  obtenerContratoCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        const services: any = data?.services?.find((e: any) => e.name === 'SII')
        if(services){
          services.attributes.forEach((element: any) => {
            if(element.name === 'userName') element.value = rut;
            if(element.name === 'password') element.value = password;
            if(element.name === 'orgAccess') element.value = true;
          });
          this.guardarCreds(rut, [services])
        } else {
          this.alertService.error('Ha ocurrido un error al obtener el contrato de credenciales.');
          this.showReport = false;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al validar las credenciales.');
        this.showReport = false;
        this.spinner.hide();
    });
  }

  validarCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.validarCredenciales(RUT.applyBackendFormat(rut), password, 'SII').subscribe(
      (data: any) => {
        this.obtenerContratoCreds(rut, password);
      },
      ({ error }) => {
        const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al validar las credenciales.'; 
        this.alertService.error(mensaje);
        this.showReport = false;
        this.spinner.hide();
    });
  }

  validaCredencialReturn(lista: any[]): boolean {
    if(lista && lista.length > 0){
      const objeto = lista.find(e => e?.name === 'SII')
      if(objeto && objeto?.configurationStatus === true){
        this.credencialesSinc = true;
        return true;
      } else {
        this.credencialesSinc = false;
        return false;
      }
    } else {
      this.credencialesSinc = false;
      return false;
    }
  }

  changeRut(): void {
    this.limpiarClave();
  }

  limpiarClave(): void {
    this.compraVentaReportForm.removeControl('claveSii');
    this.compraVentaReportForm.removeControl('sincronizar');
    this.credencialesSinc = false;
    this.consultacredencialesSinc = false;
  }

  addClaveForm(): void {
    this.compraVentaReportForm.addControl('claveSii', new UntypedFormControl('', [Validators.required]));
    this.compraVentaReportForm.addControl('sincronizar', new UntypedFormControl(false, [Validators.required]));
  }

  searchReport() {
    if(this.consultacredencialesSinc) {

      if (!this.compraVentaReportForm.valid) {
        this.utilsService.validateAllFormFields(this.compraVentaReportForm);
        return;
      }
  
      const formTemp = this.compraVentaReportForm.getRawValue();
      const rut = formTemp?.rut;
      const claveSii = formTemp?.claveSii;
      const sincronizar = formTemp?.sincronizar;
  
      if (!rutValidate(rut)) {
        return;
      }
  
      const today = new Date();
      this.actualPeriodo = today.getFullYear();
      this.rut = rut;

      if(sincronizar === true){
        this.validarCreds(rut, claveSii);
      } else {
        this.crearReporte(claveSii, rut);
      }
      

    } else {

      this.rut = this.compraVentaReportForm.get('rut')?.value;
      if (!this.compraVentaReportForm.valid) {
        this.alertService.warn("Rut no valido");
        return
      }
      this.spinner.show();
      this.credencialesService.buscarSecretos(this.rut).subscribe(
        (data: any) => {
          if(this.validaCredencialReturn(data?.services)){
            const today = new Date();
            this.actualPeriodo = today.getFullYear();
            this.crearReporte("", this.rut);
          } else {
            this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
            this.addClaveForm();
            this.consultacredencialesSinc = true;
            this.spinner.hide();
          }
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
          this.spinner.hide();
      });
    }

  }

  public clearForm() {
    this.limpiarClave();
    this.compraVentaReportForm.reset();
    this.activateRutHelper = false;
  }

  crearReporte(claveSii: string, rut: string): void {
    this.errores = [];
    this.anyCompleted = false;
    let periodos: number[] = [(this.actualPeriodo), (this.actualPeriodo - 1), (this.actualPeriodo - 2)];

    this.spinner.show();
    this.compraVentaService.procesarPeriodo(RUT.applyBackendFormat(rut), periodos, claveSii).subscribe(
      (resp: any) => {
        if (resp && Array.isArray(resp) && resp.length > 0) {
          this.processResponses(resp, periodos); // Valida estados por mes por llamadas por periodo - valida los completed si hay se agregan a los resultados y se quitan de la lista a consultar
        } else {
          periodos = [];
          this.anyCompleted = false;
          this.errores.push({
            periodo: "-",
            mes: "-",
            msg: `Ha ocurrido un error al acceder a la información del reporte generado`
          });
        }

        if(periodos.length > 0) {
          this.llamadaAsincrona(periodos, rut);
        } else {
          this.finFlujo();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ocurrió un error al realizar la consulta de creacion de reporte');
        this.spinner.hide();
    });
  }

  llamadaAsincrona(periodos: number[], rut: string, delay: number = 0, llamadas: number = 15): void { // 0 seg, 20 llamadas
    if(llamadas > 0 && periodos.length > 0) {
      setTimeout(() => {
        this.compraVentaService.obtenerTx(RUT.applyBackendFormat(rut), periodos).subscribe(
          (resp: any) => {
            if (resp && Array.isArray(resp) && resp.length > 0) {
              this.processResponses(resp, periodos); // Valida estados por mes por llamadas por periodo - valida los completed si hay se agregan a los resultados y se quitan de la lista a consultar
            } else {
              periodos = [];
              this.anyCompleted = false;
              this.errores.push({
                periodo: "-",
                mes: "-",
                msg: `Ha ocurrido un error al acceder a la información del reporte generado`
              });
            }

            if(periodos.length > 0) {
              this.llamadaAsincrona(periodos, rut, 8000, llamadas - 1); // 8 seg
            } else {
              this.finFlujo();
            }
          },
          ({ error }) => {
            this.alertService.error(error.message || 'Ocurrió un error al realizar al consultar los estados del reporte');
            this.spinner.hide();
        });
      }, delay);
    } else {
      this.finFlujo();
    }
    
    
  }

  finFlujo(): void {    
    if(this.errores.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
      const mensajesMostrados: Set<string> = new Set();
  
      this.errores.forEach(e => {
        const mensaje: string = e?.msg || 'Ha ocurrido con uno de los reportes generados';
        if (!mensajesMostrados.has(mensaje)) {
          this.alertService.error(mensaje);
          mensajesMostrados.add(mensaje);
        }
      });
    }

    if(this.anyCompleted === true) {
      this.showReport = true;
      this.spinner.hide();
    } else {
      this.anyCompleted = false;
      this.showReport = false;
      if(this.errores.length < 1) {
        this.alertService.error('No se ha logrado obtener ningun reporte completado');
      }
      this.spinner.hide();
    }
  }

  processResponses(respuestaConsulta: any, periodos: number[]): void { // valida los estados de los reportes, si son correctos se agregan a la lista, sino se quitan de las ejecuciones, esto es para un periodo
    const array: any[] = respuestaConsulta;
    const periodosOriginal: number[] = [...periodos];

    for(const periodo of periodosOriginal) {
      const elementosFiltrados: any[] = array.filter(e => e?.LibroCompraVentaReporte?.Reporte?.periodo == periodo);
      if(elementosFiltrados.length > 0 && elementosFiltrados.every(e => e?.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion !== 'IN-PROGRESS')) {
        this.removeElementByPeriodo(periodos, periodo);
      }
    }

    for(const elemento of array) {
      if(elemento.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'ERROR') {
        this.errores.push({
          periodo: elemento?.LibroCompraVentaReporte?.Reporte?.periodo,
          mes: elemento.LibroCompraVentaReporte?.Reporte?.mes || "-",
          msg: elemento.LibroCompraVentaReporte?.Reporte?.error || `Ha ocurrido un error al consultar el reporte con periodo ${elemento?.LibroCompraVentaReporte?.Reporte?.periodo} y con mes ${elemento.LibroCompraVentaReporte?.Reporte?.mes}`
        });
      }
    }

    if(array.some(e => e?.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'COMPLETED')) {
      this.anyCompleted = true;
    }
  }

  removeElementByPeriodo(periodos: number[], periodo: any): void { // remueve un objeto
    const index = periodos.findIndex(e => e == periodo);
    if (index >= 0) {
      periodos.splice(index, 1);
    }
  }

  public closeReport(): void {
    this.showReport = false;
    this.rut = '';
  }

}
