<div *ngIf="!hasUserAccess">
  <app-request-access-view></app-request-access-view>
</div>


<div *ngIf="hasUserAccess" class="container-gtp no-print">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="btn-option">
                <button (click)="back()" class="btn btn-primary">Volver</button>
                <!-- <button
                    [useExistingCss]="true" printSectionId="print-section" ngxPrint
                    class="btn btn-primary">Descargar PDF</button> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="noToken" class="card text-center mb-5">
  <div class="card-header">
    Credenciales
  </div>
  <div class="card-body">
    <h5 class="card-title">Usted no posee credenciales de Cobranza Online</h5>
    <p class="card-text">Debe ir a la configuración de credenciales para crearlas.</p>
    <a (click)="goTocredenciales()" class="btn btn-primary">Configurar credenciales</a>
  </div>
</div>
<div *ngIf="show && hasUserAccess && !noToken" class="row" id="print-section">
    <div class="col-12">
        <div class="container-gtp mb-5">
            <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
            <h2 class="text-center pt-3">Cobranza Online</h2>
            <div class="d-flex justify-content-center">
              <h4 class="badge badge-secondary">{{rut}}</h4>
            </div>
            <!-- <div class="d-flex justify-content-center">
              <h1 class="text-center mt-5" style="font-weight: bold; color: rgb(1, 137, 234);">PUBLICACIONES</h1>
            </div> -->
          </div>
    </div>

    <div class="col-12">
      <h1 class="text-center mt-5" style="font-weight: bold; color: rgb(1, 137, 234);">PUBLICACIONES</h1>

      <div class="container card mt-5 mb-5">
        <div class="card-body">
            <div class="row g-3" [formGroup]="cobranzaFilterForm">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-4">
                    <label class="form-label">Número de documento</label>
                      <input formControlName="ndoc" type="text" class="form-control col"
                      placeholder="Número">                   
                </div>
                <!-- FILTROS -->
                <!-- USUARIO (INPUT)-->
                 <!-- agregarle guión -->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-4">
                    <label class="form-label">Rut Emisor (con dígito verificador)</label>
                    <input formControlName="emisor_rut" type="text" class="form-control"
                    placeholder="RUT" [ngClass]="{'is-invalid': isInvalid('emisor_rut')}">
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-4">
                  <label class="form-label">Rut Receptor (con dígito verificador)</label>
                  <input formControlName="receptor_rut" type="text" class="form-control"
                  placeholder="RUT" [ngClass]="{'is-invalid': isInvalid('receptor_rut')}">
              </div>
                <!-- TIPO (COMBO BOX) -->
                
                <!-- EXPIRACION (COMBO BINARIO) -->
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 mt-4">
                    <label class="form-label">Estado</label>
                    <select formControlName="status" class="form-control">
                        <!-- <option>Seleccionar</option> -->
                        <option value="">-</option>
                        <option value="REPORTE_PENDIENTE">Reporte pendiente</option>
                        <option value="PENDIENTE">Pendiente</option>
                        <option value="NOTIFICANDO_REPORTE">Notificando Reporte</option>
                        <option value="REPORTADO">Reportado</option>
                        <option value="BLOQUEADO">Bloqueado</option>
                        <option value="ANULADO">Anulado</option>
                        <option value="PAGADO">Pagado</option>
                        <option value="INVALIDO">Inválido</option>
                    </select>
                </div>
                
                <div class="col-md-3 col-sm-12 mt-4">
                  <div>
                    <label class="form-label">Fecha de publicación</label>
                    <input type="date" class="form-control" formControlName="published_at" 
                      [ngClass]="{'is-invalid': isInvalid('published_at')}">
                  </div>
                </div>
              
                <!-- <div class="col-md-3 col-sm-12 mt-4">
                  <div>
                    <label class="form-label">Fecha hasta</label>
                    <input type="date" class="form-control" formControlName="created_until" 
                      [ngClass]="{'is-invalid': isInvalid('created_until')}" 
                      [min]="minEndDate"
                      (change)="onEndDateChange($event)">
                  </div>
                </div> -->

                <div class="col text-lg-end mt-4">
                    <label class="form-label">&nbsp;</label>
                    <div class="row d-flex">
                        <div class="col-7">
                            <button type="button" class="btn btn-link" style="white-space: nowrap;" (click)="cleanFilters()">Limpiar filtros</button>
                        </div>
                        <div class="col-3">
                            <button type="button"
                                class="btn btn-sm btn-primary" (click)="search()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="container text-primary">
        {{ totalItems }} resultados
      </div>
    </div>

    <div class="col-12">
        <div class="container mt-4">
            <table class="table table-striped table-bordered table-cobranza">
              <thead class="thead-cobranza">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Número de documento</th>
                  <th scope="col">Rut Emisor</th>
                  <th scope="col">Rut Receptor</th>
                  <th scope="col">Monto</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Fecha de publicación</th>
                  <th scope="col">Tipo de Documento</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let morosidad of morosidades; let i = index" class="text-center">
                  <td>{{ morosidad.id }}</td>
                  <td>{{ morosidad.ndoc }}</td>
                  <td>{{ morosidad.emisor_rut }}</td>
                  <td>{{ morosidad.receptor_rut }}</td>
                  <td>{{ morosidad.amount | currency:'CLP':'':'1.0-0' }}</td>
                  <td class="{{'badge badge-' + getBadgeFromStatus(morosidad.status)}} mt-2 mb-2" style="border-radius: 20px;">{{ getTextFromStatus(morosidad.status) }}</td>
                  <td>{{ morosidad.published_at | date: 'dd-MM-yyyy'}}</td>
                  <td>{{ morosidad.debt_type }}</td>
                  <td>
                    <button (click)="aclararMorosidad(morosidad.id)" class="btn btn-link" [disabled]="morosidad.status == 'PAGADO'">Aclarar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </div>

    <div class="col-12">
      <!-- app.component.html -->
<div class="container mt-4">
  <nav aria-label="Page navigation example">
    <div class="text-primary">Página {{ currentPage }} de {{ totalPages }}</div>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="firstPage">
        <a class="page-link"  (click)="setPage(currentPage - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <!-- Previous Page -->
      <!-- <li class="page-item" *ngIf="currentPage > 1">
        <a class="page-link" (click)="setPage(currentPage - 1)">{{ currentPage - 1 }}</a>
      </li> -->
      
      <!-- Current Page -->
      <li class="page-item active">
        <a class="page-link">{{ currentPage }}</a>
      </li>
      
      <!-- Next Page -->
      <!-- <li class="page-item" *ngIf="currentPage + 1 < totalPages">
        <a class="page-link" (click)="setPage(currentPage + 1)">{{ currentPage + 1 }}</a>
      </li> -->
      
      <!-- <li *ngIf="totalPages > (currentPage + 1)">...</li> -->

      <!-- Last Page -->
      <!-- <li class="page-item" *ngIf="currentPage < totalPages">
        <a class="page-link" (click)="setPage(totalPages)">{{ totalPages }}</a>
      </li> -->
      <li class="page-item" [class.disabled]="lastPage">
        <a class="page-link" (click)="setPage(currentPage + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>

    </div>
</div>
