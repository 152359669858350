<div [ngClass]="{'container-gtp mb-5': showHeader}">
  <ng-container *ngIf="showHeader">
    <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
    <h2 class="text-center">REPORTE BIENES RAICES</h2>
    <h5 class="text-center"><span class="badge bg-secondary text-center">
        {{content.bienesraicesReporte.datosBasicosSolicitud.Rut | number:
        '1.0-0'}}-{{content.bienesraicesReporte.datosBasicosSolicitud.DV | uppercase}}
      </span></h5>
  </ng-container>
  
  <div class="card textos-campo mb-5">
    <h5 class="card-header list-group-item cuadro-color text-uppercase"><i class="fa fa-info-circle"
        style="font-size:28px;">&nbsp;</i>Información Básica <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.bienesraicesReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
    <div class="card-body">

      <table class="table table-hover" *ngIf="content?.bienesraicesReporte">
        <tbody>
          <tr>
            <th>
              Rut
            </th>
            <td>
              {{content.bienesraicesReporte.datosBasicosSolicitud.Rut | number:
              '1.0-0'}}-{{content.bienesraicesReporte.datosBasicosSolicitud.DV | uppercase}}
            </td>
          </tr>
          <tr>
            <th>
              Nombre
            </th>
            <td>
              <ng-container *ngIf="content?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices.length > 0">
                {{content.bienesraicesReporte.bienesRaices.listadoBienesRaices[0].nombre}}
              </ng-container>
            </td>
          </tr>
          <tr>
            <th>
              Número Total Bienes Raíces.
            </th>
            <td>
              <ng-template #sinInfo>
                0
              </ng-template>
              <ng-container
                *ngIf="content?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices.length > 0; else sinInfo">
                {{content.bienesraicesReporte.bienesRaices.listadoBienesRaices.length | number: '1.0-0'}}
              </ng-container>
            </td>
          </tr>
          <tr>
            <th>
              Valor Total bienes Raíces
            </th>
            <td>
              <ng-template #sinInfo>
                0
              </ng-template>
              <ng-container
                *ngIf="content?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices.length > 0; else sinInfo">
                M$ {{((content.bienesraicesReporte.bienesRaices.listadoBienesRaices | sumTd: 'avaluo_total') /1000) |
                number : '1.0-0'}}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card textos-campo">
    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
        style="font-size:28px;">&nbsp;</i>DETALLES <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.bienesraicesReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th *ngFor="let item of header"> {{BienesRaicesText[item]}}</th>
            </tr>
          </thead>
          <ng-container *ngIf="content?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices.length > 0">
            <tbody>
              <tr *ngFor="let item of content.bienesraicesReporte.bienesRaices.listadoBienesRaices">
                <td *ngFor="let text of header">
                  <ng-template #isNumber>
                    {{item[text] | number: '1.0-0'}}
                  </ng-template>
                  <ng-container *ngIf="!transformNumber(text); else isNumber">
                    {{item[text]}}
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>