<div class="container-gtp mb-5">
    <img src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" width="100%" alt="" />
    <h2 class="text-center pt-3"><strong>Licitación</strong> {{codigoLicitacion || ''}}</h2>
    <div class="break-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="card mt-5">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <ng-container *ngIf="dataLicitacion && objectKeys(dataLicitacion).length > 0">
                                        <tr *ngFor="let item of objectKeys(dataLicitacion)">
                                            <th class="font-size">{{item}}</th>
                                            <td class="font-size">
                                                <ng-container *ngIf="esObjeto(dataLicitacion[item]); else noObjeto">
                                                    <ng-container *ngIf="validaEstructura(dataLicitacion[item]); else elseEstructura">
                                                        
                                                        <ng-container *ngFor="let itemListado of dataLicitacion[item]?.Listado">
                                                            <div class="row">
                                                                <ng-container *ngIf="esObjeto(itemListado); else noObjeto2">
                                                                    <div class="col-sm-12 my-2">
                                                                        <div class="table-responsive">
                                                                            <table class="table table-condensed table-borderless border">
                                                                                <tr *ngFor="let itemElementListado of objectKeys(itemListado)">
                                                                                    <th class="font-size">
                                                                                        {{itemElementListado}}
                                                                                    </th>
                                                                                    <td class="font-size">
                                                                                        <ng-container *ngIf="esObjeto(itemListado[itemElementListado]); else noObjeto3">
                                                                                                <div class="col-sm-12 my-2">
                                                                                                    <div class="table-responsive">
                                                                                                        <table class="table table-condensed table-borderless border">
                                                                                                            <tr *ngFor="let elementoObjetoListado of objectKeys(itemListado[itemElementListado])">
                                                                                                                <th class="font-size">
                                                                                                                    {{elementoObjetoListado}}
                                                                                                                </th>
                                                                                                                <td class="font-size">
                                                                                                                    {{itemListado[itemElementListado][elementoObjetoListado]}}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                        </ng-container>
                                                                                        <ng-template #noObjeto3>
                                                                                            <div class="col-sm-12 my-2">
                                                                                                {{itemListado[itemElementListado]}}
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #noObjeto2>
                                                                    <div class="col-sm-12 my-2">
                                                                        {{itemListado}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #elseEstructura>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="table-responsive">
                                                                    <table class="table table-condensed table-borderless">
                                                                        <tr *ngFor="let itemElement of objectKeys(dataLicitacion[item])">
                                                                            <th class="font-size">{{itemElement}}</th>
                                                                            <td class="font-size">{{dataLicitacion[item][itemElement]}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #noObjeto>
                                                    <ng-container *ngIf="esArray(dataLicitacion[item]); else noArray">
                                                        <ng-container *ngFor="let elementArray of dataLicitacion[item]">
                                                            <div class="row">
                                                                <ng-container *ngIf="esObjeto(elementArray); else noObjeto2">
                                                                    <div class="col-sm-12 my-2">
                                                                        <div class="table-responsive">
                                                                            <table class="table table-condensed table-borderless border">
                                                                                <tr *ngFor="let itemElementArray of objectKeys(elementArray)">
                                                                                    <th class="font-size">{{itemElementArray}}</th>
                                                                                    <td class="font-size">{{elementArray[itemElementArray]}}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #noObjeto2>
                                                                    <div class="col-sm-12 my-2">
                                                                        {{elementArray}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>

                                                    </ng-container>
                                                    <ng-template #noArray>
                                                        {{dataLicitacion[item]}}
                                                    </ng-template>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>