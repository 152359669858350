import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CamposPersonalizadosService {
  private endpoint: string = env.backend.camposPersonalizados.url;
  private servicio: string = env.backend.camposPersonalizados.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  obtenerContrato(model: string = ''): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    model ? params = params.append("model", model): null;
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'obtenerContrato');
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  crearContrato(atributos: any): Observable<any> {
    const request = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      CamposPersonalizados: {
        Attributos: [atributos],
        SolicitudCredito: []
      },
      servicio: this.servicio,
      uri: 'crearContrato'
    };

    return this.http.post<any>(`${this.endpoint}crear`, request);
  }

  listarModelos(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'listarModelos');
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  listarValores(rut: string, webSection: string = ''): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'listar');
    if(webSection) params = params.append("webSection", webSection);
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  crearActualizarValores(rut: string, atributos: any[] = [], SolicitudCredito: any[] = []): Observable<any> {
    const request = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      CamposPersonalizados: {
        Attributos: atributos,
        SolicitudCredito: SolicitudCredito
      },
      servicio: this.servicio,
      uri: 'crearActualizarValor'
    };

    return this.http.post<any>(`${this.endpoint}crear`, request);
  }

  listarRuts(campos: any[]): Observable<any> {
    const request: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: 'listarRuts',
      campos: campos
    };

    return this.http.post<any>(`${this.endpoint}crear`, request);
  }

  listarSeccionWeb(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'listarSeccionWeb');

    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }
  
}
